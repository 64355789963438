import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";

import "./Message.scss";

/** @enum {string} */
export const MessageType = {
    Success: "success",
    Primary: "primary",
    Error: "error",
};
/** @typedef {{glyph: string, type: string}} IconGlyph */
/** @type {{error:IconGlyph, primary:IconGlyph, success:IconGlyph}} */
const iconGlyphs = {
    error: {
        glyph: "hexagon-exclamation",
        type: "FontAwesome",
    },
    primary: {
        glyph: "info-circle-solid",
        type: "FontAwesome",
    },
    success: {
        glyph: "check-circle-solid",
        type: "FontAwesome",
    },
};

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {MessageType} [props.type]
 * @param {Function} [props.clearHandler]
 * @param {number} [props.timeout]
 * @param {boolean} [props.absolute]
 * @param {string} [props.className]
 * @returns {React.ReactNode}
 */
const Message = ({
    children,
    type = MessageType.Error,
    clearHandler,
    timeout = 0,
    absolute = true,
    className,
}) => {
    const [isActive, setIsActive] = useState(false);
    const [timeoutId, setTimeoutId] = useState(0);
    const closeClickHandler = useCallback(() => {
        clearTimeout(timeoutId);
        clearHandler();
        setIsActive(false);
    }, [clearHandler, timeoutId]);

    useEffect(() => {
        if (isActive || !children) return;
        setIsActive(true);
        if (!timeout) return;
        setTimeoutId(setTimeout(closeClickHandler, timeout));
    }, [children, closeClickHandler, timeout, isActive, setTimeoutId]);

    if (!children) return null;

    return (
        <div className={cx(absolute && "relative")}>
            <div
                className={cx(
                    absolute && "absolute z-[9999] w-full",
                    className && className,
                )}
                role={type === MessageType.Error ? "alert" : "status"}
            >
                <div
                    className={cx(
                        "auction-message rounded-lg text-sm font-bold text-white",
                        `auction-message--${type}`,
                        absolute && "shadow-2xl",
                        isActive && "auction-message--active",
                    )}
                >
                    <div
                        className={cx(
                            "flex items-center gap-3 p-5",
                            timeout && "pb-2",
                        )}
                    >
                        <Icon
                            ariaHidden
                            className="text-lg"
                            glyph={iconGlyphs[type].glyph}
                            type={iconGlyphs[type].type}
                        />
                        <div className="grow">{children}</div>
                        {clearHandler && (
                            <button
                                type="button"
                                onClick={closeClickHandler}
                                aria-label="Close"
                                className="text-sm"
                            >
                                <Icon glyph="cross" type="Icomoon" ariaHidden />
                            </button>
                        )}
                    </div>
                    {!!timeout && (
                        <div
                            className={cx(
                                "rounded-bl-2xl h-2.5 rounded-r-lg bg-black/30",
                                isActive && "w-0",
                                !isActive && "w-full",
                            )}
                            style={{
                                transition: `width ${timeout}ms linear`,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Message;

// @ts-check
import React from "react";
import { ImageLoader } from "@qgiv/core-react";
import StoreLinks from "../StoreLinks";
import { giviAppIcon } from "../../../images";
import StoreLegalFooter from "../StoreLegalFooter";

/**
 * @param {object} props
 * @param {import("../../../types").Auction} props.auction
 * @returns {React.ReactNode}
 */
const GiviUpsellModal = ({ auction }) => {
    if (!auction || auction.hideGivi) return null;

    return (
        <div>
            <div className="mt-10 flex min-h-[75px] w-full flex-wrap items-center justify-center gap-10 border-t border-solid border-[#AAB6BC] pt-9">
                <ImageLoader
                    style={{ width: 75, height: 75 }}
                    src={giviAppIcon}
                    alt="Givi icon"
                    loadedClassName={undefined}
                    loadingClassName={undefined}
                    handleImgLoad={undefined}
                />
                <div>
                    <span className="-color--system-givi-purple block text-center text-lg font-semibold">
                        Mobile bidding made easy!
                    </span>
                    <StoreLinks />
                </div>
            </div>
            <StoreLegalFooter />
        </div>
    );
};

export default GiviUpsellModal;

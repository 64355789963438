// @ts-check
import { getSingularPluralWordForm } from "@qgiv/core-js";

// utilities
/**
 * @description for getting a default value of zero
 * @param {number} amount amount
 * @returns {number} default value
 */
const defaultZero = (amount) => (amount > 0 ? amount : 0);
/**
 * @description for getting an amount with a leading zero
 * @param {number} remaining remaining amount
 * @returns {string} amount with leading zero
 */
const padLessThanTen = (remaining) =>
    remaining < 10 ? `0${remaining}` : remaining.toString();

// private functions for calculating date time parts
/**
 * @description for getting days
 * @param {number} remaining remaining time
 * @returns {number} defaulted amount
 */
const getDays = (remaining) => {
    const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
    return defaultZero(days);
};
/**
 * @description for getting hours
 * @param {number} remaining remaining time
 * @returns {number} hours
 */
const getHours = (remaining) => {
    const hours = Math.floor(
        (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    return defaultZero(hours);
};
/**
 * @description for getting minutes
 * @param {number} remaining remaining time
 * @returns {number} minutes
 */
const getMinutes = (remaining) => {
    const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
    return defaultZero(minutes);
};
/**
 * @description for getting remaining seconds
 * @param {number} remaining remaining time
 * @returns {number} seconds
 */
const getSeconds = (remaining) => {
    const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
    return defaultZero(seconds);
};

// private functions for formatting the date time parts of the countdown
/**
 * @description for getting the final five minute format
 * @param {number} minutes minutes
 * @param {number} seconds seconds
 * @returns {string} formatted time
 */
const getFiveMinuteFormat = (minutes, seconds) => {
    const paddedMinutes = padLessThanTen(minutes);
    const paddedSeconds = padLessThanTen(seconds);
    return `${paddedMinutes}:${paddedSeconds}`;
};
/**
 * @description for getting final hour format
 * @param {number} minutes minutes
 * @param {number} seconds seconds
 * @returns {string} formatted time
 */
const getOneHourFormat = (minutes, seconds) => `${minutes}m ${seconds}s`;
/**
 * @description for getting the final day format
 * @param {number} hours hours
 * @param {number} minutes minutes
 * @param {number} seconds seconds
 * @returns {string} formatted time
 */
const getOneDayFormat = (hours, minutes, seconds) =>
    `${hours}h ${minutes}m ${seconds}s`;
/**
 * @description for getting the default format
 * @param {number} days days
 * @param {number} hours hours
 * @param {number} minutes minutes
 * @returns {string} formatted time
 */
const getDefaultFormat = (days, hours, minutes) =>
    `${days}d ${hours}h ${minutes}m`;

/**
 * @description for getting the formatted date/time countdown
 * @param {number} from date time in milliseconds to countdown from
 * @param {number} to date time in milliseconds to countdown to
 * @returns {string} formatted countdown
 */
export const getCountdown = (from, to) => {
    const remaining = to - from;
    const totalHours = remaining / 1000 / 60 / 60;
    const hours = getHours(remaining);
    const minutes = getMinutes(remaining);
    // more than a day: 1d 1h 2m,
    if (totalHours > 24) {
        const days = getDays(remaining);
        return getDefaultFormat(days, hours, minutes);
    }
    const totalMinutes = remaining / 1000 / 60;
    const seconds = getSeconds(remaining);
    // more than an hour: 1h 3m 7s,
    if (totalMinutes > 60) {
        return getOneDayFormat(hours, minutes, seconds);
    }
    // more than five minutes: 10m 37s,
    if (totalMinutes > 5) return getOneHourFormat(minutes, seconds);
    // final countdown: 03:23
    return getFiveMinuteFormat(minutes, seconds);
};

/**
 * @description for checking if a time has passed
 * @param {string} value time string to evaluate
 * @param {number} time current timestamp or Date.now()
 * @returns {boolean} time has passed
 */
export const hasPassed = (value, time = Date.now()) =>
    time > new Date(value).getTime();

/**
 * @description for calculating if the event is in progress, started and not ended
 * @param {string} eventStart event start date
 * @param {string} eventEnd event end date
 * @param {number} time current timestamp Date.now()
 * @returns {boolean} is in progress
 */
export const isInProgress = (eventStart, eventEnd, time) =>
    hasPassed(eventStart, time) && !hasPassed(eventEnd, time);

/**
 * @description for getting the countdown label (Starts|Ends in:)
 * @param {string} eventStart A start date/time to countdown to if not passed
 * @param {string} eventEnd An end date/time to countdown to if not passed
 * @param {number} time current timestamp Date.now()
 * @returns {string} countdown label
 */
export const getEventCountdownLabel = (eventStart, eventEnd, time) => {
    const hasEnded = hasPassed(eventEnd, time);

    if (hasEnded) return "Ended";

    const hasStarted = hasPassed(eventStart, time);
    return `${hasStarted ? "Ends" : "Starts"} in:`;
};

/**
 * @description for getting the event countdown
 * @param {string} eventStart A start date/time to countdown to if not passed
 * @param {string} eventEnd An end date/time to countdown to if not passed
 * @param {number} time current timestamp Date.now()
 * @returns {string} countdown
 */
export const getEventCountdownTime = (eventStart, eventEnd, time) => {
    const startTime = new Date(eventStart).getTime();
    const endTime = new Date(eventEnd).getTime();
    const toTime = time < startTime ? startTime : endTime;
    return getCountdown(time, toTime);
};

/**
 * @description for getting a formatted countdown for an event with start and end dates
 * @param {string} eventStart A start date/time to countdown to if not passed
 * @param {string} eventEnd An end date/time to countdown to if not passed
 * @param {number} time current timestamp Date.now()
 * @returns {string} Formatted countdown string
 */
export const getEventCountdown = (eventStart, eventEnd, time) => {
    const label = getEventCountdownLabel(eventStart, eventEnd, time);

    if (hasPassed(eventEnd, time)) return label;

    const countdown = getEventCountdownTime(eventStart, eventEnd, time);

    return `${label} ${countdown}`;
};

/**
 * @description for getting a stopwatch time from a date time
 * @param {string} start ISO time string to count from
 * @param {number} time current timestamp Date.now()
 * @returns {string} time with label
 */
export function getElapsedTime(start, time) {
    const milliseconds = time - new Date(start).getTime();
    const seconds = Math.floor(milliseconds / 1000);
    if (seconds < 60) {
        return `${seconds} sec ago`;
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes} min ago`;
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return getSingularPluralWordForm(`${hours} hour(s) ago`, hours);
    }
    const days = Math.floor(hours / 24);
    if (days < 7) return getSingularPluralWordForm(`${days} day(s) ago`, days);
    const weeks = Math.floor(days / 7);
    return getSingularPluralWordForm(`${weeks} week(s) ago`, weeks);
}

/* globals QGIV */
export const RETRY_COUNT = 9;

/**
 * @description for getting SSE host
 * @returns {string} host
 */
export function getSSEHost() {
    return QGIV?.sseHost || window.location.origin;
}

/**
 * @description for creating a wait
 * @param {number} timeout timeout in milliseconds
 * @returns {Promise} promise
 */
export function waitAsync(timeout) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}

/** @enum {string} */
export const BroadcastMessageType = {
    Connect: "connect",
    Connecting: "connecting",
    Disconnect: "disconnect",
    Ping: "ping",
    Unstable: "unstable",
    Failure: "failure",
    Initialize: "initialize",
    Restore: "restore",
};

/**
 * @param {BroadcastMessageType} type message type
 * @returns {boolean} is the message type ignored
 */
export function isIgnoredMessageType(type) {
    const ignoredTypes = [
        BroadcastMessageType.Unstable,
        BroadcastMessageType.Failure,
        BroadcastMessageType.Connecting,
    ];
    return ignoredTypes.includes(type);
}

export class PostMessageResponse {
    /**
     * @description post message response constructor
     * @param {BroadcastMessageType} type message type
     * @param {any} payload message payload
     */
    constructor(type, payload) {
        this.type = type;
        this.payload = payload;
    }
}

/**
 * @description for posting a broadcast channel message with post message response object
 * @param {BroadcastChannel} channel channe to use
 * @param {BroadcastMessageType} type broadcast channel type
 * @param {any} data data to post
 */
export function postBroadcastChannelMessage(channel, type, data) {
    if (!channel) return;
    channel.postMessage(new PostMessageResponse(type, data));
}

import React, { useEffect, useRef, useState } from "react";

const ForgotPassword = (
    /** @type {{ email: string, handleSubmitPasswordClick: Function, isSent: boolean }} */
    { email = "", isSent = false, handleSubmitPasswordClick = null },
) => {
    const [emailValue, setEmailValue] = useState(email);
    const firstInput = useRef(null);

    useEffect(() => {
        firstInput?.current?.focus();
    }, [firstInput]);

    return (
        <>
            {isSent && (
                <div className="mb-[15px] rounded bg-[#0f8808] px-[15px] py-[10px] text-center text-[15px] tracking-[0.12px] text-[#fff]">
                    We just sent you an email containing a temporary link to{" "}
                    <br />
                    reset your password.
                </div>
            )}
            <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                Forgot Your Password?
            </div>
            <div className="mt-[10px] text-center text-[15px] tracking-[0.12px] text-[#66737D]">
                Send password reset instructions to the following email:
            </div>
            <input
                ref={firstInput}
                placeholder="Email"
                aria-label="Email"
                data-testid="email-input"
                className="my-[20px] w-full rounded-[5px] border border-solid border-[#AAB6BC] p-[15px] text-[15px] leading-[23px] tracking-[0.12px] shadow-inner focus-within:border-black"
                type="email"
                value={emailValue}
                onKeyUp={(e) =>
                    e.key === "Enter" && handleSubmitPasswordClick(emailValue)
                }
                onChange={(e) => setEmailValue(e.target.value)}
            />

            <button
                data-testid="submit-email-btn"
                className="btn btn--primary mt-[10px] w-full p-[12px] text-lg tracking-[0.12px]"
                type="button"
                onClick={() => handleSubmitPasswordClick(emailValue)}
            >
                Submit
            </button>
        </>
    );
};

export default ForgotPassword;

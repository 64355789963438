import React, { useState } from "react";
import { LogInstance } from "@qgiv/core-js";
import { Message, Button, ButtonStyle, Dialog, ModalSize } from "../../core";
import { RadioButton, RadioGroupProps } from "../../core/Button";
import { getAxiosResponseError } from "../../../api";
import { reloadPageWithToastSuccess } from "../../system";
import { filterValidPaymentMethods } from "../../../utility";
/** @typedef {import("../../../types").Auction} Auction */
/**
 * @typedef SavedPayment
 * @property {string} id id
 * @property {string} last last
 * @property {string} expiry expiry
 * @property {string} ccType cc type
 * @property {string} title title
 */

const { log } = LogInstance("SelectPaymentModal");
/** @enum {string} */
const cardIconGlyph = {
    VS: "cc-visa-brands",
    MC: "cc-mastercard-brands",
    AM: "cc-amex-brands",
    DC: "cc-discover-brands",
    ck: "building-columns-solid",
};
/** @enum {string} */
const cardLabel = {
    VS: "Visa",
    MC: "Mastercard",
    AM: "American Express",
    DC: "Discover",
    ck: "Bank Account",
};

/**
 * @description Select payment modal
 * @param {object} props properties
 * @param {SavedPayment[]} props.savedPayments saved Payments
 * @param {Function} props.paymentSelectAsync payment SelectAsync
 * @param {Function} props.closeHandler close Handler
 * @param {string} [props.currentPaymentId] current PaymentId
 * @param {Function} props.addPaymentModalHandler add Payment modal Handler
 * @param {Auction} props.auction auction
 * @param {boolean} [props.show]
 * @param {Function} [props.open]
 * @returns {React.JSX} modal
 */
const SelectPaymentModal = ({
    savedPayments,
    paymentSelectAsync,
    closeHandler,
    currentPaymentId = "",
    addPaymentModalHandler,
    auction,
    show,
    open,
}) => {
    const [selectedPayment, setSelectedPayment] = useState(currentPaymentId);
    const [errorMessage, setErrorMessage] = useState("");
    const [busy, setBusy] = useState(false);
    const confirmHandler = async () => {
        try {
            setBusy(true);
            setErrorMessage("");
            await paymentSelectAsync(selectedPayment);
            reloadPageWithToastSuccess(
                "Payment method changed",
                !currentPaymentId,
            );
        } catch (err) {
            log(err);
            const errors = getAxiosResponseError(err);
            setErrorMessage(errors.join(", "));
            setBusy(false);
        }
    };
    const paymentMethods = filterValidPaymentMethods(savedPayments, auction);

    return (
        <Dialog
            id="selectPaymentMethodModal"
            closeHandler={closeHandler}
            show={show}
            open={open}
            title="Choose your payment method"
            className={ModalSize.Medium}
        >
            <Message clearHandler={() => setErrorMessage("")} timeout={6000}>
                {errorMessage}
            </Message>
            <div {...RadioGroupProps}>
                {paymentMethods.map((payment) => {
                    const expiration =
                        payment.ccType === "ck"
                            ? ""
                            : `, Expires ${payment.expiry}`;

                    return (
                        <RadioButton
                            key={payment.id}
                            selected={selectedPayment === payment.id}
                            title={`${payment.title}`}
                            glyph={cardIconGlyph[payment.ccType]}
                            detail={`${cardLabel[payment.ccType]} ending in ${payment.last}${expiration}`}
                            onClick={() => {
                                setSelectedPayment(payment.id);
                                setErrorMessage("");
                            }}
                        />
                    );
                })}
            </div>
            <div className="mt-5 flex grow gap-3">
                <Button
                    className="basis-1/2"
                    btnStyle={ButtonStyle.Primary}
                    onClick={confirmHandler}
                    disabled={
                        busy ||
                        !selectedPayment ||
                        selectedPayment === currentPaymentId
                    }
                    data-testid="confirmButton"
                >
                    Confirm
                </Button>
                <Button
                    className="basis-1/2"
                    btnStyle={ButtonStyle.Secondary}
                    onClick={addPaymentModalHandler}
                >
                    Add New Payment
                </Button>
            </div>
        </Dialog>
    );
};

export default SelectPaymentModal;

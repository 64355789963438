import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OtpCodeType, RegistrationModalView, OtpCode } from "@qgiv/auction";
import { constants } from "@qgiv/core-js";
import {
    generateOtpCodeAsync,
    validateOtpCodeAndLoginAsync,
} from "../../../api/registration";
import {
    setIsLoading,
    setModalView,
    selectEmail,
    selectPhone,
    selectRegistrationModalView,
    setResetKey,
    setAccountId,
    setEmail,
} from "../../../redux/loginRegistrationModalSlice";

const ConnectedOtpCode = (
    /** @type {{ showCodeEntry?: boolean, useEmail?: boolean }} */
    { showCodeEntry = false, useEmail = false },
) => {
    const dispatch = useDispatch();
    const email = useSelector(selectEmail);
    const phone = useSelector(selectPhone);
    const modalView = useSelector(selectRegistrationModalView);

    const [code, setCode] = useState("");
    const [selectedCodeType, setSelectedCodeType] = useState(OtpCodeType.NONE);
    const [hasError, setHasError] = useState(false);

    /**
     * Update code value and submit to backend if 6 digits.
     * @param {string} codeValue Code text.
     */
    async function updateCodeAsync(codeValue) {
        const { Status } = constants.ENUMS;

        setCode(codeValue);

        if (codeValue.length < 6) {
            return;
        }

        setHasError(false);
        dispatch(setIsLoading(true));

        try {
            const {
                data: {
                    response: {
                        api_token = null,
                        accountId = null,
                        resetKey = null,
                        status = null,
                        errors = [],
                    } = {},
                } = {},
            } = await validateOtpCodeAndLoginAsync(email, codeValue);

            if (errors?.length || !api_token || !accountId) {
                throw new Error(
                    "Login code could not be generated. Please try again.",
                );
            }

            if (status && Number(status) === Status.PENDING) {
                dispatch(setEmail(email));
                dispatch(setResetKey(resetKey));
                dispatch(setAccountId(accountId));

                dispatch(setModalView(RegistrationModalView.CREATE_PASSWORD));
                return;
            }
            dispatch(setModalView(RegistrationModalView.CONFIRMATION));
        } catch (err) {
            setHasError(true);
            return;
        } finally {
            dispatch(setIsLoading(false));
        }
    }

    /**
     * Handle send code clicks.
     * @param {number} codeType Code type.
     * @param {boolean} resend Should resend.
     */
    const handleSendCodeClick = useCallback(
        async (codeType, resend = false) => {
            const isSms = codeType === OtpCodeType.SMS;
            dispatch(setIsLoading(true));
            setHasError(false);

            try {
                await generateOtpCodeAsync(email, isSms, resend);
            } catch (err) {
                // if there was a failure above, try to resend code (if it was not already a re-send).
                if (!resend) {
                    try {
                        await generateOtpCodeAsync(email, isSms, true);
                    } catch (resendErr) {
                        setHasError(true);
                        return;
                    }
                } else {
                    setHasError(true);
                }
            } finally {
                dispatch(setIsLoading(false));
                setSelectedCodeType(codeType);
                dispatch(setModalView(RegistrationModalView.OTP_CODE_ENTER));
            }
        },
        [email, dispatch],
    );

    useEffect(() => {
        if (email && !phone) {
            handleSendCodeClick(OtpCodeType.EMAIL, false);
        }
    }, [email, phone, handleSendCodeClick]);

    return (
        <OtpCode
            email={email}
            phone={phone}
            code={code}
            selectedCodeType={selectedCodeType}
            showCodeEntry={showCodeEntry}
            useEmail={useEmail}
            hasError={hasError}
            modalView={modalView}
            updateCode={async (codeValue) => {
                // devnote: without awaiting it, errors are not caught
                // eslint-disable-next-line no-return-await
                await updateCodeAsync(codeValue);
            }}
            handleSendCodeClick={async (codeType, resend) => {
                // devnote: without awaiting it, errors are not caught
                // eslint-disable-next-line no-return-await
                await handleSendCodeClick(codeType, resend);
            }}
        />
    );
};

export default ConnectedOtpCode;

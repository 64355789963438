import React from "react";
import {
    getGiviStoreUrl,
    getStoreBadgeAltText,
    GiviStore,
} from "../../../utility";
import { appleAppStoreLogo, googlePlayStoreLogo } from "../../../images";
import ImageLink from "../ImageLink";

const items = [
    {
        linkUrl: getGiviStoreUrl(GiviStore.Apple),
        imageUrl: appleAppStoreLogo,
        imageAltText: getStoreBadgeAltText(GiviStore.Apple),
    },
    {
        linkUrl: getGiviStoreUrl(GiviStore.Google),
        imageUrl: googlePlayStoreLogo,
        imageAltText: getStoreBadgeAltText(GiviStore.Google),
    },
];

/**
 * @returns {React.ReactElement}
 */
const StoreLinks = () => (
    <div className="mt-2.5 flex flex-wrap justify-center gap-2.5">
        {items.map(({ linkUrl, imageAltText, imageUrl }) => (
            <ImageLink
                key={linkUrl}
                linkUrl={linkUrl}
                imageUrl={imageUrl}
                imageAltText={imageAltText}
                imageClassName="h-[40px]"
            />
        ))}
    </div>
);

export default StoreLinks;

export const registrationQueryString = "launchmodal";

/**
 * @returns {boolean}
 */
export const hasRegistrationQueryString = () =>
    new URLSearchParams(window.location.search).has(registrationQueryString);

/**
 * @returns {void}
 */
export function removeRegistrationQueryString() {
    const queryString = new URLSearchParams(window.location.search);
    if (!queryString.has(registrationQueryString)) return;
    queryString.delete(registrationQueryString);
    const url = queryString.size
        ? `${window.location.pathname}?${queryString}`
        : window.location.pathname;
    window.history.pushState({}, "", url);
}

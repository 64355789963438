// @ts-check
import React from "react";
import { Icon } from "@qgiv/core-react";
import {
    getEventCountdown,
    getStartTime,
    isInProgress,
} from "../../../utility";
/** @typedef {import("../../../types").Product} Product */

const Countdown = (
    /** @type {{item:Product, time:number}} */
    { item, time },
) => {
    const categoryStart = getStartTime(item);
    const countdownText = getEventCountdown(
        categoryStart,
        item.endTimeIso,
        time,
    );
    const inProgress = isInProgress(categoryStart, item.endTimeIso, time);
    return (
        <span className="flex items-center gap-1 text-center text-[15px] font-normal leading-[23px] tracking-[0.12px] text-[color:var(--Theme-Text-Black,#394146)]">
            {inProgress && <Icon type="FontAwesome" glyph="timer" ariaHidden />}
            {countdownText}
        </span>
    );
};

export default Countdown;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    globalModalType: null,
};

export const slice = createSlice({
    name: "globalModal",
    initialState,
    reducers: {
        setGlobalModalType: (state, action) => ({
            ...state,
            globalModalType: action.payload,
        }),
        resetGlobalModal: () => ({
            ...initialState,
        }),
    },
});

// actions
export const { resetGlobalModal, setGlobalModalType } = slice.actions;

export default slice.reducer;

// selectors
export const selectModalType = (state) => state.globalModal.globalModalType;

import React from "react";
import cx from "classnames";
import { getItemAmount, getItemAmountLabel } from "../../../utility";
/** @typedef {import("../../../types").Product} Product */

/**
 * @param {object} props
 * @param {Product} props.item
 * @param {number} props.time
 * @param {boolean} [props.isInList]
 * @returns {React.JSX.Element}
 */
const ItemAmountHeader = ({ item, time, isInList = false }) => (
    <h3
        className={cx(
            "amount-heading",
            "font-bold",
            isInList && "ui-text-primary-color text-lg",
            !isInList &&
                "text-2xl text-[color:var(--Theme-Text-Black,#394146)]",
        )}
    >
        {getItemAmountLabel(item, time)}
        {":\u00A0"}
        {getItemAmount(item, time)}
    </h3>
);

export default ItemAmountHeader;

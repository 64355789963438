import React from "react";
import {
    ActivityPage,
    getActivityProductIds,
    LoadingIcon,
} from "@qgiv/auction";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchTransactions,
    getAllActivity,
    getAuction,
    getCompletedActivity,
    getFavoriteProducts,
    getPendingActivity,
    getProducts,
    getTime,
    getTransactions,
    getTransactionsStatus,
    getUser,
} from "../../redux";
import { followHandler, actionHandlerAsync, payBalanceAsync } from "../../api";
/** @typedef {import("../../models/Product/Product").Product} Product */

const ConnectedActivityPage = () => {
    /** @type {Product[]} */
    const products = useSelector(getProducts);
    const { attendee, account } = useSelector(getUser);
    const auction = useSelector(getAuction);
    const pendingItems = useSelector(getPendingActivity);
    const completedItems = useSelector(getCompletedActivity);
    const favorites = useSelector(getFavoriteProducts);
    const time = useSelector(getTime);
    const transactions = useSelector(getTransactions);
    const transactionsStatus = useSelector(getTransactionsStatus);
    const allActivity = useSelector(getAllActivity);

    if (!auction) return <LoadingIcon />;

    const activityIds = getActivityProductIds(
        attendee.id,
        allActivity,
        products,
    );

    return (
        <ActivityPage
            items={products}
            attendee={attendee}
            auction={auction}
            favorites={favorites}
            pendingItems={pendingItems}
            completedItems={completedItems}
            time={time}
            onWatchHandler={followHandler}
            actionHandlerAsync={actionHandlerAsync}
            transactions={transactions}
            account={account}
            useDispatch={useDispatch}
            transactionsStatus={transactionsStatus}
            fetchTransactions={fetchTransactions}
            payBalanceAsync={payBalanceAsync}
            activityIds={activityIds}
        />
    );
};

export default ConnectedActivityPage;

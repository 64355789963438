import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RegistrationModalView, CreatePassword } from "@qgiv/auction";
import {
    selectEmail,
    selectAccountId,
    setIsLoading,
    setModalView,
    selectResetKey,
    selectFormId,
} from "../../../redux/loginRegistrationModalSlice";
import { resetAccount } from "../../../api/registration";

const ConnectedCreatePassword = () => {
    const dispatch = useDispatch();
    const formId = useSelector(selectFormId);
    const email = useSelector(selectEmail);
    const accountId = useSelector(selectAccountId);
    const resetKey = useSelector(selectResetKey);
    const [createError, setCreateError] = useState(null);

    /**
     * Handle on submit click.
     * @param {string} password The password.
     * @param {string} match The password match.
     */
    async function handleSubmitPasswordClickAsync(password, match) {
        setCreateError(null);

        if (!email || !resetKey) {
            setCreateError(
                "There was an error retrieving your registration. Please try again.",
            );
            return;
        }

        dispatch(setIsLoading(true));

        try {
            const result = await resetAccount(
                formId,
                accountId,
                resetKey,
                password,
                match,
            );
            if (!result.data.success) {
                throw new Error("Invalid email or password.");
            }

            dispatch(setModalView(RegistrationModalView.CONFIRMATION));
        } catch (err) {
            setCreateError(err.message);
            return;
        } finally {
            dispatch(setIsLoading(false));
        }
    }

    return (
        <CreatePassword
            createError={createError}
            handleSubmitPasswordClick={async (password, match) =>
                // devnote: without awaiting it, errors are not caught
                // eslint-disable-next-line no-return-await
                await handleSubmitPasswordClickAsync(password, match)
            }
        />
    );
};

export default ConnectedCreatePassword;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RegistrationModalView, CheckEmail } from "@qgiv/auction";
import { checkEmailAsync } from "../../../api/registration";
import {
    setEmail,
    setIsLoading,
    resetModal,
    setPhone,
    setModalView,
    selectEventId,
    selectEventUrl,
} from "../../../redux/loginRegistrationModalSlice";

const ConnectedCheckEmail = () => {
    const dispatch = useDispatch();
    const eventUrl = useSelector(selectEventUrl);
    const eventId = useSelector(selectEventId);

    /**
     * Handle open event registration iframe.
     * @param {string} email The email.
     */
    function handleOpenRegistration(email) {
        const { loadFullScreenModal = () => {} } = window?.QGIV?.Embed ?? {};

        dispatch(resetModal(false));

        loadFullScreenModal(null, {
            eventUrl: `${eventUrl}?email=${email}`,
            eventName: "Registration",
        });
    }

    /**
     * Handle on submit click.
     * @param {string} email The email.
     */
    async function handleSubmitEmailClickAsync(email) {
        if (!email) {
            return;
        }

        dispatch(setEmail(email));
        dispatch(setIsLoading(true));

        let ticketId = null;
        let phoneNumber = null;

        try {
            const {
                data: { response },
            } = await checkEmailAsync(eventId, email);

            ticketId = response?.id;
            phoneNumber = response?.phone;

            if (!ticketId) {
                throw new Error("Ticket not found.");
            }
        } catch (err) {
            handleOpenRegistration(email);
            return;
        } finally {
            dispatch(setIsLoading(false));
        }

        if (phoneNumber) {
            dispatch(setPhone(phoneNumber));
        }

        dispatch(setModalView(RegistrationModalView.REGISTER_SELECT));
    }

    return (
        <CheckEmail
            handleSubmitEmailClick={async (email) => {
                // devnote: without awaiting it, errors are not caught
                // eslint-disable-next-line no-return-await
                await handleSubmitEmailClickAsync(email);
            }}
        />
    );
};

export default ConnectedCheckEmail;

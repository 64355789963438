import React from "react";
import { Icon } from "@qgiv/core-react";
import { twMerge } from "tailwind-merge";
import Button, { ButtonStyle } from "./Button";

export const RadioGroupProps = {
    className: "flex flex-col gap-3",
    role: "radiogroup",
};

/**
 * @param {object} props
 * @param {string} props.id
 * @param {boolean} props.selected
 * @param {string} props.glyph
 * @param {string} [props.glyphClassName]
 * @param {string} props.title
 * @param {string} props.detail
 * @param {Function} props.onClick
 * @returns {React.ReactNode}
 */
const RadioButton = ({
    id,
    selected,
    glyph,
    glyphClassName = "",
    title,
    detail,
    onClick,
}) => (
    <Button
        className={twMerge(
            "flex rounded-[5px] border border-solid font-normal no-underline",
            !selected && "m-px border-[#CFD7DA]",
            selected &&
                "ui-text-primary-color ui-border-primary-color border-2",
        )}
        role="radio"
        btnStyle={ButtonStyle.Text}
        aria-checked={selected}
        aria-label={title}
        onClick={() => {
            onClick(id);
        }}
    >
        <div className="flex items-center gap-5">
            <Icon
                className={twMerge("text-3xl", glyphClassName)}
                type="FontAwesome"
                glyph={glyph}
                ariaHidden
                label={title}
            />
            <div className="flex grow flex-col gap-2 text-left">
                <span>{title}</span>
                <small>{detail}</small>
            </div>
        </div>
    </Button>
);

export default RadioButton;

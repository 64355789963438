import { constants } from "@qgiv/core-js";
import parse from "../Base";

const {
    ENUMS: { AttendeeStatus, BidderStatus },
} = constants;

/** @typedef {import("../../../../../packages/auction/source/types").Attendee} Attendee */

// properties that require conversion
const intProperties = new Set([
    "attendeeStatus",
    "bidderStatus",
    "globalAccountStatus",
]);
const floatProperties = new Set([
    "balance",
    "totalBilled",
    "totalGiftAssist",
    "totalPaid",
    "totalPending",
    "totalSpent",
]);
const booleanProperties = new Set(["claimed", "notAttending"]);

/**
 * @description parse an attendee object
 * @param {any} rawAttendee raw attendee
 * @returns {Attendee} attendee
 */
export default function parseAttendee(rawAttendee) {
    if (!rawAttendee || !Object.keys(rawAttendee).length) return {};
    // parse properties
    const attendee = parse(
        rawAttendee,
        intProperties,
        floatProperties,
        booleanProperties,
    );
    // add custom properties
    attendee.isRegistered =
        attendee.attendeeStatus === AttendeeStatus.REGISTERED;
    attendee.isCheckedIn =
        attendee.attendeeStatus === AttendeeStatus.CHECKED_IN;
    attendee.isInitial = attendee.bidderStatus === BidderStatus.INITIAL;
    attendee.isActive = attendee.bidderStatus === BidderStatus.ACTIVE;
    attendee.isPaid = attendee.bidderStatus === BidderStatus.PAID;
    attendee.isCheckedOut = attendee.bidderStatus === BidderStatus.CHECKED_OUT;
    attendee.isPayLater = attendee.bidderStatus === BidderStatus.PAY_LATER;
    attendee.isBalanceDue = attendee.bidderStatus === BidderStatus.BALANCE_DUE;

    return attendee;
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RegistrationModalView, LoginNoPassword } from "@qgiv/auction";
import { validateEmail } from "@qgiv/core-validation";
import { checkEmailAsync } from "../../../api/registration";
import {
    selectEmail,
    selectEventId,
    setEmail,
    setIsLoading,
    setModalView,
    setPhone,
} from "../../../redux/loginRegistrationModalSlice";

const ConnectedLoginNoPassword = () => {
    const dispatch = useDispatch();

    const email = useSelector(selectEmail);
    const eventId = useSelector(selectEventId);
    const [validationError, setValidationError] = useState(null);

    /* istanbul ignore next */
    /**
     * Handle open event registration iframe.
     */
    function handleOpenLoginPassword() {
        dispatch(setModalView(RegistrationModalView.LOGIN_PASSWORD));
    }

    /**
     * Handle on submit click.
     * @param {string} emailValue The email
     */
    async function handleSubmitEmailClickAsync(emailValue) {
        if (!emailValue) {
            return;
        }

        if (!validateEmail(emailValue)) {
            setValidationError("Please enter a valid email address.");
            return;
        }

        dispatch(setEmail(emailValue));
        dispatch(setIsLoading(true));

        let ticketId = null;
        let phoneNumber = null;

        try {
            const {
                data: { response },
            } = await checkEmailAsync(eventId, emailValue);

            ticketId = response?.id;
            phoneNumber = response?.phone;

            if (!ticketId) {
                setValidationError("Username not found. Please try again.");
                return;
            }
        } catch (err) {
            setValidationError("Please enter a valid email address.");
            return;
        } finally {
            dispatch(setIsLoading(false));
        }

        if (phoneNumber) {
            dispatch(setPhone(phoneNumber));
        }

        dispatch(setModalView(RegistrationModalView.OTP_CODE_SELECT));
    }

    return (
        <LoginNoPassword
            handleOpenLoginPassword={() => handleOpenLoginPassword()}
            handleSubmitEmailClick={async (emailValue) =>
                // devnote: without awaiting it, errors are not caught
                // eslint-disable-next-line no-return-await
                await handleSubmitEmailClickAsync(emailValue)
            }
            email={email}
            error={validationError}
        />
    );
};

export default ConnectedLoginNoPassword;

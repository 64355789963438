import React, { useEffect, useRef, useState } from "react";

const CheckEmail = (
    /** @type {{ handleSubmitEmailClick: Function }} */
    { handleSubmitEmailClick = null },
) => {
    const [emailValue, setEmailValue] = useState("");
    const firstInput = useRef(null);

    useEffect(() => {
        firstInput?.current?.focus();
    }, [firstInput]);

    return (
        <>
            <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                Please enter your email
            </div>
            <div className="mt-[10px] text-center text-[15px] tracking-[0.12px] text-[#66737D]">
                We&apos;ll use this to get you into the auction
            </div>
            <input
                ref={firstInput}
                aria-label="Email"
                data-testid="email-input"
                className="my-[20px] w-full rounded-[5px] border border-solid border-[#AAB6BC] p-[15px] text-[15px] leading-[23px] tracking-[0.12px] shadow-inner focus-within:border-black"
                type="email"
                value={emailValue}
                placeholder="Email"
                onKeyUp={(e) =>
                    e.key === "Enter" && handleSubmitEmailClick(emailValue)
                }
                onChange={(e) => setEmailValue(e.target.value)}
            />
            <button
                data-testid="submit-email-btn"
                className="btn btn--primary mt-[10px] w-full p-[12px] text-lg tracking-[0.12px]"
                type="button"
                onClick={() => handleSubmitEmailClick(emailValue)}
            >
                Submit
            </button>
        </>
    );
};

export default CheckEmail;

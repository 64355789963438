import React, { useState } from "react";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import Button, { ButtonSize, ButtonStyle } from "../../core/Button";
import SortableList from "../../core/SortableList/SortableList";
import "../SettingsDropdown/SettingsDropdown.scss";
import { NavigationListPosition } from "../../header/NavigationList";
import {
    SitePage,
    addItemToList,
    removeItemFromList,
    updateItemInList,
    getNavigationUrl,
    getNavigationTargetId,
    getInternalUrl,
} from "./navigationSettingsHelpers";
/** @typedef {import("../../../types/Navigation").Navigation} Navigation */
/** @typedef {import("../../../types/CustomPage").CustomPage} CustomPage */

const NavigationSettings = (
    /** @type {{navigation:Navigation[], customPages:CustomPage[], position:NavigationListPosition, toggle:(show:boolean)=>{}, updateNavigation:(items:Navigation[])=>{}}} */ {
        navigation = [],
        customPages = [],
        position = NavigationListPosition.Header,
        toggle,
        updateNavigation,
    },
) => {
    const defaultList = [...navigation]
        .sort((a, b) => a.weight - b.weight)
        .map((i, weight) => ({ ...i, weight, isOpen: false }));
    /** @type {[items:Navigation[], setItems:(navigation:Navigation[])=>{}]} */
    const [items, setItems] = useState(defaultList);
    const [deletedItems, setDeletedItems] = useState([]);
    const isSidebar = position === NavigationListPosition.Sidebar;

    const addHandler = () => {
        setItems(addItemToList(items));
    };
    const updateHandler = () => {
        updateNavigation(items, deletedItems);
        toggle(false);
    };
    const resetToDefaultHandler = () => {
        setItems(defaultList);
        toggle(false);
    };

    /**
     * @description renders each item
     * @param {Navigation} item item
     * @returns {import("react").ReactNode} element
     */
    const itemRenderer = (item) => {
        const deleteHandler = () => {
            setDeletedItems([...deletedItems, item.id]);
            setItems(removeItemFromList(items, item.weight));
        };
        const moveUpHandler = () => {
            setItems(
                updateItemInList(items, item.weight, {
                    ...item,
                    weight: item.weight - 1,
                }),
            );
        };
        const moveDownHandler = () => {
            setItems(
                updateItemInList(items, item.weight, {
                    ...item,
                    weight: item.weight + 1,
                }),
            );
        };
        const toggleEditHandler = () => {
            setItems(
                items.toSpliced(item.weight, 0).toSpliced(item.weight, 1, {
                    ...item,
                    isOpen: !item.isOpen,
                }),
            );
        };
        const updateItemHandler = (updatedItem) => {
            setItems(updateItemInList(items, item.weight, updatedItem));
        };

        return (
            <>
                <div className="-display--flex -align-items--center -padding--5 -gap--10">
                    <Icon
                        glyph="grip-dots-vertical"
                        type="FontAwesome"
                        ariaHidden
                        label="Move"
                    />
                    <div className="-display--flex -flex-direction--column">
                        <button
                            className="btn btn--text"
                            type="button"
                            aria-label="Move up"
                            disabled={item.weight === 0}
                            onClick={moveUpHandler}
                        >
                            <Icon
                                glyph="chevron-up-solid"
                                type="FontAwesome"
                                ariaHidden
                                label="Move up"
                            />
                        </button>
                        <button
                            className="btn btn--text"
                            type="button"
                            aria-label="Move down"
                            disabled={item.weight === items.length - 1}
                            onClick={moveDownHandler}
                        >
                            <Icon
                                glyph="chevron-down-solid"
                                type="FontAwesome"
                                ariaHidden
                                label="Move down"
                            />
                        </button>
                    </div>
                    <span className="grow truncate">{item.title}</span>
                    <button
                        type="button"
                        className="btn btn--text min-w-[16px]"
                        aria-label="Edit"
                        onClick={toggleEditHandler}
                    >
                        <Icon
                            type="FontAwesome"
                            glyph="pencil-alt-regular"
                            ariaHidden
                            label="Edit"
                        />
                    </button>
                    <button
                        type="button"
                        className="btn btn--text min-w-[16px]"
                        aria-label="Delete"
                        onClick={deleteHandler}
                    >
                        <Icon
                            ariaHidden
                            className="-color--system-error"
                            glyph="trash-alt-regular"
                            label="Delete"
                            type="FontAwesome"
                        />
                    </button>
                </div>
                {item.isOpen && (
                    <form className="-navigation-settings--form -padding-top--20 -padding-bottom--20">
                        <label
                            className="-width--100 -padding-bottom--10"
                            htmlFor={`title-${item.id}`}
                        >
                            Link Label
                            <input
                                id={`title-${item.id}`}
                                className="-background-color--system-white -color--system-dark -width--100 -ui-border -padding--10 -margin-top--10"
                                type="text"
                                value={item.title}
                                placeholder="Link Label"
                                onChange={(e) =>
                                    updateItemHandler({
                                        ...item,
                                        title: e.target.value,
                                    })
                                }
                            />
                        </label>
                        <label
                            className="-width--100 -padding-bottom--10"
                            htmlFor={`linksTo-${item.id}`}
                        >
                            Links To
                            <select
                                id={`linksTo-${item.id}`}
                                className="-background-color--system-white -width--100 -ui-border -padding--10 -margin-top--10"
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    updateItemHandler({
                                        ...item,
                                        internal: value === "page" ? 1 : 0,
                                        url: value === "page" ? "%home%" : "",
                                        targetid: "",
                                    });
                                }}
                                value={item.internal ? "page" : "custom"}
                            >
                                <option value="page">Site Page</option>
                                <option value="custom">Custom URL</option>
                            </select>
                        </label>
                        {!!item.internal && (
                            <select
                                className="-background-color--system-white -width--100 -ui-border -padding--10 -margin-top--10"
                                aria-label="Site Page"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    updateItemHandler({
                                        ...item,
                                        url: getNavigationUrl(value),
                                        targetid: getNavigationTargetId(value),
                                    });
                                }}
                                value={getInternalUrl(item, customPages)}
                            >
                                <option value={SitePage.Home}>
                                    Event Home
                                </option>
                                <option value={SitePage.Donate}>Donate</option>
                                <option value={SitePage.Register}>
                                    Register
                                </option>
                                <option value={SitePage.Sonsors}>
                                    Sponsors
                                </option>
                                <option value={SitePage.ProductList}>
                                    Auction Items
                                </option>
                                {customPages.length &&
                                    customPages.map((page) => (
                                        <option key={page.id} value={page.id}>
                                            {page.title ||
                                                `Custom page ${page.id}`}
                                        </option>
                                    ))}
                            </select>
                        )}
                        {!item.internal && (
                            <input
                                className="-background-color--system-white -color--system-dark -width--100 -ui-border -padding--10 -margin-top--10"
                                type="text"
                                aria-label="URL"
                                placeholder="URL"
                                value={item.url}
                                onChange={(e) =>
                                    updateItemHandler({
                                        ...item,
                                        url: e.target.value,
                                    })
                                }
                            />
                        )}
                        <label
                            className="-width--100 -padding-top--10 -display--flex -gap--10 -align-items--center"
                            htmlFor={`newWindow-${item.id}`}
                        >
                            <input
                                id={`newWindow-${item.id}`}
                                type="checkbox"
                                checked={item.newWindow === "1"}
                                onChange={() =>
                                    updateItemHandler({
                                        ...item,
                                        newWindow:
                                            item.newWindow === "0" ? "1" : "0",
                                    })
                                }
                            />
                            Open in a new window
                        </label>
                    </form>
                )}
            </>
        );
    };

    const content = items.length ? (
        <SortableList
            items={items}
            updateItems={setItems}
            itemRenderer={itemRenderer}
        />
    ) : (
        <p>No navigation items added</p>
    );

    return (
        <section>
            <header
                className={cx(
                    "-display--flex",
                    "-align-items--center",
                    "-justify-content--space-between",
                    "-padding-bottom--20",
                    isSidebar && "-flex-direction--column -gap--10",
                )}
            >
                <h2 className="-settings-dropdown--heading">
                    Navigation Settings
                </h2>
                <Button
                    className="-btn--cms-edit"
                    size={ButtonSize.Small}
                    onClick={addHandler}
                >
                    <Icon glyph="plus-regular" type="FontAwesome" ariaHidden />
                    Add Link
                </Button>
            </header>
            <div>{content}</div>
            <footer
                className={cx(
                    "-padding-top--20",
                    "-display--flex",
                    "-align-items--center",
                    !isSidebar && "-gap--15",
                    isSidebar && "-flex-direction--column",
                )}
            >
                <Button onClick={updateHandler} btnStyle={ButtonStyle.Success}>
                    Update Settings
                </Button>
                or
                <Button
                    className="-padding--0"
                    onClick={resetToDefaultHandler}
                    btnStyle={ButtonStyle.Text}
                >
                    Cancel
                </Button>
            </footer>
        </section>
    );
};

export default NavigationSettings;

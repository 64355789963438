/** @typedef {import("../types").Auction} Auction */
/**
 * @public
 * @function handleModalKeyDown
 * @description Ensures that once the modal is open focus is not able to go outside of
                the modal by redirecting focus when it is moved to the previous
                focusable element from the first focusable element or to the next
                focusable element from the last focusable element.
 * @param {object} [event] keydown event
 * @param {object} [ref] React reference from useRef React hook
 * @returns {void}
 */

import { currencyStringAccounting } from "@qgiv/core-js";

// eslint-disable-next-line import/prefer-default-export
export const handleModalKeyDown = (event, ref) => {
    const isTabPressed = event.key === "Tab" || event.keyCode === 9;
    // quick return for all other types of key press
    if (!isTabPressed) {
        return;
    }
    // Selectors that correspond with the list of possible elements that
    // will receive focus from the browser
    const focusableElements = [
        "button:not([disabled])",
        "input:not([disabled])",
        "select:not([disabled])",
        "textarea:not([disabled])",
        'a[href]:not([tabindex="-1"]):not([disabled])',
        '[tabindex]:not([tabindex="-1"]):not([disabled])',
    ];

    // Get a list of all of the focusable content that is nested within the
    // window including any markup that is passed down via props.children
    const elementQueryString = focusableElements.join(", ");
    const allFocusableContentInModal =
        ref.current.querySelectorAll(elementQueryString);

    const hasFocusableContentInModal = allFocusableContentInModal.length > 0;
    const elementThatHasFocus = document.activeElement;
    const firstFocusableElementInModal =
        hasFocusableContentInModal && allFocusableContentInModal[0];
    const lastFocusableElementInModal =
        hasFocusableContentInModal &&
        allFocusableContentInModal[allFocusableContentInModal.length - 1];

    // The Shift and Tab keys are pressed at the same time
    if (
        event.shiftKey &&
        elementThatHasFocus === firstFocusableElementInModal
    ) {
        // go to the last focusable element
        lastFocusableElementInModal.focus();
        event.preventDefault();
        return;
    }
    if (elementThatHasFocus !== lastFocusableElementInModal) return;
    // go to the first focusable element
    firstFocusableElementInModal.focus();
    event.preventDefault();
};

/** @type {number} */
export const GiftAssistType = {
    None: 0,
    Percent: 1,
    Flat: 2,
};

/**
 * @description gets the gift assist amount
 * @param {Auction} auction auction
 * @param {number} total total
 * @returns {number} gift assist amount
 */
export function getGiftAssistAmount(auction, total) {
    switch (auction.feeCoverage) {
        case GiftAssistType.Flat:
            return auction.feeCoverageFlat;
        case GiftAssistType.Percent:
            return total * (auction.feeCoveragePercentage / 100);
        case GiftAssistType.None:
        default:
            return 0;
    }
}

/**
 * @description gets the gift assist text
 * @param {Auction} auction auction
 * @param {number} total total
 * @returns {number} gift assist text
 */
export function getGiftAssistText(auction, total) {
    const amount = getGiftAssistAmount(auction, total);
    const text =
        auction.feeCoverageText ||
        "I'd like to help cover processing costs - add %Fee% to my transaction";
    return text.replace("%Fee%", currencyStringAccounting(amount, "USD"));
}

import { constants } from "@qgiv/core-js";
import parse from "../Base";

const {
    ENUMS: { Status, StoreProductType },
} = constants;

/** @typedef {import("../../../../../packages/auction/source/types").Activity} Activity */

// properties that require conversion
const intProperties = new Set(["qty", "status", "productType", "qtyFulfilled"]);
const floatProperties = new Set([
    "originalAmount",
    "total",
    "fairMarketValue",
    "procurementCost",
    "unitCost",
    "tax",
    "shippingCost",
]);

/**
 * @description parses an activity object
 * @param {any} rawActivity raw activity object
 * @returns {Activity} parsed activity object
 */
export default function parseActivity(rawActivity) {
    const activity = parse(rawActivity, intProperties, floatProperties);
    activity.isPaid = activity.transaction !== "0";
    activity.isBidType = activity.productType === StoreProductType.BID;
    activity.isBuyType = activity.productType === StoreProductType.BUY;
    activity.isDonateType = activity.productType === StoreProductType.DONATE;
    return activity;
}

/**
 * @description filters activity to active only
 * @param {Activity[]} activity activity array
 * @returns {Activity[]} active
 */
export function getActiveActivity(activity) {
    return activity.filter(({ status }) => status !== Status.DELETED);
}

import React, { useEffect, useRef, useState } from "react";
/**
 * @param {object} props
 * @param {string} [props.email]
 * @param {Function} [props.handleOpenLoginPassword]
 * @param {Function} [props.handleSubmitEmailClick]
 * @param {string} [props.error]
 * @returns {React.ReactNode}
 */
const LoginNoPassword = ({
    email = "",
    handleOpenLoginPassword = null,
    handleSubmitEmailClick = null,
    error = null,
}) => {
    const [emailValue, setEmailValue] = useState(email);
    const firstInput = useRef(null);

    useEffect(() => {
        firstInput?.current?.focus();
    }, [firstInput]);

    return (
        <>
            <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                Login
            </div>
            <div className="mt-[10px] text-center text-[15px] tracking-[0.12px] text-[#66737D]">
                Enter the email used when registering for this event
            </div>
            {error && (
                <div className="mt-[10px] rounded bg-[#d03424] p-[10px] text-center text-[15px] tracking-[0.12px] text-[#fff]">
                    {error}
                </div>
            )}
            <input
                ref={firstInput}
                placeholder="Email"
                aria-label="Email"
                data-testid="email-input"
                className="my-[20px] w-full rounded-[5px] border border-solid border-[#AAB6BC] p-[15px] text-[15px] leading-[23px] tracking-[0.12px] shadow-inner focus-within:border-black"
                type="email"
                value={emailValue}
                onKeyUp={(e) =>
                    e.key === "Enter" && handleSubmitEmailClick(emailValue)
                }
                onChange={(e) => setEmailValue(e.target.value)}
            />
            <button
                data-testid="submit-email-btn"
                className="btn btn--primary mt-[10px] w-full p-[12px] text-lg tracking-[0.12px]"
                type="button"
                onClick={() => handleSubmitEmailClick(emailValue)}
            >
                Submit
            </button>
            <div className="mt-[15px] flex items-center justify-center gap-1 text-[15px] tracking-[0.12px] text-[#66737D]">
                Don’t want a verification code?{" "}
                <button
                    type="button"
                    className="font-bold text-[color:var(--user-theme\_\_color--primary,#1F6FD9)] hover:underline"
                    onClick={() => handleOpenLoginPassword()}
                >
                    Enter Password.
                </button>
            </div>
        </>
    );
};

export default LoginNoPassword;

// @ts-check
import { Icon } from "@qgiv/core-react";
import React from "react";

/** @enum {string} */
export const IconContentWrapperIcon = {
    BookmarkStrikethrough: "bookmark-strikethrough-solid",
    Dollar: "dollar-sign-regular",
    CartInfo: "shopping-cart-info",
    FaceFrown: "face-frown-open",
    Timer: "timer",
};

/**
 * @param {object} props - The properties object.
 * @param {string} props.iconGlyph
 * @param {React.ReactNode|React.ReactNode[]} props.children
 * @returns {React.JSX.Element} The rendered IconContentWrapper component.
 */
const IconContentWrapper = ({ iconGlyph, children }) => (
    <div className="p-5 text-center">
        <Icon
            ariaHidden
            className="m-2.5 text-[66px] text-[#e07800]"
            glyph={iconGlyph}
            type="FontAwesome"
        />
        <p>{children}</p>
    </div>
);

export default IconContentWrapper;

import React, { useEffect, useRef } from "react";
import { Icon } from "@qgiv/core-react";
import { twMerge } from "tailwind-merge";
import { OtpCodeType, RegistrationModalView } from "../../../../utility";

const OtpCode = (
    /** @type {{ email: string, phone: string, code: string, modalView: string, useEmail:boolean, showCodeEntry:boolean, handleSendCodeClick:Function, updateCode:Function, selectedCodeType:string}} */
    {
        email = "",
        phone = "",
        code = "",
        modalView = "",
        useEmail = false,
        showCodeEntry = false,
        handleSendCodeClick = null,
        updateCode = null,
        selectedCodeType = OtpCodeType.NONE,
        hasError = false,
    },
) => {
    const firstInput = useRef(null);

    useEffect(() => {
        firstInput?.current?.focus();
    }, [firstInput]);

    /**
     * Gets markup for code input
     * @returns {React.ReactNode} Code input.
     */
    function getCodeInput() {
        return (
            <>
                <div className="flex items-center justify-center">
                    <div
                        className={twMerge(
                            "mb-[10px] mt-[30px] p-[10px] text-center text-[13px]",
                            hasError
                                ? "bg-[#D03424] text-white"
                                : "bg-[#FEF4E1] text-[#394146]",
                        )}
                    >
                        <Icon
                            glyph="paper-plane-duotone"
                            type="FontAwesome"
                            ariaHidden
                        />
                        <span className="ml-[10px]">
                            {hasError ? (
                                "Code was not accepted. Please try again."
                            ) : (
                                <>
                                    <span>6-digit code sent to</span>{" "}
                                    <strong>
                                        {selectedCodeType ===
                                            OtpCodeType.EMAIL || useEmail
                                            ? email
                                            : `(xxx)-xxx-${phone}`}
                                        .
                                    </strong>
                                </>
                            )}
                        </span>
                    </div>
                </div>
                <input
                    data-testid="code-input"
                    className="mb-[5px] mt-[20px] w-full rounded-[5px] border border-solid border-[#AAB6BC] p-[15px] text-[15px] leading-[23px] tracking-[0.12px] shadow-inner focus-within:border-black"
                    type="text"
                    maxLength={6}
                    value={code}
                    placeholder="6-digit code"
                    onChange={async (e) => updateCode(e.target.value)}
                />
                <div className="text-right">
                    <button
                        type="button"
                        className="text-[15px] font-bold tracking-[0.12px] text-[color:var(--user-theme\_\_color--primary,#1F6FD9)] hover:underline"
                        onClick={() =>
                            handleSendCodeClick(selectedCodeType, true)
                        }
                    >
                        Resend Code
                    </button>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                Enter Verification Code
            </div>
            <div className="mt-[10px] text-center text-[15px] tracking-[0.12px] text-[#66737D]">
                Choose how to receive your 6-digit verification code.
            </div>
            <div className="flex-col">
                <button
                    ref={firstInput}
                    data-testid="email-btn"
                    size=""
                    className="btn btn--primary mt-[30px] w-full p-[12px] text-lg tracking-[0.12px]"
                    type="button"
                    style={{ flexWrap: "wrap" }}
                    onClick={() => handleSendCodeClick(OtpCodeType.EMAIL)}
                >
                    <div className="-display--flex -align-items--center -justify-content--center -gap--15">
                        <Icon
                            glyph="envelope-regular"
                            type="Icomoon"
                            ariaHidden
                        />
                        <span className="-text--left truncate">{email}</span>
                    </div>
                </button>
                {phone ? (
                    <button
                        data-testid="sms-btn"
                        size=""
                        className="btn btn--primary mt-[20px] w-full p-[12px] text-lg tracking-[0.12px]"
                        type="button"
                        onClick={() => handleSendCodeClick(OtpCodeType.SMS)}
                    >
                        <div className="-display--flex -align-items--center -justify-content--center -gap--15">
                            <Icon
                                glyph="comment-dots-regular"
                                type="FontAwesome"
                                ariaHidden
                            />
                            <span>(xxx)-xxx-{phone}</span>
                        </div>
                    </button>
                ) : null}
            </div>
            {(modalView === RegistrationModalView.OTP_CODE_ENTER ||
                showCodeEntry) &&
                getCodeInput()}
        </>
    );
};

export default OtpCode;

import { constants } from "@qgiv/core-js";
import { hasPassed } from "../../utility";
import parse from "../Base";

const {
    ENUMS: { StoreProductType },
} = constants;

/** @typedef {import("../../../../../packages/auction/source/types").Category} Category */

// properties that require conversion
const intProperties = new Set(["itemType", "weight"]);
const booleanProperties = new Set(["checkout", "private"]);

/**
 * @description for parsing a category object
 * @param {any} rawCategory category
 * @returns {Category} category
 */
export function parseCategory(rawCategory) {
    if (!rawCategory || !Object.keys(rawCategory).length) return {};
    /** @type {Category} */
    const category = parse(
        rawCategory,
        intProperties,
        new Set(),
        booleanProperties,
    );

    category.isBidType = category.itemType === StoreProductType.BID;
    category.isBuyType = category.itemType === StoreProductType.BUY;
    category.isDonateType = category.itemType === StoreProductType.DONATE;

    return category;
}

/**
 * @description gets categories by ids
 * @param {any[]} categories categories
 * @param {string[]} ids category ids
 * @returns {any[]} categories
 */
export function getCategoriesByIds(categories, ids) {
    if (!categories?.length || !ids?.length) return [];
    return categories.filter(({ id }) => ids.includes(id));
}

/**
 * @description gets category by id
 * @param {any[]} categories categories
 * @param {string} id category id
 * @returns {any} category
 */
export function getCategory(categories, id) {
    if (!categories?.length) return null;
    if (!id) throw new Error("Category id is required");
    return categories.find((c) => c.id === id);
}

/**
 * @description get active categories
 * @param {Category[]} categories categories
 * @returns {Category[]} categories
 */
export function getActiveCategories(categories) {
    if (!categories.length) return [];
    return categories.filter((c) => !c.private && hasPassed(c.startTimeIso));
}

/* eslint-disable no-return-await */
// this rule has been deprecated due to the way JS handles promises now.
// https://eslint.org/docs/latest/rules/no-return-await

import { qfetch } from "@qgiv/core-js";

/**
 * Login via email and password
 * @param {string} username Email to check.
 * @param {string} password Password to check.
 * @returns {Promise} result.
 */
export async function signIn(username, password) {
    return await qfetch.post("/account/login", {
        username,
        password,
        csrfToken: document.querySelector("#csrfToken")?.value ?? null,
    });
}

/**
 * Reset password
 * @param {string} username Email to check.
 * @returns {Promise} result.
 */
export async function resetPassword(username) {
    return await qfetch.post("/account/login", {
        username,
        password_reset: 1,
        csrfToken: document.querySelector("#csrfToken")?.value ?? null,
    });
}

/**
 * Create account
 * @param {string|number} formId Form id.
 * @param {string|number} accountId Account id.
 * @param {string} resetKey Reset key.
 * @param {string} password Password.
 * @param {string} match Password confirmation.
 * @returns {Promise} result.
 */
export async function resetAccount(
    formId,
    accountId,
    resetKey,
    password,
    match,
) {
    return await qfetch.post(
        `/account/${accountId}/reset/${resetKey}?formId=${formId}`,
        {
            password,
            confirm_password: match,
            csrfToken: document.querySelector("#csrfToken")?.value ?? null,
        },
    );
}

/**
 * Submit to check email endpoint.
 * @param {number|string} eventId Event id.
 * @param {string} email Email to check.
 * @returns {Promise} result.
 */
export async function checkEmailAsync(eventId, email) {
    const checkEmailUrl = `/api/v3/events/${eventId}/attendee/check`;

    return await qfetch.post(checkEmailUrl, {
        email,
    });
}

/**
 * Generate OTP Code
 * @param {string} email Email address of account.
 * @param {boolean} sms Whether to send as SMS.
 * @param {boolean} resend Use resend endpoint.
 * @returns {Promise} response.
 */
export async function generateOtpCodeAsync(email, sms, resend = false) {
    const action = resend ? "resend" : "generate";
    let generateOtpCodeUrl = `/api/v1/accounts/otp/${action}?email=${email}`;
    if (sms) {
        generateOtpCodeUrl += "&sendSMS=1";
    }

    return await qfetch.get(generateOtpCodeUrl);
}

/**
 * Generate OTP Code
 * @param {string} email Email address of account.
 * @param {string} code OTP code.
 * @returns {Promise} account.
 */
export async function validateOtpCodeAndLoginAsync(email, code) {
    const validateOtpCodeUrl = `/api/v1/accounts/otp/validate?email=${email}&otpCode=${code}&login=1`;

    return await qfetch.get(validateOtpCodeUrl);
}

export default {
    validateOtpCodeAndLoginAsync,
    generateOtpCodeAsync,
    checkEmailAsync,
};

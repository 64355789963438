// @ts-check
import { currencyStringAccounting } from "@qgiv/core-js";
import React from "react";
import cx from "classnames";
/** @typedef {import("../../../types").Product} Product */

/**
 * @param {object} props
 * @param {Product} props.item
 * @returns {React.ReactNode}
 */
const ItemGoal = ({ item }) => {
    const goal = item.fundANeedGoal || item.maxQty * item.cost;
    const isAmountGoal = item.maxQty > 0;
    const percent = (item.raised / goal) * 100;
    const percentValue = percent > 100 ? "100%" : `${Math.floor(percent)}%`;

    return (
        <>
            <div className="flex gap-5 pb-1">
                <span>Raised: {currencyStringAccounting(item.raised)}</span>
                <span className={cx(percent < 100 && "text-[#D93526]")}>
                    Goal:{" "}
                    {isAmountGoal
                        ? item.maxQty
                        : currencyStringAccounting(goal)}
                </span>
            </div>
            <div className="rounded bg-[#F7F8F9] p-1 shadow-[0px_0px_6px_#00000080_inset]">
                <div
                    className={cx("rounded bg-[#1F6FD9B2] p-5")}
                    style={{ width: percentValue }}
                >
                    {percentValue}
                </div>
            </div>
        </>
    );
};

export default ItemGoal;

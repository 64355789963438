import React from "react";

/**
 * @param {object} props
 * @param {string} props.categoryName
 * @param {string[]} [props.tags]
 * @returns {React.JSX.Element}
 */
const ItemCategoryAndTags = ({ categoryName, tags = [] }) => (
    <div
        data-testid="categoryTagsContainer"
        className="item__category self-stretch text-[13px] font-semibold text-[color:var(--Background-Tertiary-Gray,#66737D)]"
    >
        {tags.length ? `${categoryName}: ` : categoryName}
        {tags.length ? (
            <span className="font-normal" data-testid="tagsContainer">
                {tags.join(", ")}
            </span>
        ) : null}
    </div>
);

export default ItemCategoryAndTags;

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { loadFonts, initSentry, LogInstance } from "@qgiv/core-js";
import loadIcons from "@qgiv/icons";
import { ConfirmationModalQueryStringKey } from "@qgiv/auction";
import {
    ConnectedActivityPage,
    ConnectedItemDetails,
    ConnectedItemList,
    SystemTimer,
    ConnectedEventLoginRegisterModal,
    ConnectedGlobalModal,
    PaymentIFrame,
} from "./components";
import "./auctionApp.scss";
import store from "./redux/store";
import SystemFeed from "./components/SystemFeed/SystemFeed";
import {
    addControl,
    updateNavigation,
    updateSocialSettings,
    updateLayout,
    updateCustomPages,
    updateEditMode,
} from "./redux";
import User from "./components/User";
import ConnectedPageHeader from "./components/PageHeader";
import { auctionLoginMessageHandler } from "./utilities/registrationHelpers";
import { modalMessageHandler } from "./utilities/modalHelpers";
import { ConnectedLoginConfirmation } from "./components/EventLoginRegisterModal/Views";
import { hasRegistrationQueryString } from "./utilities";

const { debug } = LogInstance("QGIV.AuctionApp");

debug("starting app");

// Load web fonts
loadFonts();

// Load Sentry performance monitoring
initSentry({ app: "Auction Event" }, "react-mobile-fe");

// Load Icons into DOM
loadIcons();

if (!window.QGIV) throw new Error("Global QGIV object not found");

const auctionApp = document.querySelector("#auction-app");
if (!auctionApp) throw new Error("App container not found");
const queryString = new URLSearchParams(window.location.search);

// for initializing the Redux data from legacy, feed, and interval
const auctionAppRoot = createRoot(auctionApp);
auctionAppRoot.render(
    <Provider store={store}>
        <User
            bidder={window.QGIV.AuctionBidder}
            account={window.QGIV.AuctionAccount}
            csrfToken={document.querySelector("[name=csrfToken]")?.value}
        />
        <SystemTimer />
        <SystemFeed formId={window.QGIV.Form.id.toString()} />
        <PaymentIFrame />
    </Provider>,
);

// page header
const headerTarget = document.querySelector("#auction-app-header");
if (headerTarget) {
    const updateHeaderLayout = (layout) => {
        store.dispatch(updateLayout(layout));
    };

    const navigation = JSON.parse(headerTarget.dataset.navigation);
    const socialSettings = JSON.parse(headerTarget.dataset.socialSettings);
    store.dispatch(updateNavigation(navigation));
    store.dispatch(updateSocialSettings(socialSettings));
    updateHeaderLayout(headerTarget.dataset.layout);
    if (headerTarget.dataset.editMode) {
        const customPages = JSON.parse(headerTarget.dataset.customPages);
        store.dispatch(updateEditMode(true));
        store.dispatch(updateCustomPages(customPages));
    }
    const root = createRoot(headerTarget);
    const showConfirmation = queryString.get(ConfirmationModalQueryStringKey);
    const confirmationModal = showConfirmation ? (
        <ConnectedLoginConfirmation />
    ) : null;
    root.render(
        <Provider store={store}>
            <ConnectedPageHeader />
            {confirmationModal}
        </Provider>,
    );
    window.QGIV.AuctionHeader = {
        updateHeaderLayout,
    };
}

// item list
const renderAuctionItemListWidgets = () => {
    const widgets = document.querySelectorAll(".auction-item-list-widget");

    if (!widgets.length) {
        return;
    }

    debug(`renderAuctionItemListWidgets: ${widgets.length}`);

    [...widgets].forEach((element) => {
        const { control, controlId } = element.dataset;
        const cmsSettings = JSON.parse(control);
        store.dispatch(addControl(cmsSettings));

        const elementRoot = createRoot(element);
        // Render App
        elementRoot.render(
            <Provider store={store}>
                <ConnectedItemList id={controlId} />
            </Provider>,
        );
    });
};

renderAuctionItemListWidgets();
// NOTE: expose the method for reloading in cms
window.QGIV.AuctionItemListReloader = renderAuctionItemListWidgets;

// item details
const renderAuctionItemDetailsWidget = (itemId) => {
    if (!itemId) return;
    const widget = document.querySelector(
        `.auction-item-details-${itemId}-widget`,
    );
    if (!widget) return;
    const widgetRoot = createRoot(widget);
    widgetRoot.render(
        <Provider store={store}>
            <ConnectedItemDetails id={widget.dataset?.previewId || itemId} />
        </Provider>,
    );
};
const updateAuctionItemPreviewSelect = () => {
    const select = document.querySelector(".js-auction-product-preview-select");
    if (!select) {
        return;
    }

    select.addEventListener("change", (e) => {
        const widget = document.querySelector(
            `.auction-item-details-preview-widget`,
        );
        if (!widget || !e.target.value) {
            return;
        }
        widget.setAttribute("data-preview-id", e.target.value);

        renderAuctionItemDetailsWidget("preview");
    });
};

// page url: event/{alias}/item/{itemId}
const productRouteMatch = window.location.pathname.match(
    /\/item\/(?<itemId>\d+)\/?$/,
);
// cms url: control/forms/{formId}/cms/auctionproduct/
const cmsRouteMatch = /^\/control\/forms\/\d+\/cms\/auctionproduct\/?$/.test(
    window.location.pathname,
);
if (productRouteMatch?.groups) {
    const { itemId } = productRouteMatch.groups;
    debug(`load item details ${itemId}`);
    renderAuctionItemDetailsWidget(itemId);
} else if (cmsRouteMatch) {
    debug(`load CMS preview item details`);
    renderAuctionItemDetailsWidget("preview");
    updateAuctionItemPreviewSelect();
}
// NOTE: expose the method for reloading in cms
window.QGIV.AuctionItemDetailsReloader = renderAuctionItemDetailsWidget;

const activityPage = document.querySelector("#auction-account-activity");
if (activityPage) {
    const activityPageRoot = createRoot(activityPage);
    activityPageRoot.render(
        <Provider store={store}>
            <ConnectedActivityPage />
        </Provider>,
    );
}

// setup login modal
(() => {
    debug(`renderAuctionLoginModal`);

    let loginModalContainer = document.querySelector(".js-auction-login-modal");
    if (!loginModalContainer) {
        document.body.insertAdjacentHTML(
            "beforeend",
            `<div class="js-auction-login-modal"></div>`,
        );

        loginModalContainer = document.querySelector(".js-auction-login-modal");

        // setup message listener for login modal
        window.addEventListener("message", (m) =>
            auctionLoginMessageHandler(m, store),
        );
    }

    const formTitle = document.querySelector("#form-title")?.value;

    // Render App
    const elementRoot = createRoot(loginModalContainer);
    elementRoot.render(
        <Provider store={store}>
            <ConnectedEventLoginRegisterModal
                eventId={window.QGIV.Event.id}
                eventName={formTitle}
                isPostRegistration
            />
        </Provider>,
    );
})();

// setup registration button handler
(() => {
    const registrationButton = document.querySelector(
        ".form-auction .js-display-registration-button .ui-button",
    );
    if (!registrationButton) {
        return;
    }

    // NOTE: as we update the url when the modal id closed, this will re-open it when the back button is clicked
    window.addEventListener("popstate", () => {
        if (!hasRegistrationQueryString()) return;
        registrationButton.dispatchEvent(new Event("click"));
    });
})();

// setup donation button handler
(() => {
    const donationButton = document.querySelector(
        ".form-auction .js-display-donation-button .ui-button",
    );
    if (!donationButton) {
        return;
    }

    const launchDonationModal =
        document.querySelector("#launch-modal-on-load")?.value ?? "0";

    if (Number(launchDonationModal)) {
        donationButton.dispatchEvent(new Event("click"));
    }
})();

/** Setup global modal event handlers */
(() => {
    const globalModalRootElement = document.createElement("div");

    document.body.appendChild(globalModalRootElement);
    const globalModalRoot = createRoot(globalModalRootElement);

    globalModalRoot.render(
        <Provider store={store}>
            <ConnectedGlobalModal />
        </Provider>,
    );

    // setup message listener for global modals
    window.addEventListener("message", (m) => modalMessageHandler(m, store));
})();

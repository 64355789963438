import React, { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { Icon } from "@qgiv/core-react";
import Button, { ButtonStyle } from "../Button";

export const ModalSize = {
    Small: "md:w-3/5 lg:w-1/3",
    Medium: "md:w-3/4 lg:w-2/5",
    Large: "md:w-5/6 lg:w-1/2",
};

/**
 * @param {object} props
 * @param {React.ReactNode} props.title
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 * @param {boolean} props.show for opening as a modal dialog
 * @param {Function} props.closeHandler
 * @param {boolean} [props.open] for opening as a dialog element for testing
 * @returns {React.ReactNode}
 */
const Dialog = ({ title, children, className, show, closeHandler, open }) => {
    const dialogRef = useRef(null);
    const closeClickHandler = () => {
        dialogRef.current.close();
        closeHandler();
    };
    // NOTE: call the showModal method once the dialog is rendered
    useEffect(() => {
        if (!show || !dialogRef || !dialogRef.current?.showModal) return;
        dialogRef.current.showModal();
    }, [show, dialogRef]);

    if (!show && !open) return null;

    return (
        <dialog
            id="dialog-modal"
            aria-labelledby="dialog-modal-title"
            open={open}
            className={twMerge(
                "rounded-[4px] p-7 backdrop:bg-[#39414650]",
                className && className,
            )}
            onClose={closeClickHandler}
            ref={dialogRef}
        >
            <div className="flex justify-end">
                <Button
                    aria-label="Close"
                    btnStyle={ButtonStyle.Text}
                    size=""
                    className="p-0"
                    onClick={closeClickHandler}
                >
                    <Icon glyph="times-regular" type="FontAwesome" ariaHidden />
                </Button>
            </div>
            <h1
                id="dialog-modal-title"
                className="mt-0 pb-4 text-center text-xl font-bold"
            >
                {title}
            </h1>
            {children}
        </dialog>
    );
};

export default Dialog;

// @ts-check
import React from "react";
import { getElapsedTime } from "../../../utility";
import "./ItemActivityFeed.scss";

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.bidder
 * @param {string} props.amount
 * @param {string} props.timestamp_iso
 * @param {number} props.index
 * @returns {React.JSX.Element}
 */
const ItemActivityFeedItem = ({ id, bidder, amount, timestamp_iso, index }) => (
    <div
        className="item-activity flex items-center justify-between text-[13px] text-[color:var(--Theme-Text-Black,#394146)]"
        data-testid={id}
        data-testindex={index}
    >
        <div className="flex flex-wrap">
            <span className="w-full">#{bidder}</span>
            <strong className="w-full font-bold">${amount}</strong>
        </div>
        <div>{getElapsedTime(timestamp_iso, Date.now())}</div>
    </div>
);

export default ItemActivityFeedItem;

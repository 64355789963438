import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { ImageSlider } from "@qgiv/core-react";
import { Dialog, ImageZoom } from "../../core";

/**
 * @param {object} props
 * @param {import("../../../types/Image").Image[]} props.images
 * @param {boolean} props.show
 * @param {boolean} [props.open]
 * @param {Function} props.closeHandler
 * @returns {React.ReactNode}
 */
const ItemImagesModal = ({ images, show, open, closeHandler }) => {
    const [activeImageKey, onToggle] = useState(0);
    const thumbnailSection =
        images.length > 1 ? (
            <div className="order-2 flex w-full flex-wrap content-start items-start justify-center gap-3 md:order-1 md:w-2/5 md:justify-start">
                {images.map(({ id, filename, url }, index) => (
                    <div
                        key={id}
                        className={twMerge(
                            "flex w-20 items-center justify-center rounded border border-solid p-2",
                            activeImageKey === index && "border-primary",
                            activeImageKey !== index && "border-transparent",
                        )}
                    >
                        <ImageSlider.Thumbnails
                            imageKey={index}
                            shape="squared"
                            size="large"
                            element="div"
                        >
                            <img
                                className="max-h-10"
                                src={url}
                                alt={filename}
                            />
                        </ImageSlider.Thumbnails>
                    </div>
                ))}
            </div>
        ) : null;

    return (
        <Dialog
            title="Item Images"
            show={show}
            open={open}
            closeHandler={closeHandler}
            className="md:w-5/6 lg:w-3/4"
        >
            <div className="border-t border-solid border-[#CFD7DA] pt-6">
                <ImageSlider
                    activeImageKey={activeImageKey}
                    onToggle={onToggle}
                    images={images}
                    className="flex flex-col gap-3 md:flex-row"
                >
                    {thumbnailSection}
                    <div className="order-1 flex size-full flex-col items-stretch justify-center">
                        {images.map(({ id, filename, url }, index) => (
                            <ImageSlider.Content
                                key={id}
                                imageKey={index}
                                imageCount={images.length}
                                hideArrowButtons
                                className="grow"
                            >
                                <ImageZoom
                                    src={url}
                                    alt={filename}
                                    size="md:max-h-[400px]"
                                />
                            </ImageSlider.Content>
                        ))}
                    </div>
                </ImageSlider>
            </div>
        </Dialog>
    );
};

export default ItemImagesModal;

import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Icon } from "@qgiv/core-react";
import { constants } from "@qgiv/core-js";
import {
    Button,
    ButtonStyle,
    getMask,
    Input,
    MaskedInput,
    MaskType,
    Message,
    Dialog,
} from "../../core";
import { getInitialValues, getValidationSchema } from "./validation";
import { getAxiosResponseError } from "../../../api";
import { addToastSuccess } from "../../system";

const {
    ENUMS: { TicketHolderNotificationType },
} = constants;

/**
 * @param {object} props
 * @param {Function} props.closeHandler
 * @param {import("../../../types").Attendee} props.attendee
 * @param {Function} props.updateTicketAsync
 * @param {boolean} [props.includeCompany]
 * @param {boolean} [props.show]
 * @param {boolean} [props.open]
 * @returns {React.ReactNode}
 */
const TicketDetailsModal = ({
    closeHandler,
    attendee,
    updateTicketAsync,
    includeCompany,
    show,
    open,
}) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [busy, setBusy] = useState(false);
    const [notificationTypes, setNotificationTypes] = useState(
        attendee.notificationTypes,
    );

    const toggleNotificationTypes = (type, add) => {
        setNotificationTypes(
            add
                ? [...notificationTypes, type]
                : notificationTypes.filter((t) => t !== type),
        );
    };
    const onSubmitHandler = async (values) => {
        try {
            setBusy(true);
            // ensure that push notification isn't changed
            if (
                attendee.notificationTypes.includes(
                    TicketHolderNotificationType.PUSH,
                )
            ) {
                values.notificationTypes.push(
                    TicketHolderNotificationType.PUSH,
                );
            }
            const response = await updateTicketAsync({
                ...values,
                notificationTypes,
            });
            if (!response.success) throw response;
            closeHandler();
            addToastSuccess("Success!");
        } catch (err) {
            const errors = getAxiosResponseError(err);
            setErrorMessage(errors.join(", "));
            setBusy(false);
        }
    };

    return (
        <Dialog
            closeHandler={closeHandler}
            show={show}
            title="My Ticket Details"
            open={open}
        >
            <Message timeout={7000} clearHandler={setErrorMessage}>
                {errorMessage}
            </Message>
            <Formik
                initialValues={getInitialValues(attendee)}
                validationSchema={getValidationSchema()}
                onSubmit={onSubmitHandler}
            >
                {({ touched, errors, isSubmitting, setTouched, values }) => (
                    <Form>
                        {includeCompany && (
                            <Input
                                name="company"
                                label="Company"
                                className="pb-3"
                            />
                        )}
                        <div className="flex gap-3 pb-3">
                            <Input
                                name="firstName"
                                label="First name"
                                className="grow"
                            />
                            <Input
                                name="lastName"
                                label="Last name"
                                className="grow"
                            />
                        </div>
                        <MaskedInput
                            name="phoneNumber"
                            {...getMask(MaskType.Phone, "Mobile phone")}
                        />
                        <div className="bg-slate-100 my-3 flex items-center justify-between rounded border border-solid border-[#AAB6BC] px-3 py-4 shadow-inner">
                            <em>{attendee.email}</em>
                            <Icon
                                glyph="lock-alt-solid"
                                type="FontAwesome"
                                ariaHidden
                            />
                        </div>

                        <fieldset className="border-0 m-0 p-0">
                            <legend className="pt-3 text-lg font-bold">
                                Notification Preferences
                            </legend>
                            <label
                                htmlFor="notificationTypesEmail"
                                className="flex items-baseline gap-2 py-3"
                            >
                                <input
                                    id="notificationTypesEmail"
                                    className="focus:shadow-[0_0_0_2px_white,0_0_0_3px_black]"
                                    type="checkbox"
                                    checked={notificationTypes.includes(
                                        TicketHolderNotificationType.EMAIL,
                                    )}
                                    value={TicketHolderNotificationType.EMAIL}
                                    onChange={({ target: { checked } }) => {
                                        toggleNotificationTypes(
                                            TicketHolderNotificationType.EMAIL,
                                            checked,
                                        );
                                        if (touched.length) return;
                                        setTouched(["notificationTypes"]);
                                    }}
                                />
                                <span className="text-[#66737D]">
                                    I would like to receive email notifications
                                </span>
                            </label>
                            <label
                                htmlFor="notificationTypesText"
                                className="flex items-baseline gap-2 py-3"
                            >
                                <input
                                    id="notificationTypesText"
                                    className="focus:shadow-[0_0_0_2px_white,0_0_0_3px_black]"
                                    type="checkbox"
                                    checked={
                                        !!values.phoneNumber &&
                                        notificationTypes.includes(
                                            TicketHolderNotificationType.SMS,
                                        )
                                    }
                                    value={TicketHolderNotificationType.SMS}
                                    onChange={({ target: { checked } }) => {
                                        toggleNotificationTypes(
                                            TicketHolderNotificationType.SMS,
                                            checked,
                                        );
                                        if (touched.length) return;
                                        setTouched(["notificationTypes"]);
                                    }}
                                    disabled={!values.phoneNumber}
                                />
                                <span className="text-[#66737D]">
                                    I would like to receive text notifications
                                </span>
                            </label>
                        </fieldset>

                        <div className="flex justify-center gap-3 pt-5">
                            <Button
                                type="submit"
                                className="grow"
                                btnStyle={ButtonStyle.Primary}
                                disabled={
                                    busy ||
                                    Object.keys(touched).length < 1 ||
                                    Object.keys(errors).length ||
                                    isSubmitting
                                }
                            >
                                Save
                            </Button>
                            <Button
                                className="grow"
                                onClick={closeHandler}
                                btnStyle={ButtonStyle.Secondary}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default TicketDetailsModal;

import React, { forwardRef, Fragment } from "react";
import { Combobox } from "@headlessui/react";
import { Icon } from "@qgiv/core-react";
import { ButtonPill } from "../Button";

/**
 * @param {object} props - The properties object.
 * @param {Function} props.closeOptions
 * @param {Function} props.onChange
 * @param {Function} props.openOptions
 * @param {string} [props.placeholder]
 * @param {string} props.query
 * @param {object|object[]} props.selectedOptions
 * @param {Function} props.setQuery
 * @param {boolean} props.showButtonOptions
 * @param {object|object[]} props.options
 * @param {boolean} [props.showOptGroupInSelection]
 * @param {string|string[]} [props.value]
 * @returns {React.JSX.Element} The rendered Trigger component.
 */
const Trigger = forwardRef(
    (
        {
            closeOptions,
            onChange,
            openOptions,
            options,
            placeholder = "",
            query,
            selectedOptions,
            setQuery,
            showButtonOptions,
            showOptGroupInSelection = false,
            value = "",
        },
        ref,
    ) => {
        /**
         * @function onOptionButtonClick
         * @description Deselect option when button is clicked
         * @param {object} option Object for the option to remove. E.g.
         *      {value: 123, name: "Remove Option"}
         */
        const onOptionButtonClick = (option) => {
            const newSelectedOptions = [...selectedOptions];
            const removedIndex = selectedOptions.indexOf(option);

            // don't remove irremovable options
            if (option.irremovable) return;

            newSelectedOptions.splice(removedIndex, 1);

            onChange(newSelectedOptions);
        };

        const getSelectedText = () => {
            if (Array.isArray(selectedOptions)) {
                return query;
            }

            if (showOptGroupInSelection) {
                const reversedOptions = [...options].reverse();
                const selectedOption = options.find(
                    (option) => option.value === value,
                );
                // Find the "optgroup" the selected option belongs to. Find the
                // last group option with an index less than the selected option.
                const groupName = reversedOptions.find(
                    (option, optionIndex) =>
                        option.group &&
                        optionIndex > reversedOptions.indexOf(selectedOption),
                )?.name;

                return `${groupName} > ${selectedOptions.name}`;
            }

            return selectedOptions.name;
        };

        return (
            <Combobox.Button as={Fragment}>
                {({ disabled }) => (
                    <div className="combo-box__trigger">
                        <div className="combo-box__selected">
                            {showButtonOptions &&
                                selectedOptions.map((option) => (
                                    <ButtonPill
                                        irremovable={option.irremovable}
                                        key={option.value}
                                        onClick={() =>
                                            onOptionButtonClick(option)
                                        }
                                        value={option.value}
                                    >
                                        {option.name}
                                    </ButtonPill>
                                ))}
                            <Combobox.Input
                                displayValue={() => getSelectedText()}
                                onChange={(event) =>
                                    setQuery(event.target.value)
                                }
                                onBlur={closeOptions}
                                onFocus={() => {
                                    ref?.current?.select();
                                    openOptions.call(null);
                                }}
                                placeholder={placeholder}
                                {...{ ref }}
                            />
                        </div>
                        <button
                            className="combo-box__button"
                            type="button"
                            {...{ disabled }}
                        >
                            <Icon
                                glyph="chevron-down-solid"
                                label="open combo box"
                                type="FontAwesome"
                            />
                        </button>
                    </div>
                )}
            </Combobox.Button>
        );
    },
);

Trigger.displayName = "Trigger";

export default Trigger;

import { parseCamelCasePropertyObject } from "../../utility";

/**
 * @description converts legacy control to camel case properties
 * @param {any} legacyControl legacy control with snake case properties
 * @returns {any} object with camel case properties
 */
export default function parseControl(legacyControl) {
    const control = parseCamelCasePropertyObject(legacyControl);
    if (control.options) {
        control.options = parseCamelCasePropertyObject(control.options);
    }
    return control;
}

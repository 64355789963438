import React from "react";
import { twMerge } from "tailwind-merge";
import "./HeaderDropdown.scss";

/** @enum {string} */
export const DropdownContentWidth = {
    Auto: "w-auto",
    100: "w-[100px]",
    150: "w-[150px]",
    200: "w-[200px]",
    300: "w-[300px]",
    400: "w-[400px]",
};

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 * @returns {React.JSX.Element}
 */
const HeaderDropdown = ({ children, className = "" }) => (
    <div className={twMerge("-header-dropdown", className && className)}>
        {children}
    </div>
);

HeaderDropdown.MenuItem = function MenuItem(
    /** @type {{children: React.node}} */ { children },
) {
    return <div className="-header-dropdown__item">{children}</div>;
};

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} [props.show]
 * @param {DropdownContentWidth} [props.width]
 * @param {string} [props.theme]
 * @returns {React.JSX.Element}
 */
HeaderDropdown.DropdownContent = function DropdownContent({
    children,
    show = false,
    width = DropdownContentWidth.Auto,
    theme = "-background-color--system-white main-navigation theme-only",
}) {
    return show ? (
        <div className={twMerge("-header-dropdown__content", theme, width)}>
            {children}
        </div>
    ) : null;
};

export default HeaderDropdown;

// @ts-check
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Cart from "../Cart";
import ActivityPageRoot from "./ActivityPageRoot";
import { ActivityFilters, getEventRoute, EventPage } from "../../../utility";
import "./ActivityPage.scss";
import { IconContentWrapper } from "../../system/IconContentWrapper";
import ItemCardList from "../ItemCardList/ItemCardList";
/** @typedef {import("../../../types").Activity} Activity */
/** @typedef {import("../../../types").Product} Product */
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Auction} Auction */

/**
 * @param {object} props
 * @param {Product[]} props.items
 * @param {Activity[]} props.pendingItems
 * @param {Activity[]} props.completedItems
 * @param {Attendee} props.attendee
 * @param {number} props.time
 * @param {string[]} props.favorites
 * @param {Auction} props.auction
 * @param {Function} props.onWatchHandler
 * @param {Function} props.actionHandlerAsync
 * @param {Array} props.transactions
 * @param {object} props.account
 * @param {Function} props.useDispatch
 * @param {Function} props.fetchTransactions
 * @param {string} props.transactionsStatus
 * @param {Function} props.payBalanceAsync
 * @param {string[]} props.activityIds
 * @returns {React.ReactNode}
 */
const ActivityPage = ({
    items,
    pendingItems,
    completedItems,
    attendee,
    favorites,
    auction,
    time,
    onWatchHandler,
    actionHandlerAsync,
    transactions,
    account,
    useDispatch,
    fetchTransactions,
    transactionsStatus,
    payBalanceAsync,
    activityIds,
}) => {
    const itemCardListArgs = {
        time,
        items,
        favorites,
        attendee,
        paymentMethods: account.paymentMethods,
        auction,
        onWatchHandler,
        actionHandlerAsync,
        activityIds,
    };
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <ActivityPageRoot
                    items={items}
                    pendingItems={pendingItems}
                    auction={auction}
                    attendee={attendee}
                    favorites={favorites}
                    transactions={transactions}
                    account={account}
                    useDispatch={useDispatch}
                    transactionsStatus={transactionsStatus}
                    fetchTransactions={fetchTransactions}
                    payBalanceAsync={payBalanceAsync}
                />
            ),
            errorElement: (
                <IconContentWrapper iconGlyph="search-solid">
                    <>
                        <h1>Page Not Found</h1>
                        <p>
                            The page you are looking for cannot be found. Please
                            make sure you&apos;ve entered the correct URL and
                            try again.
                        </p>
                    </>
                </IconContentWrapper>
            ),
            children: [
                {
                    path: "*",
                    element: (
                        <ItemCardList
                            activityFilter={ActivityFilters.WATCHING}
                            {...itemCardListArgs}
                        />
                    ),
                },
                {
                    path: getEventRoute(EventPage.Activity),
                    element: (
                        <ItemCardList
                            activityFilter={ActivityFilters.WATCHING}
                            {...itemCardListArgs}
                        />
                    ),
                },
                {
                    path: getEventRoute(EventPage.Bidding),
                    element: (
                        <ItemCardList
                            activityFilter={ActivityFilters.BIDDING}
                            {...itemCardListArgs}
                        />
                    ),
                },
                {
                    path: getEventRoute(EventPage.Cart),
                    element: (
                        <Cart
                            pendingItems={pendingItems}
                            completedItems={completedItems}
                            title={`${auction.title} - Cart`}
                        />
                    ),
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};

export default ActivityPage;

// @ts-check
import React from "react";
import InnerHTML from "dangerously-set-html-content";
import "./ItemDescriptionAndSubmittedBy.scss";

/**
 * @param {object} props
 * @param {string} [props.submittedBy]
 * @param {string} [props.description]
 * @returns {React.ReactNode}
 */
const ItemDescriptionAndSubmittedBy = ({ submittedBy, description }) => {
    if (!submittedBy && !description) return null;
    const submittedByElements = submittedBy ? (
        <em className="mb-4 block text-[13px] text-[#66737D]">
            Donated by: {submittedBy}
        </em>
    ) : null;
    return (
        <div>
            <hr className="-divider-hr my-2.5" />
            <h3 className="mb-1 text-lg font-bold leading-6 text-[color:var(--Theme-Text-Black,#394146)]">
                Item Details
            </h3>
            <p className={submittedBy ? "mt-0" : ""}>{submittedByElements}</p>
            <div className="-user-html-content self-stretch text-[15px] font-normal leading-[23px] tracking-[0.12px] text-[color:var(--Theme-Text-Black,#394146)]">
                {description ? <InnerHTML html={description} /> : null}
            </div>
        </div>
    );
};

export default ItemDescriptionAndSubmittedBy;

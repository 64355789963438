// @ts-check

import React from "react";
import { Icon } from "@qgiv/core-react";
import LoadingIcon from "../core/LoadingIcon/LoadingIcon";

import "./EventLoginRegistrationModal.scss";

const EventLoginRegistrationModal = (
    /** @type {{eventName:string, isModalOpen:boolean, onCloseModal:Function, children:React.ReactNode, isLoading:boolean}} */
    { isLoading = false, isModalOpen, eventName, onCloseModal, children = [] },
) => {
    /**
     * Handle closing modal
     */
    function closeModal() {
        onCloseModal?.call(undefined);
    }

    return (
        isModalOpen && (
            <div className="modal-container modal-container--register">
                <div className="modal -ui-border -centered -background-color--system-white">
                    {isLoading && (
                        <div className="modal-loading-icon -position--absolute -width--100">
                            <LoadingIcon />
                        </div>
                    )}
                    <div className="modal-title -display--flex -align-items--center -justify-content--space-between -font-weight--bold -text--center -padding--15">
                        <span />
                        {eventName}
                        <button
                            type="button"
                            aria-label="Close Modal"
                            onClick={closeModal}
                        >
                            <Icon
                                glyph="times-regular"
                                type="FontAwesome"
                                ariaHidden
                            />
                        </button>
                    </div>
                    <div className="p-[30px]">{children}</div>
                </div>
            </div>
        )
    );
};

export default EventLoginRegistrationModal;

// @ts-check
import React from "react";
import { Icon } from "@qgiv/core-react";
import { getActivityLabel, isPopular } from "../../../utility/itemHelpers";
import "./ItemStats.scss";
/** @typedef {import("../../../types").Product} Product */

/**
 * @param {object} props
 * @param {Product} props.item
 * @param {boolean} [props.isInList]
 * @returns {React.JSX.Element}
 */
const ItemStats = ({ item, isInList = false }) => {
    const activityLabel = getActivityLabel(item);
    return (
        <span
            className={`item__stats ${isInList ? "gap-2 text-[13px] text-[color:var(--Theme-Text-Black,#66737d)]" : "gap-3 text-[15px] text-[color:var(--Theme-Text-Black,#394146)]"} text-centerfont-normal flex items-center leading-[23px] tracking-[0.12px]`}
        >
            <span className="flex items-center gap-1 whitespace-nowrap">
                {isPopular(item.activityCount) ? (
                    <Icon
                        ariaHidden
                        className="-color--system-error"
                        glyph="fire"
                        type="FontAwesome"
                    />
                ) : null}
                {item.activityCount} {activityLabel}
            </span>
            <span className="text-[20px] leading-3 text-[color:var(--Theme-medium-dark,#66737d)] md:inline">
                •
            </span>
            <span className="flex items-center gap-1 whitespace-nowrap">
                <Icon
                    ariaHidden
                    className="-color--system-favorite"
                    glyph="bookmark-solid"
                    type="FontAwesome"
                />
                {`${item.watchers} watching`}
            </span>
        </span>
    );
};

export default ItemStats;

import React from "react";
import { twMerge } from "tailwind-merge";

import "./Button.scss";

/** @enum {string} */
export const ButtonSize = {
    xSmall: "xsmall",
    Small: "small",
    Regular: "regular",
    Large: "large",
    xxLarge: "xxlarge",
    None: "",
};
/** @enum {string} */
export const ButtonStyle = {
    Primary: "primary",
    Secondary: "secondary",
    Success: "success",
    Error: "error",
    Transparent: "transparent",
    Text: "text",
    Icon: "icon",
    None: "",
};
export const ButtonHighlightStyle = {
    Focus: "focus-visible:shadow-[0_0_0_1px_#ffffff99,0_0_0_2px_#00000099]",
    Hover: "hover:shadow-[0_0_0_1px_#ffffff99,0_0_0_2px_#00000099]",
};
const themeOverrides = [ButtonStyle.Primary, ButtonStyle.Secondary];

const Button = ({
    children,
    className = "",
    btnStyle = ButtonStyle.Primary,
    size = ButtonSize.Regular,
    ...rest
}) => (
    <button
        type="button"
        className={twMerge(
            "leading-none outline-none",
            ButtonHighlightStyle.Focus,
            ButtonHighlightStyle.Hover,
            !themeOverrides.includes(btnStyle) && `btn btn--${btnStyle}`,
            `btn--${size}`,
            themeOverrides.includes(btnStyle) &&
                `btn--${ButtonStyle.None} rounded-[5px] border border-solid`,
            btnStyle === ButtonStyle.Secondary &&
                "ui-background-color ui-text-primary-color ui-border-primary-color",
            btnStyle === ButtonStyle.Primary &&
                `ui-primary-color${rest.disabled ? "-lighten-20" : ""} ui-border-primary-color text-white`,
            btnStyle === ButtonStyle.None && "border-none",
            className,
        )}
        {...rest}
    >
        {children}
    </button>
);

export default Button;

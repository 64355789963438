import { createSelector } from "@reduxjs/toolkit";
import { AuctionBroadcastType } from "../../utilities";
/** @typedef {import("../../models/Activity/Activity").Activity} Activity */

export const getIsWatching = (id) => (state) =>
    state.favorites.value
        ? state.favorites.value.map((item) => item.product).includes(id)
        : false;

export const getIsLoading = (state) =>
    !state.feed.value || state.feed.value === AuctionBroadcastType.Connecting;

export const getBaseUrl = (state) =>
    state.auction.value
        ? `${window.location.origin}/event/${state.auction.value.alias}`
        : "";

/**
 * @description merge activity by product
 * @param {Activity[]} activity activity array
 * @returns {Activity[]} activity by product
 */
const mergeActivityByProduct = (activity) => {
    const productActivity = new Map();
    activity.forEach((/** @type {Activity} */ a) => {
        const id = a.isPaid ? a.productId : `${a.productId}-pending`;
        if (!productActivity.has(id)) {
            productActivity.set(id, a);
            return;
        }
        const existing = productActivity.get(id);
        productActivity.set(id, {
            ...existing,
            total: a.total + existing.total,
            qty: a.isBuyType ? a.qty + existing.qty : 0,
        });
    });
    return [...productActivity.values()];
};

export const getAllActivity = (state) => {
    const { purchases, donations } = state;
    return mergeActivityByProduct([...purchases.list, ...donations.list]);
};

export const getPendingActivity = createSelector(
    [(state) => getAllActivity(state)],
    (items) => items.filter(({ isPaid }) => !isPaid),
);

export const getCompletedActivity = createSelector(
    [(state) => getAllActivity(state)],
    (items) => items.filter(({ isPaid }) => isPaid),
);

export const getFavoriteProducts = createSelector(
    [(state) => state.favorites.value],
    (items) =>
        items
            ? items.filter((f) => f.product).map(({ product }) => product)
            : [],
);

export const getTime = (state) => state.timer.time;

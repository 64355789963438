import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RegistrationModalView, LoginPassword } from "@qgiv/auction";
import { validateEmail } from "@qgiv/core-validation";
import {
    selectEmail,
    setIsLoading,
    setModalView,
} from "../../../redux/loginRegistrationModalSlice";
import { signIn } from "../../../api/registration";

const ConnectedLoginPassword = () => {
    const email = useSelector(selectEmail);
    const dispatch = useDispatch();
    const [loginError, setLoginError] = useState(null);

    /**
     * Handle open event registration iframe.
     */
    function handleOpenLoginNoPassword() {
        dispatch(setModalView(RegistrationModalView.LOGIN_NO_PASSWORD));
    }

    /**
     * Handle open forgot password.
     */
    function handleOpenForgotPassword() {
        dispatch(setModalView(RegistrationModalView.FORGOT_PASSWORD));
    }

    /**
     * Handle on submit click.
     * @param {string} emailValue The email.
     * @param {string} passwordValue The password.
     */
    async function handleSubmitPasswordClickAsync(emailValue, passwordValue) {
        setLoginError(null);
        if (!emailValue || !passwordValue) {
            return;
        }

        if (!validateEmail(emailValue)) {
            setLoginError("Please enter a valid email address.");
            return;
        }

        dispatch(setIsLoading(true));

        try {
            const result = await signIn(emailValue, passwordValue);
            if (!result.data.success) {
                throw new Error("Invalid email or password.");
            }

            dispatch(setModalView(RegistrationModalView.CONFIRMATION));
        } catch (err) {
            setLoginError(err.message);
            return;
        } finally {
            dispatch(setIsLoading(false));
        }
    }

    return (
        <LoginPassword
            email={email}
            loginError={loginError}
            handleOpenLoginNoPassword={() => handleOpenLoginNoPassword()}
            handleOpenForgotPassword={() => handleOpenForgotPassword()}
            handleSubmitPasswordClick={async (emailValue, passwordValue) => {
                // devnote: without awaiting it, errors are not caught
                // eslint-disable-next-line no-return-await
                await handleSubmitPasswordClickAsync(emailValue, passwordValue);
            }}
        />
    );
};

export default ConnectedLoginPassword;

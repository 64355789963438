// @ts-check
import React, { useState } from "react";
import { Icon } from "@qgiv/core-react";
import Button, { ButtonStyle } from "../../core/Button/Button";
import {
    getPaymentMethodLabel,
    getPaymentMethodSupportingLabel,
} from "../../../utility";
import "./PaymentMethod.scss";
import { PaymentModalType } from "../../account";
import { usePaymentIframe } from "../../../hooks";
/** @typedef {import("../../../types").PaymentMethod} PaymentMethod */
/** @typedef {import("../../../types").Auction} Auction */

/**
 * @param {object} props
 * @param {PaymentMethod} props.currentPaymentMethod
 * @returns {React.ReactNode}
 */
const PaymentMethod = ({ currentPaymentMethod }) => {
    const [modal, setModal] = useState(PaymentModalType.None);
    const closeModalHandler = () => setModal(PaymentModalType.None);
    const paymentClickHandler = () => {
        setModal(PaymentModalType.AddPayment);
    };

    usePaymentIframe(modal, closeModalHandler);

    return (
        <div className="payment-method">
            <div className="text-center text-lg font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                Current Payment Method
            </div>
            {!currentPaymentMethod && (
                <Button
                    className="-color--system-white mt-2.5 w-full bg-black py-[15px]"
                    onClick={paymentClickHandler}
                >
                    Add Payment Method
                </Button>
            )}
            {currentPaymentMethod && (
                <Button
                    btnStyle={ButtonStyle.Secondary}
                    className="payment-method__btn mt-5 w-full !border-[#AAB6BC] p-2.5"
                    onClick={paymentClickHandler}
                >
                    <div className="flex content-start items-center gap-2 text-[#66737D]">
                        {currentPaymentMethod.card && (
                            <Icon
                                ariaHidden
                                className={`icon icon--matched icon--${currentPaymentMethod.card.type.toLowerCase()} mr-2.5`}
                                glyph={`cc-${currentPaymentMethod.card.type.toLowerCase()}-brands`}
                                type="FontAwesome"
                            />
                        )}
                        <div
                            data-testid="paymentMethodLabel"
                            className="flex w-full flex-col gap-1.5 text-left"
                        >
                            {getPaymentMethodLabel(currentPaymentMethod)}
                            <span className="text-[13px]">
                                {getPaymentMethodSupportingLabel(
                                    currentPaymentMethod,
                                )}
                            </span>
                        </div>
                    </div>
                </Button>
            )}
        </div>
    );
};

export default PaymentMethod;

import React from "react";
import Dialog, { ModalSize } from "../core/Dialog/Dialog";
import EventInstructions from "./EventInstructions";

/**
 * @param {object} props
 * @param {string} [props.seatLabelDisplay]
 * @param {string} [props.checkinText]
 * @param {string} [props.checkoutText]
 * @param {boolean} props.show
 * @param {boolean} [props.open]
 * @param {Function} props.closeHandler
 * @returns {React.ReactNode}
 */
const EventInstructionsModal = ({
    seatLabelDisplay,
    checkinText,
    checkoutText,
    closeHandler,
    show,
    open,
}) => (
    <Dialog
        show={show}
        open={open}
        closeHandler={closeHandler}
        title="Event Instructions"
        className={ModalSize.Medium}
    >
        <EventInstructions
            seatLabelDisplay={seatLabelDisplay}
            checkinText={checkinText}
            checkoutText={checkoutText}
            hideTitle
        />
    </Dialog>
);

export default EventInstructionsModal;

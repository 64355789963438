/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
    listInitialState,
    productStateMutator,
} from "./stateMutators/stateMutators";

const productsSlice = createSlice({
    name: "products",
    initialState: listInitialState,
    reducers: {
        updateProducts: productStateMutator,
    },
});

export default productsSlice.reducer;
export const { updateProducts } = productsSlice.actions;

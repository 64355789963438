import React from "react";
import { useField } from "formik";
import FieldError from "./FieldError";

const FormikFieldError = ({ name }) => {
    const [, meta] = useField({ name });
    return <FieldError>{meta.touched && meta.error}</FieldError>;
};

export default FormikFieldError;

import { createSlice } from "@reduxjs/toolkit";
import { valueInitialState, valueStateMutator } from "./stateMutators";

const auctionFeedSlice = createSlice({
    name: "feed",
    initialState: valueInitialState,
    reducers: {
        updateAuctionFeed: valueStateMutator,
    },
});

export default auctionFeedSlice.reducer;
export const { updateAuctionFeed } = auctionFeedSlice.actions;

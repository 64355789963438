import { qfetch } from "@qgiv/core-js";

/** @enum {string} */
export const System = {
    AUCTIONS: "auctions",
    EVENTS: "events",
};
/** @enum {string} */
export const Version = {
    V1: "v1",
    V2: "v2",
    V3: "v3",
};
/** @enum {string} */
export const Method = {
    POST: "post",
    PUT: "put",
    DELETE: "delete",
    GET: "get",
};

/**
 * @description for making an api request
 * @param {string} url request url
 * @param {object} postData request data
 * @param {Method} type method type
 * @returns {Promise<object>} promise
 */
async function apiRequestAsync(url, postData, type) {
    // NOTE: we'll move this to the app once the feature flag is no longer used
    if (window.QGIV?.Auction?.timestampHeartbeatAction) {
        window.QGIV.Auction.timestampHeartbeatAction();
    }

    const { data } = await qfetch[type](url, postData);
    // legacy end point check
    if (!data?.response) {
        if (data.errors?.length) {
            throw data.errors;
        }
        return data;
    }
    const { response } = data;

    if (response?.errors?.length) {
        throw response;
    }

    return response;
}

/**
 * @typedef ApiRepository
 * @property {(route:string, data:object) => Promise} getAsync get request
 * @property {(route:string, data:object) => Promise} postAsync post request
 * @property {(route:string, data:object) => Promise} putAsync put request
 * @property {(route:string, data:object) => Promise} deleteAsync delete request
 */
/**
 * @description for creating a repository with get, post, put, and delete methods
 * @param {string} baseUrl base url for every request
 * @param {object} baseData base data to be included on every request
 * @returns {ApiRepository} repository
 */
export function createRepository(baseUrl, baseData = {}) {
    // get the current user info
    return {
        getAsync: (route, data) =>
            apiRequestAsync(
                `/${baseUrl}/${route}`,
                { ...baseData, ...data },
                Method.GET,
            ),
        postAsync: (route, data) =>
            apiRequestAsync(
                `/${baseUrl}/${route}`,
                { ...baseData, ...data },
                Method.POST,
            ),
        putAsync: (route, data) =>
            apiRequestAsync(
                `/${baseUrl}/${route}`,
                { ...baseData, ...data },
                Method.PUT,
            ),
        deleteAsync: (route, data) =>
            apiRequestAsync(
                `/${baseUrl}/${route}`,
                { ...baseData, ...data },
                Method.DELETE,
            ),
    };
}

/**
 * @description for creating a repository for a system version with get, post, put, and delete methods
 * @param {System} system system route name
 * @param {Version} version version number string
 * @param {string} id system id
 * @param {object} baseData base data to include in every request
 * @returns {ApiRepository} repository
 */
export function createApiRepository(system, version, id, baseData = {}) {
    const baseUrl = `api/${version}/${system}/${id}`;
    return createRepository(baseUrl, baseData);
}

import store from "./store";
import { tick } from "./timerSlice";
import { updateAuction } from "./auctionSlice";
import { updateProducts } from "./productsSlice";
import { updateCategories } from "./categoriesSlice";
import { updatePurchases } from "./purchasesSlice";
import { updateDonations } from "./donationsSlice";
import { updateAuctionFeed } from "./auctionFeedSlice";
import {
    addControl,
    updateNavigation,
    updateSocialSettings,
    updateLayout,
    updateCustomPages,
    updateEditMode,
} from "./cmsSlice";
import { updateFavorites, addFavorite } from "./favoritesSlice";
import { updateUser } from "./userSlice";

export * from "./selectors";
export * from "./slices/transactionsSlice";
export * from "./modalSlice";

export default store;
export {
    updateAuction,
    updateProducts,
    tick,
    updateCategories,
    updatePurchases,
    updateAuctionFeed,
    updateUser,
    addControl,
    updateDonations,
    updateFavorites,
    addFavorite,
    updateNavigation,
    updateSocialSettings,
    updateLayout,
    updateCustomPages,
    updateEditMode,
};

import React from "react";
import { Icon } from "@qgiv/core-react";

const LoadingIcon = () => (
    <div className="text-5xl flex min-h-80 items-center justify-center">
        <Icon
            ariaHidden
            className="-animation--pulse"
            glyph="loader"
            type="FontAwesome"
        />
    </div>
);

export default LoadingIcon;

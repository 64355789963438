// @ts-check
import {
    currencyStringAccounting,
    getSingularPluralWordForm,
    constants,
} from "@qgiv/core-js";
import { hasPassed } from "./countdown";
/** @typedef {import("../types").Product} Product */
/** @typedef {import("../types").AttributeOption} AttributeOption */

const {
    ENUMS: { StoreProductType, MaxBidType },
} = constants;

/**
 * @description calculates if item is popular based on activity count
 * @param {number} activityCount activity count (bid/buy/donate)
 * @returns {boolean} is popular
 */
export const isPopular = (activityCount) => activityCount >= 10;

/**
 * @description gets the start time
 * @param {Product} product item
 * @returns {string} start time ISO string
 */
export function getStartTime({ isBidType, bidStartTimeIso, startTimeIso }) {
    if (isBidType && bidStartTimeIso) return bidStartTimeIso;
    return startTimeIso;
}

/**
 * @description gets the item amount
 * @param {Product} item item
 * @param {number} time
 * @returns {string} formatted amount
 */
export function getItemAmount(item, time = Date.now()) {
    const {
        currentPrice,
        cost,
        isBidType,
        bidIncrement,
        isDonateType,
        endTimeIso,
    } = item;
    if (isBidType) {
        if (currentPrice) return currencyStringAccounting(currentPrice);
        if (bidIncrement && !cost) {
            return currencyStringAccounting(bidIncrement);
        }
    }
    if (isDonateType && !cost) {
        switch (true) {
            case hasPassed(endTimeIso, time):
                return "Ended";
            case hasPassed(getStartTime(item), time):
                return "Open";
            default:
                return "Not Started";
        }
    }
    return currencyStringAccounting(cost);
}

/**
 * @description get the amount label
 * @param {Product} product item
 * @param {number} time
 * @returns {string} label
 */
export function getItemAmountLabel(
    { isBuyType, endTimeIso, bidCount, isDonateType, purchaseId },
    time = Date.now(),
) {
    if (isDonateType) return "Contribution";
    if (isBuyType) return "Price";

    if (hasPassed(endTimeIso, time) || purchaseId) return "Winning Amount";
    if (bidCount) return "Highest Bid";
    return "Starting Bid";
}

/**
 * @description get the item value
 * @param {Product} product item
 * @returns {string} item value
 */
export function getItemValue({
    displayItemValue,
    displayValue,
    fairMarketValue,
    isBidType,
}) {
    if (!isBidType) return "";
    if (displayValue) return displayItemValue;
    if (fairMarketValue) {
        return currencyStringAccounting(fairMarketValue, "USD");
    }
    return "";
}

/**
 * @description get the reserve not met text
 * @param {Product} product item
 * @returns {string} text
 */
export function getReserveNotMetText({
    isBidType,
    currentPrice,
    bidCount,
    reserve,
}) {
    if (!isBidType || !bidCount || currentPrice >= reserve) return "";
    return "Reserve not met";
}

/**
 * @param {Product} item
 * @returns {number}
 */
export const getIncrement = (item) =>
    item.isBidType ? Math.max(item.bidIncrement, 1) : 1;

/**
 * @param {Product} item
 * @param {string} [bidderId]
 * @returns {number}
 */
export function getNextBid(item, bidderId) {
    if (bidderId && bidderId === item.winner && item.maxUserBid) {
        return Math.max(item.maxUserBid + getIncrement(item));
    }
    return Math.max(item.cost, item.currentPrice + getIncrement(item));
}

/**
 * @description get the action button text, bid/purchase/contribute
 * @param {Product} item
 * @param {string} [bidderId]
 * @param {number} [maxBidType]
 * @param {number} [amount]
 * @param {boolean} [isLoggedIn]
 * @returns {string} button text
 */
export function getActionButtonText(
    item,
    bidderId,
    maxBidType = MaxBidType.SKIP,
    amount = 0,
    isLoggedIn = true,
) {
    if (!isLoggedIn) return "Log In";
    if (!bidderId) return "Register";
    if (item.isBuyType) return "Purchase";
    if (item.isDonateType) return "Contribute";
    // bid item
    if (item.maxBid && amount && item.maxBid === amount) {
        return "Buy it Now";
    }
    const builder = ["Place"];
    if (
        maxBidType === MaxBidType.INCREMENT &&
        (amount > getNextBid(item) || (item.winner && bidderId === item.winner))
    ) {
        builder.push("Max");
    }
    builder.push("Bid");
    return builder.join(" ");
}

/**
 * @param {number} maxBidType
 * @returns {string}
 */
export function getMaxBidText(maxBidType) {
    if (maxBidType === MaxBidType.SKIP) {
        return "Current Bid";
    }
    return "Current Max Bid";
}

/**
 * @param {Product} item
 * @param {string} [bidderId]
 * @returns {boolean}
 */
export function isWinning({ isBidType, winner }, bidderId) {
    if (!bidderId || !isBidType || !parseInt(winner, 10)) return false;
    return winner === bidderId;
}

/**
 * @param {Product} item
 * @returns {string}
 */
export function getActionSuccessText({ isBuyType, isDonateType }) {
    if (isBuyType) return "Purchased!";
    if (isDonateType) return "Contributed!";
    return "Bid Placed!";
}

/**
 * @description get the activity label
 * @param {Product} product item
 * @returns {string} label
 */
export function getActivityLabel({ isBuyType, isDonateType, activityCount }) {
    if (isBuyType) {
        return getSingularPluralWordForm("purchase(s)", activityCount);
    }
    if (isDonateType) {
        return getSingularPluralWordForm("contribution(s)", activityCount);
    }
    return getSingularPluralWordForm("bid(s)", activityCount);
}

/** @enum {number} */
export const ItemStatus = {
    None: 0,
    NoActivity: 1,
    OutOfStock: 2,
    LowStock: 3,
    HasActivity: 4,
    NoWinner: 5,
    Ended: 6,
    Won: 7,
    WonBidder: 8,
    Winning: 9,
    OutBid: 10,
    Popular: 11,
    NoBids: 12,
    NotStarted: 13,
};

/**
 * @description checks if action is disabled by status
 * @param {ItemStatus} status item status
 * @returns {boolean} is disabled statue
 */
export function isActionDisabledStatus(status) {
    const disabledStatuses = [
        ItemStatus.OutOfStock,
        ItemStatus.NoWinner,
        ItemStatus.Ended,
        ItemStatus.Won,
        ItemStatus.WonBidder,
        ItemStatus.NotStarted,
    ];
    return disabledStatuses.includes(status);
}

/**
 * @description get the item status text
 * @param {Product} item product
 * @param {number} time
 * @param {import("../types").Attendee} [attendee]
 * @param {boolean} [hasActivityForBidderId] has bid/purchase/contribution for bidder id
 * @returns {ItemStatus} status
 */
export function getItemStatus(
    item,
    time,
    attendee,
    hasActivityForBidderId = false,
) {
    if (!hasPassed(getStartTime(item), time)) {
        return ItemStatus.NotStarted;
    }
    if (!item.isBidType) {
        if (hasPassed(item.endTimeIso, time)) {
            return ItemStatus.Ended;
        }
        if (!item.activityCount) return ItemStatus.NoActivity;
        const qtyAvailable = item.maxQty - item.qtySold;
        if (item.isBuyType && item.maxQty && qtyAvailable < 1) {
            return ItemStatus.OutOfStock;
        }
        if (hasActivityForBidderId) return ItemStatus.HasActivity;
        if (item.maxQty && qtyAvailable <= 10) {
            return ItemStatus.LowStock;
        }
        return ItemStatus.None;
    }
    // bid item
    if (item.maxBid && item.currentPrice >= item.maxBid) {
        if (
            attendee?.bidderNumber &&
            item.itemWinner &&
            attendee?.bidderNumber === item.itemWinner
        ) {
            return ItemStatus.Won;
        }
        return ItemStatus.WonBidder;
    }

    if (hasPassed(item.endTimeIso, time)) {
        if (!item.activityCount) return ItemStatus.NoWinner;
        if (item.reserve && item.currentPrice < item.reserve) {
            return ItemStatus.Ended;
        }
        if (
            attendee?.bidderNumber &&
            item.itemWinner &&
            attendee?.bidderNumber === item.itemWinner
        ) {
            return ItemStatus.Won;
        }
        return ItemStatus.WonBidder;
    }
    if (!item.activityCount) return ItemStatus.NoBids;
    if (attendee?.id === item.winner) return ItemStatus.Winning;
    if (hasActivityForBidderId) return ItemStatus.OutBid;
    if (isPopular(item.activityCount)) return ItemStatus.Popular;
    return ItemStatus.None;
}

/** @enum {string} */
export const StatusColor = {
    Dark: "dark",
    Success: "success",
    Warning: "warning",
    Light: "light",
};

/**
 * @description gets the status color
 * @param {ItemStatus} itemStatus item status
 * @returns {StatusColor} color
 */
export function getItemStatusColor(itemStatus) {
    switch (itemStatus) {
        case ItemStatus.NoActivity:
        case ItemStatus.NoBids:
        case ItemStatus.Popular:
            return StatusColor.Dark;
        case ItemStatus.Winning:
        case ItemStatus.Won:
        case ItemStatus.HasActivity:
            return StatusColor.Success;
        case ItemStatus.OutBid:
        case ItemStatus.LowStock:
            return StatusColor.Warning;
        default:
            return StatusColor.Light;
    }
}

/**
 * @description get the item status text
 * @param {ItemStatus} itemStatus item status
 * @param {Product} item item
 * @returns {string} status text
 */
export function getItemStatusText(
    itemStatus,
    { isBuyType, maxQty, qtySold, winningBidderNumber },
) {
    switch (itemStatus) {
        case ItemStatus.NoActivity:
            return `Be the first to ${isBuyType ? "buy" : "contribute"}!`;
        case ItemStatus.OutOfStock:
            return "Out of stock";
        case ItemStatus.HasActivity:
            return `Thank you for ${
                isBuyType ? "purchasing" : "contributing"
            }!`;
        case ItemStatus.LowStock:
            return `${maxQty - qtySold} left in stock`;
        case ItemStatus.Ended:
            return "Item ended";
        case ItemStatus.NoWinner:
            return "No winning bids";
        case ItemStatus.Won:
            return "You won!";
        case ItemStatus.WonBidder:
            return `Bidder #${winningBidderNumber} won`;
        case ItemStatus.NoBids:
            return "Be the first to bid!";
        case ItemStatus.Winning:
            return "You're winning";
        case ItemStatus.OutBid:
            return "You're outbid";
        case ItemStatus.Popular:
            return "Popular Item";
        case ItemStatus.NotStarted:
            return "Not Started";
        case ItemStatus.None:
        default:
            return "";
    }
}

/**
 * @description get the last bid time
 * @param {Product} item item
 * @returns {string} bid time
 */
export function getLastBidTime({ isBidType, bidCount, bidHistory }) {
    if (!isBidType || !bidCount) return "";
    const lastBid = bidHistory[bidHistory.length - 1];
    return lastBid.timestamp_iso;
}

/**
 * Get list of ids for items that have activity for bidder.
 * @param {number} attendeeId bidder id.
 * @param {Array} activity List of donations.
 * @param {Array} products List of products.
 * @returns {Array} List of product ids with history.
 */
export function getActivityProductIds(attendeeId, activity, products) {
    if (!attendeeId) {
        return [];
    }

    // DEVNOTE: for some reason, donations use `productId` instead of `product`,
    // so just adding these fallbacks for both, in case this gets changed in the future.
    const productIds = new Set([
        ...activity.map((d) => d.product ?? d.productId),
        ...products
            .filter(
                (p) =>
                    p.isBidType &&
                    p.bidHistory?.some((bh) => bh.attendeeId === attendeeId),
            )
            .map((p) => p.product),
    ]);

    return [...productIds.values()];
}

/**
 * @description gets recommended bid amounts
 * @param {Product} item item
 * @param {string} [bidderId] bidder id
 * @returns {number[]} recommended bid amounts
 */
export function getRecommendedBidAmounts(item, bidderId) {
    const recommendedBids = [];
    if (item.categoryType !== StoreProductType.BID) return recommendedBids;
    const increment = item.bidIncrement || 1;
    if (increment <= 1) return recommendedBids;
    const nextBid = getNextBid(item, bidderId);
    const firstRecommended = nextBid + increment;

    if (item.maxBid && firstRecommended >= item.maxBid) return recommendedBids;
    const maxRecommended = firstRecommended + increment * 2;
    const max = item.maxBid
        ? Math.min(item.maxBid, maxRecommended)
        : maxRecommended;

    for (let bid = firstRecommended; bid <= max; bid += increment) {
        recommendedBids.push(bid);
    }

    return recommendedBids;
}

/**
 * @description get attribute max
 * @param {{options:AttributeOption[]}} attributeObject attribute
 * @param {string} [name] option id
 * @returns {number} max
 */
function getAttributeMax({ options }, name) {
    if (name) {
        const attr = options.find((a) => a.name === name);
        if (!attr || attr.unlimited) return Infinity;
        return Math.max(attr.maxQty - attr.qtySold, 0);
    }
    const hasUnlimited = options.find((a) => a.unlimited);
    if (hasUnlimited) return Infinity;
    const max = options.reduce(
        (total, a) => total + Math.max(a.maxQty - a.qtySold, 0),
        0,
    );
    return max;
}

/**
 * @description gets the max amount for purchase or bid
 * @param {Product} item item
 * @param {string} [attributeName] attribute id
 * @returns {number} max
 */
export function getMaxAmount(
    { isBuyType, maxBid, maxQty, qtySold, hasAttributes, attribute, unlimited },
    attributeName,
) {
    if (!isBuyType) {
        return maxBid || Infinity;
    }
    // store items
    if (hasAttributes) {
        return getAttributeMax(attribute, attributeName);
    }

    if (unlimited) return Infinity;
    return maxQty - qtySold;
}

/**
 * Get the total amount based on type
 * @param {string|number} amount selected amount
 * @param {boolean} isQtyType is quantity type
 * @param {number} cost item cost
 * @returns {string} Total amount.
 */
export function getTotalForItem(amount, isQtyType, cost) {
    let total = typeof amount === "number" ? amount : parseFloat(amount);

    if (!amount) return "0";

    if (isQtyType) {
        total *= cost;
    }

    return total.toFixed(total % 1 === 0 ? 0 : 2);
}

import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

const CreatePassword = (
    /** @type {{ handleSubmitPasswordClick?:Function, createError:string }} */
    { handleSubmitPasswordClick = null, createError = null },
) => {
    const [validationErrors, setValidationErrors] = useState({});
    const [passwordValue, setPasswordValue] = useState("");
    const [matchValue, setMatchValue] = useState("");
    const firstInput = useRef(null);

    useEffect(() => {
        firstInput?.current?.focus();
    }, [firstInput]);

    /**
     * Check if password is valid.
     * @param {string} password The password.
     * @returns {boolean} True if password is valid.
     */
    function isValidPassword(password) {
        if (password.length < 8) {
            return false;
        }

        const patterns = [
            /[A-Z]/, // has uppercase
            /[a-z]/, // has lowercase
            /[0-9]/, // has number
            /[!@#$%^&*()_\\\-=+{};:,<.?>]/, // has special character
        ];

        return patterns.filter((regex) => regex.test(password)).length >= 2;
    }

    /**
     * @param {string} password Password value.
     * @param {string} match Match password value.
     */
    function updateErrors(password, match) {
        setValidationErrors({
            password: !isValidPassword(password),
            match: password !== match,
        });
    }

    /**
     * @param {string} value Password value.
     */
    function updatePassword(value) {
        setPasswordValue(value);
        updateErrors(value, matchValue);
    }

    /**
     * @param {string} value Match password value.
     */
    function updateMatch(value) {
        setMatchValue(value);
        updateErrors(passwordValue, value);
    }

    return (
        <>
            <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                Create an account to start bidding!
            </div>
            {createError && (
                <div className="mt-[10px] rounded bg-[#d03424] p-[10px] text-center text-[15px] tracking-[0.12px] text-[#fff]">
                    {createError}
                </div>
            )}
            <input
                ref={firstInput}
                aria-label="Password"
                placeholder="Create a Password"
                data-testid="password-input"
                className={twMerge(
                    "mb-[10px] mt-[20px] w-full rounded-[5px] border border-solid border-[#AAB6BC] p-[15px] text-[15px] leading-[23px] tracking-[0.12px] shadow-inner focus-within:border-black",
                    validationErrors.password && "border-[#d03424]",
                )}
                type="password"
                value={passwordValue}
                onChange={(e) => updatePassword(e.target.value)}
            />
            <ul className="my-[10px] ml-[30px] list-disc text-[13px] leading-[21px] tracking-[0.5px] text-[#66737D]">
                <li
                    className={twMerge(
                        validationErrors.password &&
                            passwordValue.length < 8 &&
                            // eslint-disable-next-line
                            "text-[color:var(--user-theme\\_\\_color--primary,#1F6FD9)]",
                    )}
                >
                    Minimum of 8 characters
                </li>
                <li
                    className={twMerge(
                        "mt-[5px]",
                        validationErrors.password &&
                            // eslint-disable-next-line
                            "text-[color:var(--user-theme\\_\\_color--primary,#1F6FD9)]",
                    )}
                >
                    Must include at least two of the following: Special
                    characters, Upper/lowercase, Numbers
                </li>
            </ul>
            <input
                placeholder="Re-enter Password"
                data-testid="match-input"
                className={twMerge(
                    "my-[10px] w-full rounded-[5px] border border-solid border-[#AAB6BC] p-[15px] text-[15px] leading-[23px] tracking-[0.12px] shadow-inner focus-within:border-black",
                    validationErrors.match && "border-[#d03424]",
                )}
                type="password"
                value={matchValue}
                onKeyUp={(e) =>
                    e.key === "Enter" &&
                    handleSubmitPasswordClick(passwordValue, matchValue)
                }
                onChange={(e) => updateMatch(e.target.value)}
            />

            <button
                data-testid="submit-password-btn"
                className="btn btn--primary mt-[30px] w-full p-[12px] text-lg tracking-[0.12px]"
                type="button"
                disabled={
                    validationErrors.password ||
                    validationErrors.match ||
                    !passwordValue ||
                    !matchValue
                }
                onClick={() =>
                    handleSubmitPasswordClick(passwordValue, matchValue)
                }
            >
                Submit
            </button>
        </>
    );
};

export default CreatePassword;

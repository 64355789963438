// @ts-check
import React from "react";
import "./CartTable.scss";
import { Icon } from "@qgiv/core-react";
import { Tooltip } from "../../core";
/** @typedef {import("../../../types").Activity} Activity */

const CartTable = (
    /** @type {{title: string, tooltip:string, items: Activity[], balanceColumn:string}} */ {
        title,
        tooltip,
        items,
        balanceColumn,
    },
) => {
    const itemHeader = "Item";
    const amountHeader = "Amount";

    return (
        <div className="cart-table mb-12">
            <Tooltip
                text={tooltip}
                className="left-[120px] right-[-250px] top-[-25px]"
            >
                <div className="inline-flex items-center gap-1.5 text-[20px] font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                    {title}
                    <Icon
                        ariaHidden
                        className="text-base"
                        glyph="circle-exclamation-regular"
                        type="FontAwesome"
                    />
                </div>
            </Tooltip>
            <div className="flex-table mt-2 break-words" role="table">
                <div role="rowgroup">
                    <div
                        className="hidden flex-col md:flex md:flex-row"
                        role="row"
                    >
                        <div
                            className="grow basis-3/5 font-bold"
                            role="columnheader"
                        >
                            {itemHeader}
                        </div>
                        <div
                            className="grow basis-1/5 font-bold"
                            role="columnheader"
                        >
                            {amountHeader}
                        </div>
                        <div
                            className="grow basis-1/5 font-bold"
                            role="columnheader"
                        >
                            {balanceColumn}
                        </div>
                    </div>
                </div>
                <div role="rowgroup">
                    {items.map((item) => (
                        <div
                            key={item.id}
                            className="mb-3 flex flex-col md:mb-0 md:flex-row"
                            role="row"
                        >
                            <div
                                className="grow basis-full font-bold md:basis-3/5 md:font-normal"
                                role="cell"
                            >
                                #{item.productNumber} • {item.title}
                            </div>
                            <div
                                className="flex grow basis-1/5 justify-between md:justify-normal"
                                data-label="Amount"
                                role="cell"
                            >
                                <span className="font-bold md:hidden">
                                    {amountHeader}
                                </span>
                                {item.isDonateType ? (
                                    <span>${item.originalAmount}</span>
                                ) : (
                                    <span>
                                        ${item.unitCost}
                                        {item.qty > 1 ? ` x ${item.qty}` : ""}
                                    </span>
                                )}
                            </div>
                            <div
                                className="flex grow basis-1/5 justify-between font-bold md:justify-normal"
                                data-label={balanceColumn}
                                role="cell"
                            >
                                <span className="md:hidden">
                                    {balanceColumn}
                                </span>
                                <span
                                    className={`${
                                        item.transaction === "0"
                                            ? "-color--system-error"
                                            : ""
                                    }`}
                                >
                                    ${item.total}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CartTable;

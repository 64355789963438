/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
    activityStateMutator,
    listInitialState,
} from "./stateMutators/stateMutators";

const donationsSlice = createSlice({
    name: "donations",
    initialState: listInitialState,
    reducers: {
        updateDonations: activityStateMutator,
    },
});

export default donationsSlice.reducer;
export const { updateDonations } = donationsSlice.actions;

import React from "react";
import { IMaskInput } from "react-imask";
import cx from "classnames";
import { useField } from "formik";
import FormikFieldError from "./FormikFieldError";
import { formatExpiration, getInputClassName } from "./inputHelpers";

/**
 * @param {object} props
 * @param {string} props.name
 * @param {boolean} props.inline
 * @param {boolean} props.wrapped
 * @param {string} props.className
 * @param {object} props.props
 * @returns {React.ReactElement}
 */
const MaskedInput = ({ name, inline, wrapped, className, ...props }) => {
    const { placeholder } = props;
    const [field, meta, helpers] = useField({ name });

    const input = (
        <IMaskInput
            onAccept={(value) => {
                helpers.setValue(value);
            }}
            onComplete={(value) => {
                helpers.setValue(value);
            }}
            onBlurCapture={(e) => {
                if (name === "expiration") {
                    helpers.setValue(formatExpiration(e?.target?.value));
                }
            }}
            id={name}
            name={name}
            type="text"
            className={getInputClassName(
                inline,
                wrapped,
                meta.touched && meta.error,
            )}
            aria-label={placeholder}
            {...props}
            {...field}
        />
    );
    if (wrapped) {
        return input;
    }
    return (
        <div className={cx(className && className)}>
            {input}
            <FormikFieldError name={name} />
        </div>
    );
};

export default MaskedInput;

import { RegistrationModalType, RegistrationModalView } from "@qgiv/auction";
import {
    setFormId,
    setIsModalOpen,
    setModalType,
    setModalView,
} from "../redux/loginRegistrationModalSlice";

/**
 * @param {Function} dispatch
 * @param {string} id
 */
export function showLoginModal(dispatch, id) {
    dispatch(setFormId(id));
    dispatch(setModalType(RegistrationModalType.LOGIN));
    dispatch(setModalView(RegistrationModalView.LOGIN_NO_PASSWORD));
    dispatch(setIsModalOpen(true));
}

import { qfetch, LogInstance, constants } from "@qgiv/core-js";
import {
    createApiRepository,
    createRepository,
    getFormInfo,
    System,
    Version,
} from "./base";
import store, { getUser, updateUser } from "../redux";
/** @typedef {import("./base").ApiRepository} ApiRepository */
/** @typedef {import("@qgiv/auction/source/types").Attendee} Attendee */

const {
    ENUMS: { PaymentType },
} = constants;
const { log } = LogInstance("QGIV.api.account");
/** @type {Map<Version, ApiRepository>} */
const repo = new Map();
const getRepo = (version) => {
    if (!repo.has(version)) {
        repo.set(version, createApiRepository(System.AUCTIONS, version));
    }
    return repo.get(version);
};

/**
 * @description for logging out user
 * @returns {Promise} promise
 */
export async function logoutAsync() {
    const {
        data: { success },
    } = await qfetch.post("/account/login/", {
        logout: "1",
        dataType: "json",
    });
    return success;
}

/**
 * @description for changing current ticket
 * @param {string} id id
 * @returns {Promise} response
 */
export async function selectTicketAsync(id) {
    const response = await getRepo(Version.V3).postAsync("attendees/select", {
        attendee: id,
    });
    if (!response.success) throw response;
    log(response);
    return response;
}

/**
 * @description for changing payment method
 * @param {string} paymentMethod payment method id
 * @returns {Promise} response
 */
export async function selectPaymentMethodAsync(paymentMethod) {
    const {
        attendee: { ticketHolder },
    } = getUser(store.getState());
    const response = await getRepo(Version.V1).postAsync(
        `checkin/${ticketHolder}`,
        { paymentMethod },
    );
    if (!response.ticket) throw response;
    log(response.ticket);
    return { ...response, success: true };
}

/**
 * @description parses the payment info into the post object
 * @param {object} inputData input data
 * @returns {object} post data
 */
export function parsePaymentObject(inputData) {
    const { formId } = getFormInfo();
    const billing = {
        address: inputData.address,
        city: inputData.city,
        state: inputData.state,
        zip: inputData.zip,
        country: inputData.country,
        firstName: inputData.firstName,
        lastName: inputData.lastName,
    };
    const payment = {};
    switch (inputData.paymentType) {
        case PaymentType.CREDITCARD: {
            payment.account = inputData.cardNumber.replace(/\D/g, "");
            const {
                groups: { month, year },
            } = inputData.expiration.match(
                /^(?<month>1[0-2]|0[1-9])\/(?<year>\d{4})$/,
            );
            payment.expiry = {
                month,
                year,
            };
            payment.csc = inputData.cvv;
            break;
        }
        case PaymentType.ECHECK:
            payment.routing_no = inputData.routingNumber;
            payment.account_no = inputData.accountNumber;
            break;
        default:
            return null;
    }
    return {
        form: formId,
        payment: { title: "My Saved Payment" },
        Payment: payment,
        billing,
        payment_type:
            inputData.paymentType === PaymentType.ECHECK ? "check" : "card",
    };
}

/**
 * @description for adding a saved payment method
 * @param {object} data input data
 * @returns {Promise} response
 */
export async function addPaymentMethodAsync(data) {
    const {
        user: {
            value: { attendee, account },
        },
    } = store.getState();
    const postData = parsePaymentObject(data);
    const response = await createRepository(`account/${account.id}`).postAsync(
        "payments/add/",
        postData,
    );
    log(response);
    if (!response.success) throw response;
    await getRepo(Version.V1).postAsync(`checkin/${attendee.ticketHolder}`, {
        paymentMethod: response.paymentId,
    });
    return response;
}

/**
 * @description updates ticket details
 * @param {import("@qgiv/auction/source/types").Attendee} contact ticket details
 * @returns {Promise} response
 */
export async function updateTicketAsync(contact) {
    const {
        auction: {
            value: { id: formId },
        },
        user: {
            value: { attendee, account },
        },
    } = store.getState();
    const postData = {
        formId,
        contact,
    };
    const response = await getRepo(Version.V3).postAsync(
        `attendees/${attendee.id}`,
        postData,
    );
    if (!response.attendee) throw response;
    log(response.attendee);
    store.dispatch(updateUser({ attendee: response.attendee, account }));
    return { ...response, success: true };
}

/**
 * @description for paying attendee balance
 * @param {number} giftAssistAmount gift assist amount
 * @returns {Promise} response
 */
export async function payBalanceAsync(giftAssistAmount) {
    /** @type {{user:{value:{attendee:Attendee}}}} */
    const {
        user: {
            value: {
                attendee: { id, paymentMethod },
            },
        },
    } = store.getState();
    const paymentType = Object.keys(paymentMethod)[0];
    const route = `attendees/${id}/pay`;
    const payment = {
        cardId: paymentMethod[paymentType].id,
        type: "stored",
    };
    const data = {
        payment: JSON.stringify(payment),
    };

    if (giftAssistAmount) {
        data.giftAssistAmount = giftAssistAmount;
    }

    const response = await getRepo(Version.V3).postAsync(route, data);
    if (!response.attendee) throw response;
    return { ...response, success: true };
}

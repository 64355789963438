/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
    mergeFavoriteMutator,
    valueInitialState,
    valueStateMutator,
} from "./stateMutators/stateMutators";

const favoritesSlice = createSlice({
    name: "favorites",
    initialState: valueInitialState,
    reducers: {
        updateFavorites: valueStateMutator,
        addFavorite: mergeFavoriteMutator,
    },
});

export default favoritesSlice.reducer;
export const { updateFavorites, addFavorite } = favoritesSlice.actions;

// @ts-check
import React, { useCallback, useEffect, useState } from "react";
import Combobox from "../../core/ComboBox/ComboBox";
import Sidebar from "../../Sidebar";
import ItemListFilterSection from "../ItemListFilterSection";
import {
    applyFilters,
    defaultItemSort as defaultSort,
    getFilteredSortOptions,
    hasBiddingFilters,
    resetFilters,
} from "../../../utility";
/**
 * @typedef {object} FilterOptions
 * @property {object[]} categories - Array of category filter options.
 * @property {object[]} tags - Array of tag filter options.
 * @property {object[]} bids - Array of bid filter options.
 */
/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {Function} props.onChanged
 * @param {Function} [props.onClose]
 * @param {object[]} props.sortOptions
 * @param {string} [props.defaultItemSort]
 * @param {FilterOptions} props.filterOptions
 * @returns {React.JSX.Element}
 */
const ItemListFilter = ({
    onChanged,
    onClose = () => {},
    isOpen,
    sortOptions,
    defaultItemSort = defaultSort,
    filterOptions,
}) => {
    const [activeFilters, setActiveFilters] = useState({
        /** @type {Array[any]} */ categories: [],
        /** @type {Array[any]} */ tags: [],
        /** @type {Array[any]} */ bids: [],
    });
    const [activeSort, setActiveSort] = useState(defaultItemSort);
    const handleOnClose = useCallback(onClose, [onClose]);
    const hasBidding = hasBiddingFilters(activeFilters, filterOptions);
    const filteredSortOptions = getFilteredSortOptions(sortOptions, hasBidding);

    // if has biddable items, do nothing, otherwise reset filters & sort
    useEffect(() => {
        if (hasBidding) {
            return;
        }

        setActiveFilters((prev) => ({
            ...prev,
            bids: [],
        }));
        setActiveSort("numberasc");
    }, [hasBidding]);

    return (
        <Sidebar title="Filter" onClose={handleOnClose} isOpen={isOpen}>
            <Sidebar.Body>
                <div>
                    <Combobox
                        className="sidebar__filter"
                        value={activeSort}
                        onChange={setActiveSort}
                        options={filteredSortOptions}
                        label="Sort by"
                    />
                    <ItemListFilterSection
                        title="Categories"
                        options={filterOptions.categories}
                        idKey="id"
                        nameKey="name"
                        name="categories"
                        activeFilters={activeFilters}
                        setActiveFilters={setActiveFilters}
                    />
                    <ItemListFilterSection
                        title="Tags"
                        options={filterOptions.tags}
                        idKey="name"
                        nameKey="name"
                        name="tags"
                        activeFilters={activeFilters}
                        setActiveFilters={setActiveFilters}
                    />
                    {hasBidding && (
                        <ItemListFilterSection
                            title="Bids"
                            options={filterOptions.bids}
                            idKey="id"
                            nameKey="name"
                            name="bids"
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                        />
                    )}
                </div>
            </Sidebar.Body>
            <Sidebar.Actions>
                <button
                    type="button"
                    data-testid="apply-button"
                    className="btn btn--regular btn--primary grow"
                    onClick={() =>
                        applyFilters(
                            activeFilters,
                            activeSort,
                            onChanged,
                            onClose,
                        )
                    }
                >
                    Apply
                </button>
                <button
                    type="button"
                    data-testid="reset-button"
                    className="btn btn--regular btn--secondary grow"
                    onClick={() =>
                        resetFilters(
                            setActiveFilters,
                            setActiveSort,
                            onChanged,
                            onClose,
                        )
                    }
                >
                    Reset
                </button>
            </Sidebar.Actions>
        </Sidebar>
    );
};

export default ItemListFilter;

// @ts-check

import React, { useEffect } from "react";
import { EventLoginRegisterModal } from "@qgiv/auction";
import { useDispatch, useSelector } from "react-redux";
import {
    selectIsLoading,
    setEventId,
    setEventName,
    setEventUrl,
    selectIsModalOpen,
    resetModal,
} from "../../redux/loginRegistrationModalSlice";
import ModalViewSwitch from "./ModalViewSwitch";

const ConnectedEventLoginRegisterModal = (
    /** @type {{eventName:string, eventUrl:string, eventId:string, isPostRegistration:boolean}} */
    { eventName, eventUrl, eventId, isPostRegistration = false },
) => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(selectIsModalOpen);
    const isLoading = useSelector(selectIsLoading);

    useEffect(() => {
        dispatch(setEventUrl(eventUrl));
        dispatch(setEventId(eventId));
        dispatch(setEventName(eventName));
    }, [dispatch, eventId, eventName, eventUrl]);

    return isModalOpen ? (
        <EventLoginRegisterModal
            isLoading={isLoading}
            isModalOpen
            eventName={eventName}
            onCloseModal={() => {
                dispatch(resetModal(false));
            }}
        >
            <ModalViewSwitch isPostRegistration={isPostRegistration} />
        </EventLoginRegisterModal>
    ) : null;
};
export default ConnectedEventLoginRegisterModal;

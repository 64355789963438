// @ts-check
import React from "react";
import { PageHeader } from "@qgiv/auction";
import { useDispatch, useSelector } from "react-redux";
import {
    getPendingActivity,
    getAllCMS,
    getProducts,
    getUser,
    getTickets,
    getTransactions,
    getTransactionsStatus,
    fetchTransactions,
    getAuction,
} from "../../redux";
import {
    logoutAsync,
    updateNavigationCMS,
    selectTicketAsync,
    updateTicketAsync,
} from "../../api";
import { showLoginModal } from "../../utilities";
/** @typedef {import("../../models/Product/Product").Product} Product */
/** @typedef {import("@qgiv/auction/source/types").Auction} Auction */
/** @typedef {import("@qgiv/auction/source/types").Attendee} Attendee */
/** @typedef {import("@qgiv/auction/source/types").Account} Account */

/** @enum {string} */
const Layout = {
    Standard: "standard",
    Alternate: "alternate",
};

const ConnectedPageHeader = () => {
    const dispatch = useDispatch();
    /** @type {Product[]} */
    const products = useSelector(getProducts);
    const pendingItems = useSelector(getPendingActivity);
    const { navigation, socialSettings, layout, editMode, customPages } =
        useSelector(getAllCMS);
    /** @type {{attendee:Attendee, account:Account}} */
    const { attendee, account } = useSelector(getUser);
    const tickets = useSelector(getTickets);
    /** @type {Array} */
    const transactions = useSelector(getTransactions);
    const transactionsStatus = useSelector(getTransactionsStatus);
    const auction = useSelector(getAuction);

    const onLoginClick = () => {
        showLoginModal(dispatch, auction.id);
    };

    return (
        <PageHeader
            items={products}
            responsive={layout === Layout.Standard}
            cartCount={pendingItems.length}
            navigation={navigation}
            social={socialSettings}
            logoutAsync={logoutAsync}
            editMode={editMode}
            updateNavigationHandler={updateNavigationCMS}
            customPages={customPages}
            ticketSelectAsync={selectTicketAsync}
            tickets={tickets}
            transactions={transactions}
            account={account}
            useDispatch={useDispatch}
            transactionsStatus={transactionsStatus}
            fetchTransactions={fetchTransactions}
            attendee={attendee}
            auction={auction}
            updateTicketAsync={updateTicketAsync}
            onLoginClick={onLoginClick}
        />
    );
};

export default ConnectedPageHeader;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    EventLoginRegisterModal,
    LoginConfirmation,
    NoTicketsModal,
    PaymentRequired,
} from "@qgiv/auction";
import { getAuction, getIsLoading, getUser } from "../../../redux";

const ConnectedLoginConfirmation = () => {
    const isLoading = useSelector(getIsLoading);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const auction = useSelector(getAuction);
    /** @type {{attendee:import("@qgiv/auction/source/types").Attendee, account:import("@qgiv/auction/source/types").Account}} */
    const { attendee, account } = useSelector(getUser);

    useEffect(() => {
        window.history.replaceState({}, "", window.location.pathname);
        if (account && !account.anonymousEmail) return;
        // NOTE: we'll update this in https://bloomerang.atlassian.net/browse/QG-40521
        window.QGIV.Auction.openAnonymousEmailModal();
    });

    if (isLoading || !account) return null;

    if (account.anonymousEmail) {
        return null;
    }

    if (account && !attendee) {
        return (
            <NoTicketsModal
                email={account.username}
                show={isModalOpen}
                closeHandler={closeModal}
            />
        );
    }

    const { title, checkinText } = auction;
    const content = attendee?.paymentMethod ? (
        <LoginConfirmation
            email={attendee?.email}
            name={attendee?.bidderNameLabel}
            seatLabelDisplay={attendee?.seatLabelDisplay}
            checkinText={checkinText}
        />
    ) : (
        <PaymentRequired email={attendee?.email} closeHandler={closeModal} />
    );

    return (
        <EventLoginRegisterModal
            eventName={title}
            isModalOpen={isModalOpen}
            onCloseModal={closeModal}
        >
            {content}
        </EventLoginRegisterModal>
    );
};

export default ConnectedLoginConfirmation;

import {
    System,
    Version,
    Method,
    createRepository as createRepositoryBase,
    createApiRepository as createApiRepositoryBase,
} from "@qgiv/auction/source/api";
import store from "../redux";
/** @typedef {import("../../../auction/source/api/apiRepo").ApiRepository} ApiRepository */

export { System, Version, Method };

/**
 * @description gets session info for request
 * @returns {{bidderId:string, accountId:string, api_token:string}} session info
 */
export function getUserInfo() {
    const {
        user: {
            value: { attendee, account },
        },
    } = store.getState();
    return {
        bidderId: attendee.id,
        accountId: account.id,
        api_token: account.token,
    };
}

/**
 * @description gets the form details
 * @returns {{formId:string, eventId:string}} info
 */
export function getFormInfo() {
    const {
        auction: {
            value: { id: formId, eventId },
        },
    } = store.getState();
    return { formId, eventId };
}

/**
 * @description for creating a repository with get, post, put, and delete methods
 * @param {string} baseUrl base url
 * @returns {ApiRepository} repository
 */
export function createRepository(baseUrl) {
    // get the current user info
    const baseData = getUserInfo();
    return createRepositoryBase(baseUrl, baseData);
}

/**
 * @description for creating a repository for a system version with get, post, put, and delete methods
 * @param {System} system system route name
 * @param {Version} version version number string
 * @returns {ApiRepository} repository
 */
export function createApiRepository(system, version) {
    // get the current id for the system
    const {
        auction: {
            value: { id: formId, eventId },
        },
    } = store.getState();
    const id = system === System.AUCTIONS ? formId : eventId;
    const baseData = getUserInfo();
    return createApiRepositoryBase(system, version, id, baseData);
}

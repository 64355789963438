import { GlobalModalType } from "@qgiv/auction";
import { setGlobalModalType } from "../redux";
/**
 * @description Listen for messages to prompt global modals.
 * @param {MessageEvent} message The message event.
 * @param {object} store The redux store.
 */
export async function modalMessageHandler(message, store) {
    // for security, we need to make sure that the message comes from our environment or the registered widget
    if (typeof message.data !== "string" || message.data.length < 1) {
        return;
    }

    try {
        const { event, data } = JSON.parse(message.data);
        const modalType = GlobalModalType[data.modal];
        if (event !== "openGlobalModal" || !modalType) {
            return;
        }

        store.dispatch(setGlobalModalType(modalType));
    } catch (e) {
        /* empty */
    }
}

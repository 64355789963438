import { LogInstance } from "@qgiv/core-js";

const { debug, error } = LogInstance("QGIV.API.Session");
const createKey = (key) => `auction:cache:${key}`;

/**
 * @param {string} key
 * @param {any} value
 */
export function saveToSession(key, value) {
    try {
        if (!key || !value || !sessionStorage) return;
        debug(`saving ${key} to session storage`);
        sessionStorage.setItem(createKey(key), JSON.stringify(value));
    } catch (err) {
        if (err.name === "SecurityError") {
            debug("storage access denied", err.message);
            return;
        }
        error(err);
    }
}

/**
 * @param {string} key
 * @returns {any}
 */
export function getFromSession(key) {
    try {
        if (!key || !sessionStorage) return null;
        const value = sessionStorage.getItem(createKey(key));
        if (!value) return null;
        debug(`retrieved ${key} from session storage`);
        return JSON.parse(value);
    } catch (err) {
        if (err.name === "SecurityError") {
            debug("storage access denied", err.message);
            return null;
        }
        error(err);
        return null;
    }
}

/**
 * @returns {void}
 */
export function clearSession() {
    try {
        if (!sessionStorage) return;
        debug("clearing session storage");
        sessionStorage.clear();
    } catch (err) {
        if (err.name === "SecurityError") {
            debug("storage access denied", err.message);
            return;
        }
        error(err);
    }
}

import React, { useState, useRef, useEffect, useCallback } from "react";
import cx from "classnames";

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.text
 * @param {string} [props.className]
 * @returns {React.ReactNode}
 */
const Tooltip = ({ children, text, className }) => {
    const [visible, setVisible] = useState(false);
    const triggerRef = useRef(null);
    const tooltipRef = useRef(null);
    const titleId = "tooltip-title";
    const tooltipClasses =
        "absolute z-50 max-w-xs translate-y-2 transform rounded border border-gray-300 bg-[#f3f7f8] p-2 text-sm text-gray-800 shadow transition duration-200 ease-in-out";
    const [timeoutId, setTimeoutId] = useState(0);

    const cleatTimeoutId = useCallback(() => {
        clearTimeout(timeoutId);
        setTimeoutId(0);
    }, [timeoutId]);
    const showTooltip = () => {
        cleatTimeoutId();
        setVisible(true);
    };
    const hideTooltip = () => {
        cleatTimeoutId();
        setVisible(false);
    };
    // for allowing the user to move the mouse from the trigger to the tooltip
    const hideTooltipDelayed = () => {
        cleatTimeoutId();
        setTimeoutId(
            setTimeout(() => {
                setVisible(false);
                setTimeoutId(0);
            }, 100),
        );
    };
    const touchHandler = () => {
        if (visible) {
            hideTooltip();
            return;
        }
        showTooltip();
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === " " || e.key === "Spacebar" || e.key === "Enter") {
                e.preventDefault();
                cleatTimeoutId();
                setVisible(true);
                return;
            }
            if (e.key !== "Escape") return;
            cleatTimeoutId();
            setVisible(false);
        };

        const triggerElement = triggerRef.current;
        /* istanbul ignore next */
        if (triggerElement) {
            triggerElement.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            /* istanbul ignore next */
            if (triggerElement) {
                triggerElement.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [cleatTimeoutId]);

    return (
        <span className="relative">
            <span
                className="inline-block rounded focus-within:shadow-[0_0_0_1px_white,0_0_0_2px_black] focus:outline-none"
                aria-describedby={visible ? titleId : undefined}
                ref={triggerRef}
                // NOTE: we need the tab index for focus, blur, and escape to work
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex="0"
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltipDelayed}
                onFocus={showTooltip}
                onBlur={hideTooltip}
                onTouchStart={touchHandler}
                onTouchCancel={hideTooltip}
            >
                {children}
            </span>

            {visible && (
                <div
                    id={titleId}
                    role="tooltip"
                    className={cx(
                        tooltipClasses,
                        !className && "left-0 top-0 w-full",
                        className && className,
                    )}
                    ref={tooltipRef}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                    onTouchStart={showTooltip}
                    onTouchEnd={hideTooltip}
                    onTouchCancel={hideTooltip}
                >
                    {text}
                </div>
            )}
        </span>
    );
};

export default Tooltip;

// @ts-check
import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Icon } from "@qgiv/core-react";
import { twMerge } from "tailwind-merge";
import {
    getFilteredActivityItemsCount,
    ActivityFilters,
    getEventRoute,
    EventPage,
} from "../../../utility";
import "./ActivityPage.scss";
import Total from "../Total";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import { ButtonHighlightStyle } from "../../core";
/** @typedef {import("../../../types").Activity} Activity */
/** @typedef {import("../../../types").Product} Product */
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Auction} Auction */

/**
 * @param {object} props
 * @param {Product[]} props.items
 * @param {Activity[]} props.pendingItems
 * @param {Attendee} props.attendee
 * @param {string[]} props.favorites
 * @param {Auction} props.auction
 * @param {Array} props.transactions
 * @param {object} props.account
 * @param {Function} props.useDispatch
 * @param {Function} props.fetchTransactions
 * @param {string} props.transactionsStatus
 * @param {Function} props.payBalanceAsync
 * @returns {React.ReactNode}
 */
const ActivityPageRoot = ({
    items,
    pendingItems,
    auction,
    attendee,
    favorites,
    transactions,
    account,
    useDispatch,
    fetchTransactions,
    transactionsStatus,
    payBalanceAsync,
}) => (
    <div className="activity-responsive">
        <div className="rounded border-solid border-[#A1A1A1] bg-white p-3 xl:border xl:p-7 xl:pt-1">
            <div className="activity-page mb-1.5 flex">
                {Object.entries(ActivityFilters).map(([key, filter]) => {
                    const route = getEventRoute(EventPage[filter.label]);
                    return (
                        <NavLink
                            to={route}
                            key={key}
                            className={({ isActive }) =>
                                twMerge(
                                    "btn btn--large btn--tab mb-5 flex items-center p-4 hover:underline focus:outline-none",
                                    ButtonHighlightStyle.Focus,
                                    isActive && "ui-text-primary-color active",
                                    !isActive && "text-[#687982]",
                                )
                            }
                            end
                        >
                            <Icon
                                ariaHidden
                                className="mr-2.5"
                                glyph={filter.icon}
                                type="FontAwesome"
                            />
                            <span className="-display--from-md-not-active">
                                {filter.label}:&nbsp;
                            </span>
                            <span>
                                {ActivityFilters[key] === ActivityFilters.CART
                                    ? pendingItems.length
                                    : getFilteredActivityItemsCount(
                                          ActivityFilters[key],
                                          items,
                                          attendee.id,
                                          favorites,
                                      )}
                            </span>
                        </NavLink>
                    );
                })}
            </div>

            <div className="flex flex-col items-start gap-5 lg:flex-row">
                <div className="activity-list flex w-full flex-col gap-5 lg:w-auto lg:grow">
                    <Outlet />
                </div>
                <div className="flex w-full flex-col items-center justify-center gap-5 rounded-[5px] p-2.5 pb-5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] md:px-5 lg:w-[350px] xl:h-1/3">
                    <Total
                        auction={auction}
                        transactions={transactions}
                        account={account}
                        attendee={attendee}
                        useDispatch={useDispatch}
                        transactionsStatus={transactionsStatus}
                        fetchTransactions={fetchTransactions}
                        payBalanceAsync={payBalanceAsync}
                        pendingItems={pendingItems}
                    />
                    <hr className="w-full border-t border-solid border-[#A1A1A1]" />
                    <div className="w-full">
                        <PaymentMethod
                            currentPaymentMethod={attendee.paymentMethod}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ActivityPageRoot;

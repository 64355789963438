/** @enum {string} */
export const EventPage = {
    Home: "",
    LogIn: "login",
    Register: "register",
    Items: "items",
    Item: "item",
    Activity: "account/activity",
    Watching: "account/activity",
    Bidding: "account/activity/bidding",
    Cart: "account/activity/cart",
    Transaction: "checkout/receipt",
    Payment: "account/payment",
};

/**
 * @description gets a route in the current event
 * @param {EventPage} page page
 * @param {string} [id] id
 * @returns {string} route
 */
export function getEventRoute(page, id) {
    const parts = [];
    const match = window.location.pathname.match(
        /^(?<eventBase>\/event\/[0-9a-zA-Z-_]+)\/?/,
    );
    if (!match) {
        parts.push(page);
        if (id) parts.push(id);
        return parts.join("/");
    }
    const { eventBase } = match.groups;
    parts.push(eventBase, page);
    if (id) parts.push(id);
    return parts.join("/");
}

/**
 * @param {string} url
 * @returns {boolean}
 */
export const isCurrentRoute = (url) => window.location.pathname === url;

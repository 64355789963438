// @ts-check
import React from "react";
import { GiviStore, getStoreLegalText } from "../../../utility";

const StoreLegalFooter = () => (
    <footer className="mt-2.5 text-center text-[#66737D]">
        <small>
            <span>{getStoreLegalText(GiviStore.Apple)}</span>
            <br />
            <span>{getStoreLegalText(GiviStore.Google)}</span>
        </small>
    </footer>
);

export default StoreLegalFooter;

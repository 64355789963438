/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
    valueInitialState,
    auctionStateMutator,
} from "./stateMutators/stateMutators";

const auctionSlice = createSlice({
    name: "auction",
    initialState: valueInitialState,
    reducers: {
        updateAuction: auctionStateMutator,
    },
});

export default auctionSlice.reducer;
export const { updateAuction } = auctionSlice.actions;

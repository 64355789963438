/**
 * @description checks if 2 objects have the same properties
 * @param {object} object1 object 1
 * @param {object} object2 object 2
 * @returns {boolean} is equal
 */
export function hasEqualProperties(object1, object2) {
    if (Object.keys(object1).length !== Object.keys(object2).length) {
        return false;
    }

    let isEqual = true;

    Object.entries(object1).forEach(([key, value]) => {
        if (value !== object2[key]) isEqual = false;
    });

    return isEqual;
}

import React from "react";
import { twMerge } from "tailwind-merge";

/**
 * @param {object} props
 * @param {string} props.seatLabelDisplay
 * @param {string} props.checkinText
 * @param {string} props.checkoutText
 * @param {boolean} [props.hideTitle]
 * @returns {React.ReactNode}
 */
const EventInstructions = ({
    seatLabelDisplay,
    checkinText,
    checkoutText,
    hideTitle = false,
}) => (
    <div
        className={twMerge(
            "flex flex-col gap-5 rounded bg-[#f7f8f9] p-7 text-[color:var(--Theme-Text-Black,#394146)]",
            hideTitle && "mt-3",
        )}
    >
        {!hideTitle && (
            <div className="text-xl font-bold">Event Information</div>
        )}
        {!!seatLabelDisplay && (
            <section>
                <h2 className="pb-3 text-lg font-bold">Table Seating</h2>
                {seatLabelDisplay}
            </section>
        )}
        {!!checkinText && (
            <section>
                <h2 className="pb-3 text-lg font-bold">
                    Check In Instructions
                </h2>
                {checkinText}
            </section>
        )}
        {!!checkoutText && (
            <section>
                <h2 className="pb-3 text-lg font-bold">
                    Check Out Instructions
                </h2>
                {checkoutText}
            </section>
        )}
    </div>
);

export default EventInstructions;

import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { Icon } from "@qgiv/core-react";
import {
    NavigationList,
    NavigationListPosition,
    NavigationListType,
} from "../NavigationList";
import "./AccountNavigation.scss";
import { HeaderDropdown } from "../HeaderDropdown";
import { EventPage, getEventRoute } from "../../../utility";
import {
    ReceiptsModal,
    SwitchTicketModal,
    TicketDetailsModal,
} from "../../account";
import { ButtonHighlightStyle } from "../../core";
import { EventInstructionsModal } from "../../EventInstructions";
/** @typedef {import("../NavigationList/NavigationList").NavigationListItem} NavigationListItem */
/** @typedef {import("../../../types").Auction} Auction */
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Account} Account */

/** @enum {string} */
const AccountModal = {
    None: "",
    SwitchTicket: "/switchTickets",
    ViewReceipts: "/viewReceipt",
    TicketDetails: "/ticketDetails",
    EventInstructions: "/eventInstructions",
};
/** @enum {string} */
const AccountLabel = {
    MyAccount: "My Account",
    MyActivity: "My Activity",
    Cart: "Cart",
    Notifications: "Notifications",
};

/**
 * @param {object} props
 * @param {NavigationListPosition} [props.position]
 * @param {number} [props.cartCount]
 * @param {Function} [props.logoutHandler]
 * @param {Function} [props.ticketSelectAsync]
 * @param {Array} [props.tickets]
 * @param {object[]} [props.transactions]
 * @param {Auction} [props.auction]
 * @param {Account} [props.account]
 * @param {Attendee} [props.attendee]
 * @param {Function} [props.useDispatch]
 * @param {Function} [props.fetchTransactions]
 * @param {string} [props.transactionsStatus]
 * @param {Function} [props.updateTicketAsync]
 * @param {Function} [props.onLoginClick]
 * @returns {React.ReactNode}
 */
const AccountNavigation = ({
    position = NavigationListPosition.Header,
    cartCount = 0,
    logoutHandler,
    ticketSelectAsync,
    tickets = [],
    transactions = [],
    auction,
    account,
    attendee,
    useDispatch,
    fetchTransactions,
    transactionsStatus,
    updateTicketAsync,
    onLoginClick,
}) => {
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(AccountModal.None);
    const closeModalHandler = () => {
        setModal(AccountModal.None);
        if (position !== NavigationListPosition.Header) return;
        setOpen(false);
    };

    if (!account) {
        return (
            <div
                className={twMerge(
                    position === NavigationListPosition.Header
                        ? "hidden lg:block"
                        : "block",
                )}
            >
                <button
                    data-testid="loginButton"
                    type="button"
                    disabled={!auction?.id}
                    className={twMerge(
                        "flex items-center gap-1.5 font-bold hover:underline hover:opacity-60 focus:outline-none disabled:opacity-50",
                        ButtonHighlightStyle.Focus,
                        position === NavigationListPosition.Header &&
                            "flex-col justify-center text-[13px] lg:flex lg:gap-0",
                    )}
                    onClick={onLoginClick}
                >
                    <Icon
                        className="text-[24px]"
                        glyph="user-circle-regular"
                        type="FontAwesome"
                        ariaHidden
                    />
                    <span className="whitespace-nowrap">Log In</span>
                </button>
            </div>
        );
    }

    /** @type {NavigationListItem[]} */
    const mainItems = [
        {
            id: "1",
            href: getEventRoute(EventPage.Activity),
            content: (
                <div
                    className={twMerge(
                        "flex items-center justify-center gap-1.5 font-bold",
                        position === NavigationListPosition.Header &&
                            "lg:flex-col lg:gap-0 lg:text-[13px]",
                        position === NavigationListPosition.Sidebar &&
                            "text-[15px]",
                    )}
                >
                    <Icon
                        ariaHidden
                        className="mb-1 text-[20px]"
                        glyph="bookmark"
                        label={AccountLabel.MyActivity}
                        type="FontAwesome"
                    />
                    {AccountLabel.MyActivity}
                </div>
            ),
        },
        {
            id: "2",
            href: getEventRoute(EventPage.Cart),
            content: (
                <div
                    className={twMerge(
                        "flex",
                        "gap-1.5",
                        "font-bold",
                        position === NavigationListPosition.Header &&
                            "lg:flex-col lg:gap-0 lg:text-[13px]",
                        position === NavigationListPosition.Sidebar &&
                            "text-[15px]",
                    )}
                >
                    <div className="-account-cart">
                        <span className="-account-cart__count">
                            {(position === NavigationListPosition.Header &&
                                cartCount) ||
                                null}
                        </span>
                        <Icon
                            ariaHidden
                            className="text-[23px]"
                            glyph={
                                position === NavigationListPosition.Header &&
                                cartCount
                                    ? "shopping-cart-empty"
                                    : "shopping-cart"
                            }
                            label={`${AccountLabel.Cart} (${cartCount})`}
                            type="FontAwesome"
                        />
                    </div>
                    {AccountLabel.Cart}
                    {position === NavigationListPosition.Sidebar &&
                        cartCount > 0 &&
                        ` (${cartCount})`}
                </div>
            ),
        },
        // NOTE: this is a future enhancement
        // {
        //     id: "3",
        //     href: getEventRoute(`${EventPage.Activity}#notifications`),
        //     content: (
        //         <>
        //             <Icon
        //                 glyph="bell"
        //                 type="FontAwesome"
        //                 ariaHidden
        //                 label={AccountLabel.Notifications}
        //             />
        //             {AccountLabel.Notifications}
        //         </>
        //     ),
        // },
    ];
    /** @type {NavigationListItem[]} */
    const secondaryItems = [];

    if (attendee) {
        secondaryItems.push({
            id: AccountModal.TicketDetails,
            href: AccountModal.TicketDetails,
            content: <span>My Ticket Details</span>,
            onClickHandler: setModal,
        });
        if (
            attendee?.seatLabelDisplay ||
            auction?.checkinText ||
            auction?.checkoutText
        ) {
            secondaryItems.push({
                id: AccountModal.EventInstructions,
                href: AccountModal.EventInstructions,
                content: <span>Event Instructions</span>,
                onClickHandler: setModal,
            });
        }
        if (tickets.length > 1) {
            secondaryItems.push({
                id: AccountModal.SwitchTicket,
                href: AccountModal.SwitchTicket,
                content: <span>Switch Tickets</span>,
                onClickHandler: setModal,
            });
        }
        secondaryItems.push(
            // NOTE: this is a future enhancement
            // {
            //     id: "13",
            //     href: "#manageTickets",
            //     content: <span>Manage Tickets</span>,
            // },
            // {
            //     id: "14",
            //     href: "#printTickets",
            //     content: <span>Print Tickets</span>,
            // },
            {
                id: AccountModal.ViewReceipts,
                href: AccountModal.ViewReceipts,
                content: <span>View Receipt</span>,
                onClickHandler: setModal,
            },
        );
    } else {
        secondaryItems.push({
            id: "11",
            href: getEventRoute(EventPage.Register),
            content: (
                <div
                    className={twMerge(
                        position === NavigationListPosition.Header &&
                            "-padding--10",
                    )}
                >
                    Register
                </div>
            ),
        });
    }

    return (
        <>
            <div
                className={twMerge(
                    "gap-5",
                    position === NavigationListPosition.Sidebar &&
                        "flex flex-col md:gap-7",
                    position === NavigationListPosition.Header &&
                        "hidden gap-5 lg:flex xl:gap-10",
                )}
            >
                <HeaderDropdown>
                    <HeaderDropdown.MenuItem>
                        <button
                            className={twMerge(
                                "header__item flex items-center gap-1.5 font-bold hover:underline hover:opacity-60 focus:outline-none",
                                ButtonHighlightStyle.Focus,
                                position === NavigationListPosition.Header &&
                                    "flex-col justify-center text-[13px] lg:flex lg:gap-0",
                            )}
                            type="button"
                            aria-haspopup="true"
                            aria-expanded={open}
                            onClick={() => setOpen(!open)}
                        >
                            <Icon
                                ariaHidden
                                className="text-[24px]"
                                glyph="user-circle-regular"
                                label={AccountLabel.MyAccount}
                                type="FontAwesome"
                            />
                            <div className="whitespace-nowrap">
                                {AccountLabel.MyAccount}
                            </div>
                        </button>
                    </HeaderDropdown.MenuItem>
                    <HeaderDropdown.DropdownContent
                        show={open}
                        width={twMerge(
                            "w-[145px]",
                            !attendee && "right-[-5px] xl:right-[-35px]",
                        )}
                    >
                        <div
                            style={{
                                paddingLeft:
                                    position === NavigationListPosition.Sidebar
                                        ? "26px"
                                        : "0",
                            }}
                        >
                            <NavigationList
                                position={NavigationListPosition.Sidebar}
                                items={secondaryItems}
                                type={NavigationListType.Dropdown}
                            />
                            {position === NavigationListPosition.Header &&
                                logoutHandler && (
                                    <div className="-account--logout mt-2.5 pt-2.5">
                                        <button
                                            className="btn btn--error w-full p-3.5 font-bold"
                                            type="button"
                                            onClick={logoutHandler}
                                        >
                                            LOG OUT
                                        </button>
                                    </div>
                                )}
                        </div>
                    </HeaderDropdown.DropdownContent>
                </HeaderDropdown>
                {attendee && (
                    <NavigationList
                        position={position}
                        items={mainItems}
                        type={NavigationListType.Account}
                    />
                )}
            </div>
            {modal === AccountModal.TicketDetails && (
                <TicketDetailsModal
                    closeHandler={closeModalHandler}
                    attendee={attendee}
                    updateTicketAsync={updateTicketAsync}
                    includeCompany={auction?.allowCompanyRegistrations}
                    show={modal === AccountModal.TicketDetails}
                />
            )}
            {modal === AccountModal.SwitchTicket && (
                <SwitchTicketModal
                    currentBidder={attendee?.id}
                    tickets={tickets}
                    closeHandler={closeModalHandler}
                    ticketSelectAsync={ticketSelectAsync}
                    show={modal === AccountModal.SwitchTicket}
                />
            )}
            {modal === AccountModal.ViewReceipts && (
                <ReceiptsModal
                    transactions={transactions}
                    closeHandler={closeModalHandler}
                    account={account}
                    attendee={attendee}
                    useDispatch={useDispatch}
                    transactionsStatus={transactionsStatus}
                    fetchTransactions={fetchTransactions}
                    auction={auction}
                    show={modal === AccountModal.ViewReceipts}
                />
            )}
            {modal === AccountModal.EventInstructions && (
                <EventInstructionsModal
                    seatLabelDisplay={attendee?.seatLabelDisplay}
                    checkinText={auction?.checkinText}
                    checkoutText={auction?.checkoutText}
                    hideTitle
                    show={modal === AccountModal.EventInstructions}
                    closeHandler={closeModalHandler}
                />
            )}
        </>
    );
};

export default AccountNavigation;

import { Icon } from "@qgiv/core-react";
import React from "react";
import { Button } from "../../../core";
import { openPaymentIframe } from "../../../system";

/**
 * @param {object} props
 * @param {string} props.email
 * @param {Function} props.closeHandler
 * @returns {React.ReactNode}
 */
const PaymentRequired = ({ email, closeHandler }) => {
    const buttonClickHandler = () => {
        openPaymentIframe();
        closeHandler();
    };

    return (
        <div className="flex flex-col items-center gap-2">
            <Icon
                glyph="credit-card-regular"
                ariaHidden
                type="FontAwesome"
                className="mb-3 text-[50px]"
            />
            <h1 className="text-2xl font-bold">Almost there!</h1>
            <div className="text-sm font-semibold">{email}</div>
            <p className="pb-5">
                Add a payment method to participate in the event.
            </p>
            <Button onClick={buttonClickHandler}>Add Payment Method</Button>
        </div>
    );
};

export default PaymentRequired;

import React, { useState } from "react";
import { Icon } from "@qgiv/core-react";
import "./ItemWatchButton.scss";
import { twMerge } from "tailwind-merge";
import { getAxiosResponseError } from "../../../api/apiErrorHandlers";
import { addToastError, addToastSuccess, clearToasts } from "../../system";
import { ButtonHighlightStyle } from "../../core";

/** @enum {string} */
export const ItemWatchButtonBorder = {
    None: "",
    Small: "-border -small",
    Large: "-border -large",
};

/**
 * @param {object} props - The properties object.
 * @param {import("../../../types").Product} props.item
 * @param {boolean} [props.watching]
 * @param {Function} [props.onWatchClickHandler]
 * @param {string} [props.border]
 * @returns {React.JSX.Element} The rendered ItemWatchButton component.
 */
const ItemWatchButton = ({
    item,
    watching = false,
    onWatchClickHandler = null,
    border = ItemWatchButtonBorder.Large,
}) => {
    const [processing, setProcessing] = useState(false);
    if (!onWatchClickHandler) return null;
    const getIcon = () => {
        if (processing) return "loader";
        if (watching) return "bookmark-solid";
        return "bookmark";
    };
    const getIconClass = () => {
        if (processing) return ["-animation--pulse"];
        return [];
    };
    const clickHandler = async (e) => {
        try {
            clearToasts();
            setProcessing(true);
            e.preventDefault();
            await onWatchClickHandler(item.id);
            addToastSuccess(
                `${item.title} was ${!watching ? "added to" : "removed from"} watch list.`,
                2500,
            );
        } catch (err) {
            const message = getAxiosResponseError(err);
            addToastError(message.join(", "));
        } finally {
            setProcessing(false);
        }
    };

    return (
        <button
            type="button"
            className={twMerge(
                "item__watch ml-auto focus:outline-none",
                ButtonHighlightStyle.Focus,
                border !== ItemWatchButtonBorder.None && border,
            )}
            role="switch"
            aria-checked={watching}
            onClick={clickHandler}
            aria-label={watching ? "Stop Watching" : "Watch"}
            disabled={processing}
            aria-disabled={processing}
        >
            <Icon
                ariaHidden
                className={`-vertical-align--break ${getIconClass().join(" ")}`}
                glyph={getIcon()}
                type="FontAwesome"
            />
        </button>
    );
};

export default ItemWatchButton;

// @ts-check
import React, { useEffect } from "react";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import "./Sidebar.scss";

/** @enum {string} */
export const SidebarType = {
    Standard: "standard",
    Menu: "menu",
};

/**
 * @param {object} props - The properties object.
 * @param {string} props.title
 * @param {Function} props.onClose
 * @param {boolean} props.isOpen
 * @param {React.ReactNode} props.children
 * @param {SidebarType} [props.type]
 * @returns {React.JSX.Element} The rendered Sidebar component.
 */
const Sidebar = ({
    title,
    isOpen,
    children,
    onClose,
    type = SidebarType.Standard,
}) => {
    const contentClasses = cx(
        "sidebar__main",
        type === SidebarType.Menu && "main-navigation theme-only",
    );
    useEffect(() => {
        /**
         * @description On Escape Key Pressed
         * @param {object} e Key down event.
         * @returns {boolean|string} Inline function call.
         */
        function handleEscKeyClose(e) {
            return (
                document.querySelector(".sidebar--open") &&
                e.key === "Escape" &&
                onClose?.call(undefined)
            );
        }

        document.addEventListener("keydown", handleEscKeyClose.bind(undefined));
        if (isOpen) {
            // @ts-ignore
            document.querySelector(".sidebar")?.focus();
        }

        return document.removeEventListener("keydown", handleEscKeyClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <>
            {/* Register click/escape key press to close sidebar */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */}
            <dialog
                open={isOpen}
                className={cx("sidebar", isOpen && "sidebar--open", type)}
                aria-hidden={!isOpen}
                onClick={() => onClose?.call(undefined)}
            >
                {/* Stopping propagation on click to prevent close within sidebar */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                    className="sidebar__content flex"
                    onClick={(e) => e.stopPropagation()}
                    data-testid="sidebar"
                >
                    {isOpen && (
                        <>
                            <div className="sidebar__header flex items-center justify-between p-4 pt-2">
                                <span>{title}</span>
                                <button
                                    type="button"
                                    data-testid="close-sidebar-button"
                                    aria-label="Close"
                                    onClick={() => onClose?.call(undefined)}
                                >
                                    <Icon
                                        type="FontAwesome"
                                        glyph="times-regular"
                                        ariaHidden={false}
                                    />
                                </button>
                            </div>
                            <div className={contentClasses}>{children}</div>
                        </>
                    )}
                </div>
            </dialog>
        </>
    );
};

Sidebar.Body = function Body(
    /** @type {{children:import("react").ReactNode}} */ { children },
) {
    return children;
};

Sidebar.Actions = function Actions(
    /** @type {{children:import("react").ReactNode}} */ { children },
) {
    return (
        <div className="sidebar__actions flex justify-center">{children}</div>
    );
};

export default Sidebar;

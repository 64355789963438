import { useDispatch } from "react-redux";
import { updateUser } from "../../redux";
import { parseAttendee } from "../../models";
/** @typedef {import("@qgiv/auction/source/types").Account} Account */
/** @typedef {import("@qgiv/auction/source/types").Attendee} Attendee */

/**
 * @description for getting the legacy user data into Redux
 * @param {{bidder:Attendee, account:Account, csrfToken:string}} params parameters
 * @returns {void}
 */
const User = (
    /** @type {{ bidder: {id: string}, account: {id: string}, csrfToken: string}} */
    { bidder, account, csrfToken },
) => {
    const dispatch = useDispatch();
    const attendeeObject = {
        attendee: bidder ? parseAttendee(bidder) : null,
        account: account && Object.keys(account).length ? account : null,
        csrfToken,
    };

    dispatch(updateUser(attendeeObject));
    return null;
};

export default User;

/**
 * @description helper for getting errors messages from known responses
 * @param {object} data response object
 * @returns {string[]} error messages
 */
export function getApiError(data) {
    const defaultMessage = "An error occurred";
    if (!data) {
        return [defaultMessage];
    }
    if (typeof data === "string") {
        return [data];
    }
    if (Array.isArray(data)) {
        return data;
    }
    if (data.errors?.length) {
        return data.errors;
    }
    if (data.response?.errors?.length) {
        return data.response.errors;
    }
    if (data.response?.errorBidders?.errors?.length) {
        return data.response.errorBidders.errors;
    }
    if (data.responseJSON?.response?.error?.messages?.length) {
        return data.responseJSON.response.error.messages;
    }
    if (data.responseJSON?.response?.errors?.length) {
        return data.responseJSON.response.errors;
    }
    if (data.erred_bidders) {
        return Object.keys(data.erred_bidders).map(
            (id) => data.erred_bidders[id].errors,
        );
    }
    if (data instanceof Error) {
        return [data.message];
    }
    if (Array.isArray(data.responseJSON?.response)) {
        return data.responseJSON.response;
    }
    return [defaultMessage];
}

/**
 * @description gets error from Axios response object
 * @param {object} axiosResponse Axios response object
 * @returns {string[]} error messages
 */
export function getAxiosResponseError(axiosResponse) {
    if (axiosResponse?.response?.data) {
        return getApiError(axiosResponse.response.data);
    }
    return getApiError(axiosResponse);
}

import React, { useState } from "react";
import { currencyStringAccounting } from "@qgiv/core-js";
import { Button, ButtonStyle, Message, Dialog } from "../../core";
import {
    getGiftAssistAmount,
    getGiftAssistText,
    GiftAssistType,
} from "../../../utility";
import { getAxiosResponseError } from "../../../api";
import { addToastSuccess } from "../../system";
/** @typedef {import("../../../types").Auction} Auction */
/** @typedef {import("../../../types").Activity} Activity */

/**
 * @param {object} props
 * @param {Function} props.closeHandler
 * @param {Auction} props.auction
 * @param {Function} props.payBalanceAsync
 * @param {Activity[]} props.pendingItems
 * @param {Function} props.showEventInstructions
 * @param {boolean} [props.show]
 * @param {boolean} [props.open] manually open modal for testing
 * @returns {React.ReactNode}
 */
const PayBalanceModal = ({
    closeHandler,
    auction,
    payBalanceAsync,
    pendingItems,
    showEventInstructions,
    show,
    open,
}) => {
    const total = auction.totalBilled - auction.totalPaid;
    const [giftAssist, setGiftAssist] = useState(0);
    const [busy, setBusy] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const giftAssistText = getGiftAssistText(auction, total);

    const submitHandler = async () => {
        try {
            setBusy(true);
            const response = await payBalanceAsync(giftAssist);
            if (!response.success) throw response;
            closeHandler();
            if (auction.checkoutText) {
                showEventInstructions();
                return;
            }
            addToastSuccess("Balance paid", 2500);
        } catch (err) {
            const errors = getAxiosResponseError(err);
            setErrorMessage(errors.join(", "));
        } finally {
            setBusy(false);
        }
    };

    return (
        <Dialog
            title="Pay Balance"
            closeHandler={closeHandler}
            show={show}
            open={open}
        >
            <Message timeout={7000} clearHandler={setErrorMessage}>
                {errorMessage}
            </Message>
            <div className="rounded bg-[#F7F8F9] p-4">
                <strong className="text-lg">Summary</strong>
                <ul className="pt-3">
                    {pendingItems.map((i) => (
                        <li
                            key={i.id}
                            className="mb-2.5 flex justify-between gap-2.5 text-[15px] text-[#66737D]"
                        >
                            <span
                                data-testid="productTitle"
                                className="max-w-[400px]"
                            >
                                #{i.productNumber} • {i.title}
                            </span>
                            <span>{currencyStringAccounting(i.total)}</span>
                        </li>
                    ))}
                    {!!giftAssist && (
                        <li className="my-3 flex justify-between border-t border-dashed border-[#AAB6BC] pt-3 text-[15px] text-[#66737D]">
                            <span>Gift Assist</span>
                            <span>{currencyStringAccounting(giftAssist)}</span>
                        </li>
                    )}
                </ul>
                <div className="my-3 border-t border-dashed border-[#AAB6BC]" />
                <strong className="flex justify-between text-lg">
                    <span>Total Payment</span>{" "}
                    <span>
                        {currencyStringAccounting(total + giftAssist, "USD")}
                    </span>
                </strong>
            </div>
            {auction.feeCoverage !== GiftAssistType.None && (
                <label
                    htmlFor="giftAssist"
                    className="flex items-baseline gap-1 pt-5 text-[#66737D]"
                >
                    <input
                        id="giftAssist"
                        type="checkbox"
                        value={giftAssist}
                        onChange={(e) => {
                            const amount = e.target.checked
                                ? getGiftAssistAmount(auction, total)
                                : 0;
                            setGiftAssist(amount);
                        }}
                    />
                    {giftAssistText}
                </label>
            )}
            <Button
                className="mt-7 w-full"
                btnStyle={ButtonStyle.Primary}
                onClick={submitHandler}
                disabled={busy}
            >
                Pay Balance
            </Button>
        </Dialog>
    );
};

export default PayBalanceModal;

import cx from "classnames";

/** @enum {string} */
export const CardType = {
    Amex: "amex",
    Discover: "discover",
    Mastercard: "mastercard",
    Visa: "visa",
};

/**
 * @param {boolean} inline
 * @param {boolean} wrapped
 * @param {boolean} invalid
 * @returns {string}
 */
export const getInputClassName = (inline, wrapped, invalid) =>
    cx(
        "px-3 py-4",
        (inline || wrapped) && "grow",
        !inline && "w-full",
        !wrapped &&
            "rounded border border-solid border-[#AAB6BC] focus:border-black shadow-inner",
        invalid && "border-red-600",
    );

/** @enum {string} */
export const MaskType = {
    CreditCard: "CreditCard",
    CVV: "CVV",
    CVV4: "CVV4",
    Numeric: "Numeric",
    Expiration: "Expiration",
    Phone: "Phone",
    Zip: "Zip",
};

/**
 * @param {MaskType} maskType
 * @param {string} [placeholder]
 * @param {object} [options]
 * @returns {object}
 */
export const getMask = (maskType, placeholder, options = {}) => {
    const maskOptions = { ...options };
    switch (maskType) {
        case MaskType.CreditCard:
            return {
                mask: "0000 0000 0000 0000",
                placeholder: placeholder || "Credit Card",
                ...maskOptions,
            };
        case MaskType.CVV:
            return {
                mask: "000",
                placeholder: placeholder || "CVV",
                ...maskOptions,
            };
        case MaskType.CVV4:
            return {
                mask: "0000",
                placeholder: placeholder || "CVV",
                ...maskOptions,
            };
        case MaskType.Numeric:
            return {
                mask: /^\d+$/,
                placeholder: placeholder || "Number",
                ...maskOptions,
            };
        case MaskType.Expiration:
            return {
                mask: "00/0000",
                placeholder: placeholder || "Exp. Date",
                ...maskOptions,
            };
        case MaskType.Phone:
            return {
                mask: "(000) 000-0000",
                placeholder: placeholder || "Phone",
                ...maskOptions,
            };
        case MaskType.Zip:
            return {
                mask: "00000[-0000]",
                placeholder: placeholder || "Zip Code",
                ...maskOptions,
            };
        default:
            return maskOptions;
    }
};

/**
 * @param {CardType} cardType
 * @param {string} [placeholder]
 * @param {object} [options]
 * @returns {object}
 */
export const getCVVMaskByType = (cardType, placeholder, options) => {
    if (!cardType || cardType === CardType.Amex) {
        return getMask(MaskType.CVV4, placeholder, options);
    }
    return getMask(MaskType.CVV, placeholder, options);
};

/**
 * @param {string} text Expects a formatted text string, e.g. "MM/YY"
 * @returns {string}
 */
export const formatExpiration = (text) => {
    if (!text?.includes("/")) {
        return text ?? "";
    }

    const parts = text.split("/");
    const month = parts[0];
    let year = parts[1];

    if (year?.length === 2) {
        year = `20${year}`;
    }

    return `${month}/${year}`;
};

import React from "react";
import Checkbox from "../../core/Checkbox/Checkbox";
import "./ItemListFilterCheckbox.scss";

/**
 * @param {object} props - The properties object.
 * @param {string} props.label
 * @param {string} props.name
 * @param {string} props.value
 * @param {boolean} [props.isChecked]
 * @param {number} props.itemCount
 * @param {Function} props.handleToggleFilter
 * @returns {React.JSX.Element} The rendered ItemListFilterCheckbox component.
 */
const ItemListFilterCheckbox = ({
    label,
    value,
    name,
    isChecked = false,
    itemCount,
    handleToggleFilter,
}) => (
    <div className="checkbox-container">
        <Checkbox
            name={name}
            label={`${label} (${itemCount})`}
            handleChange={handleToggleFilter}
            checked={!!isChecked}
            value={value}
        >
            {label} ({itemCount})
        </Checkbox>
    </div>
);

export default ItemListFilterCheckbox;

import React from "react";
import cx from "classnames";
import { useField } from "formik";
import FormikFieldError from "./FormikFieldError";
import { getInputClassName } from "./inputHelpers";

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {boolean} props.inline
 * @param {boolean} props.wrapped
 * @param {string} props.className
 * @returns {React.ReactElement}
 */
const Input = ({
    name,
    label,
    placeholder,
    inline = false,
    wrapped = false,
    className,
}) => {
    const [field, meta] = useField({ name });
    const input = (
        <input
            id={name}
            name={name}
            type="text"
            className={getInputClassName(
                inline,
                wrapped,
                meta.touched && meta.error,
            )}
            placeholder={placeholder || label}
            aria-label={label || placeholder}
            {...field}
        />
    );
    if (wrapped) {
        return input;
    }
    return (
        <div className={cx(className && className)}>
            {input}
            <FormikFieldError name={name} />
        </div>
    );
};

export default Input;

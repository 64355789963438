import React from "react";
import * as Sentry from "@sentry/browser";
import { log } from "@qgiv/core-js";

import DefaultErrorComponent from "../ErrorComponent";

/**
 * @typedef {object} ErrorBoundaryProps
 * @property {React.ReactNode} children - The child components to be wrapped by the error boundary.
 * @property {React.ReactElement} [ErrorComponent] - The fallback UI to display in case of an error.
 * @property {string} [className] Class names to add to the error component
 */

/**
 * ErrorBoundary component to catch JavaScript errors in child components.
 * @augments {React.Component<ErrorBoundaryProps>}
 */
class ErrorBoundary extends React.Component {
    /** @param {ErrorBoundaryProps} props Component props */
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    static componentDidCatch(error) {
        log(error);

        // report error to Sentry
        const sentryIsInitialized = Sentry.getClient();
        if (sentryIsInitialized) {
            try {
                Sentry.captureException(error);
            } catch (err) {
                log(err);
            }
        }
    }

    render() {
        const { children, className, ErrorComponent } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (
                ErrorComponent || (
                    <DefaultErrorComponent className={className} />
                )
            );
        }

        return children;
    }
}

export default ErrorBoundary;

/* eslint-disable no-param-reassign */
import { parseCamelCasePropertyObject } from "@qgiv/auction/source/utility";
import { parseControl } from "../../../models";

export const cmsInitialState = {
    controls: {},
    navigation: [],
    socialSettings: {},
    layout: "",
    editMode: false,
    customPages: [],
};

/**
 * @description for adding control data
 * @param {{list: any[]}} state state object
 * @param {{payload: any}} action action object
 */
export function addControlMutator(state, action) {
    const control = parseControl(action.payload);
    state.controls[control.id] = control;
}

/**
 * @description for adding navigation data
 * @param {{list: any[]}} state state object
 * @param {{payload: any}} action action object
 */
export function navigationMutator(state, action) {
    state.navigation = action.payload.map((nav) =>
        parseCamelCasePropertyObject({ ...nav, weight: Number(nav.weight) }),
    );
}

/**
 * @description for adding navigation data
 * @param {{list: any[]}} state state object
 * @param {{payload: any}} action action object
 */
export function socialSettingsMutator(state, action) {
    state.socialSettings = action.payload;
}

/**
 * @description for adding navigation data
 * @param {{list: any[]}} state state object
 * @param {{payload: any}} action action object
 */
export function layoutMutator(state, action) {
    state.layout = action.payload;
}

/**
 * @description for setting the edit mode
 * @param {cmsInitialState} state state object
 * @param {{payload: any}} action action object
 */
export function editModeMutator(state, action) {
    state.editMode = action.payload;
}

/**
 * @description for setting the edit mode
 * @param {cmsInitialState} state state object
 * @param {{payload: any}} action action object
 */
export function customPagesMutator(state, action) {
    state.customPages = action.payload;
}

// @ts-check
import React from "react";
import cx from "classnames";
import ItemCategoryAndTags from "../ItemCategoryAndTags";
import "./ItemHeading.scss";
import ItemWatchButton from "../ItemWatchButton";
/** @typedef {import("../../../types").Product} Product */

/**
 * @param {object} props - The properties object.
 * @param {Product} props.item - The item object.
 * @param {boolean} [props.watching]
 * @param {boolean} [props.isInList]
 * @param {Function|null} [props.onWatchClick]
 * @returns {React.JSX.Element} The rendered ItemHeading component.
 */
const ItemHeading = ({
    item,
    watching = false,
    isInList = false,
    onWatchClick = null,
}) => {
    const { number, title, categoryName, tags } = item;

    return (
        <div>
            <div className="flex items-baseline justify-between gap-2.5">
                <h2
                    className={cx(
                        "font-bold text-[color:var(--Theme-Text-Black,#394146)]",
                        !isInList && "text-2xl",
                        isInList &&
                            "max-w-xs truncate text-[15px] leading-[23px] tracking-[0.12px]",
                    )}
                >
                    #{number} • {title}
                </h2>
                {!isInList && onWatchClick && (
                    <ItemWatchButton
                        item={item}
                        watching={watching}
                        onWatchClickHandler={onWatchClick}
                    />
                )}
            </div>
            <div className="hidden md:inline">
                <ItemCategoryAndTags categoryName={categoryName} tags={tags} />
            </div>
        </div>
    );
};

export default ItemHeading;

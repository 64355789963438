// @ts-check
import React, { useEffect } from "react";
import CartTable from "../CartTable";
import {
    IconContentWrapper,
    IconContentWrapperIcon,
} from "../../system/IconContentWrapper";
import { EventPage, getEventRoute } from "../../../utility";
/** @typedef {import("../../../types").Activity} Activity */

/**
 * @param {object} props
 * @param {Activity[]} props.pendingItems
 * @param {Activity[]} props.completedItems
 * @param {string} props.title
 * @returns {React.ReactNode}
 */
const Cart = ({ pendingItems, completedItems, title }) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
    const pendingSection = pendingItems.length ? (
        <CartTable
            title="Summary"
            tooltip="Billed amount the attendee has not yet paid"
            items={pendingItems}
            balanceColumn="Balance"
        />
    ) : (
        <IconContentWrapper iconGlyph={IconContentWrapperIcon.CartInfo}>
            <>
                Your cart is empty!
                <br />
                Get started by{" "}
                <a href={getEventRoute(EventPage.Items)}>
                    browsing the auction
                </a>
                .
            </>
        </IconContentWrapper>
    );
    const completedSection = completedItems.length ? (
        <CartTable
            title="Paid Items"
            tooltip="Total amount an attendee has been billed for won items, fund-a-need, and purchases"
            items={completedItems}
            balanceColumn="Billed"
        />
    ) : null;
    return (
        <>
            {pendingSection}
            {completedSection}
        </>
    );
};

export default Cart;

import React, { Fragment, useEffect, useRef, useState } from "react";
import { Combobox } from "@headlessui/react";
import cx from "classnames";
import { LoadingDots } from "@qgiv/core-react";
import Label from "./Label";
import Option from "./Option";
import Trigger from "./Trigger";
import "./ComboBox.scss";

/**
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.descText]
 * @param {boolean} [props.disabled]
 * @param {string} [props.error]
 * @param {boolean} [props.isLoadingOptions]
 * @param {boolean} [props.isRequired]
 * @param {string} props.label
 * @param {boolean} [props.multiple]
 * @param {Function} props.onChange
 * @param {Function} [props.onFocus]
 * @param {{name:string, value:string}[]} props.options
 * @param {boolean} [props.showRequiredError]
 * @param {string|string[]} [props.value]
 * @returns {React.JSX.Element}
 */
const ComboBox = (props) => {
    const {
        className = "",
        descText,
        disabled = false,
        error,
        isLoadingOptions = false,
        isRequired = false,
        label,
        multiple = false,
        onChange,
        onFocus,
        options,
        showRequiredError = false,
        value,
    } = props;
    const [query, setQuery] = useState("");
    const [id, setID] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();
    const showButtonOptions = multiple && value?.length > 0;
    const selectedOptions =
        (multiple
            ? value
                  ?.filter((val) => val.length)
                  ?.map((val) => options.find((option) => option.value === val))
            : options.find((option) => option.value === value)) || [];
    const requiredError =
        isRequired &&
        showRequiredError &&
        !(multiple ? selectedOptions.length : selectedOptions.value?.length) &&
        `${label} is required`;
    const hasError = !!error?.length || !!requiredError;
    const comboBoxClasses = cx(
        "combo-box",
        disabled && "combo-box--disabled",
        hasError && "combo-box--has-error",
        multiple && "combo-box--multiple",
        className,
    );

    const openOptions = () => {
        onFocus?.call(null);
        setIsOpen(true);
    };

    const closeOptions = () => {
        setIsOpen(false);
    };

    /**
     * @function _onChange
     * @description Combo box change handler. Converts the selected option
     *      object passed from headlessui Combobox into an value or array of
     *      values. This is passed to the onChange prop, along with the object
     *      that was (de)selected.
     * @param {object|Array} newSelectedOptions If `multiple` is falsy, this will be
     *      the selected option object. If `multiple` is truthy, this will be an
     *      array of objects for the selected options.
     */
    const _onChange = (newSelectedOptions) => {
        const newValue = multiple
            ? newSelectedOptions?.map((x) => x.value)
            : newSelectedOptions?.value;
        const selected =
            Array.isArray(newSelectedOptions) &&
            newSelectedOptions.filter((x) => !selectedOptions.includes(x))[0];
        const deselected =
            Array.isArray(newSelectedOptions) &&
            selectedOptions.filter((x) => !newSelectedOptions.includes(x))[0];

        // clear `query` value so all options show
        setQuery("");

        // call onChange and pass array of new values
        onChange(newValue, { deselected, selected });
    };

    /**
     * @function getFilteredOptions
     * @description Filter options array based on query string
     * @returns {Array} Array of option objects matching query string. If no
     *      matches are found, returns an object used to build a "no matches"
     *      message.
     */
    const getFilteredOptions = () => {
        const filteredOptions = options.filter(
            (option) =>
                // is an option group
                option.group ||
                // or is an option that contains the query string
                option.name.toLocaleLowerCase().includes(query.toLowerCase()),
        );
        // has at least one non-group option
        const hasOption = filteredOptions.some((option) => !option.group);

        // No non-group options to select. Display no matches message.
        if (!hasOption) {
            return [{ value: "", name: "No matches found", unavailable: true }];
        }

        // Show all option groups and options matching query string.
        if (query !== "") {
            return filteredOptions;
        }

        // Show all options
        return options;
    };

    useEffect(() => {
        setID(ref?.current?.id);
    }, []);

    return (
        <div className={comboBoxClasses}>
            <Combobox
                by="value"
                nullable
                onChange={_onChange}
                value={selectedOptions}
                {...{ disabled, multiple }}
            >
                {label && <Label {...props} {...{ id }} />}
                <Trigger
                    {...props}
                    closeOptions={closeOptions}
                    onChange={_onChange}
                    openOptions={openOptions}
                    options={getFilteredOptions()}
                    query={query}
                    ref={ref}
                    selectedOptions={selectedOptions}
                    setQuery={setQuery}
                    showButtonOptions={showButtonOptions}
                />
                <Combobox.Options as={Fragment} static={multiple && isOpen}>
                    {() => (
                        <div className="combo-box__pop-out">
                            <ul className="combo-box__options">
                                {isLoadingOptions ? (
                                    <LoadingDots />
                                ) : (
                                    getFilteredOptions().map((option) => (
                                        <Option
                                            key={option.value}
                                            selectedValue={value}
                                            {...option}
                                        />
                                    ))
                                )}
                            </ul>
                        </div>
                    )}
                </Combobox.Options>
            </Combobox>
            {hasError && (
                <div
                    aria-live="assertive"
                    className="combo-box__error"
                    id={`${id}-error`}
                >
                    {error || requiredError}
                </div>
            )}
            {descText && (
                <div className="combo-box__description">{descText}</div>
            )}
        </div>
    );
};

export default ComboBox;

import React from "react";
import { Icon } from "@qgiv/core-react";
import { Dialog } from "../../core";
import { getEventRoute } from "../../../utility";

/**
 * @param {object} props
 * @param {Function} props.closeHandler
 * @param {string} props.email
 * @param {boolean} [props.show]
 * @param {boolean} [props.open]
 * @returns {React.ReactNode}
 */
const NoTicketsModal = ({ closeHandler, email, show, open }) => {
    const registerUrl = getEventRoute("register");
    const itemsUrl = getEventRoute("items");

    return (
        <Dialog
            closeHandler={closeHandler}
            show={show}
            open={open}
            title={
                <span>
                    <span className="block text-[60px]">
                        <Icon
                            glyph="ticket-alt-solid"
                            type="FontAwesome"
                            ariaHidden
                        />
                    </span>
                    You don&apos;t have a ticket,{" "}
                    <span data-testid="email-address">{email}</span>
                </span>
            }
            className="rounded-[5px]"
        >
            <div className="px-[10px]">
                <p className="text-center text-[15px] text-[#66737d]">
                    Register now to participate in this event or continue
                    previewing items.
                </p>

                <div className="justify-content-center flex gap-[15px]">
                    <a
                        href={registerUrl}
                        className="btn btn--primary -background-color--theme-primary mt-[30px] w-full p-[15px] text-center text-xl"
                    >
                        Register Now
                    </a>
                    <a
                        href={itemsUrl}
                        className="btn secondary -color--theme-primary mt-[30px] w-full p-[15px] text-center text-xl font-normal"
                    >
                        View Items
                    </a>
                </div>
            </div>
        </Dialog>
    );
};

export default NoTicketsModal;

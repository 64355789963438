// @ts-check
import React from "react";
import "./ItemStatus.scss";

/**
 * @param {object} props - The properties object.
 * @param {string} props.color
 * @param {string} props.text
 * @returns {React.ReactNode} The rendered ItemStatus component.
 */
const ItemStatus = ({ color, text }) => {
    if (!text) return null;
    return (
        <span
            className={`item-status whitespace-nowrap rounded-[30px] px-4 py-1 text-[13px] font-bold ${color}`}
        >
            {text}
        </span>
    );
};

export default ItemStatus;

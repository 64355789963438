import { configureStore } from "@reduxjs/toolkit";
import controlReducer from "./cmsSlice";
import timerReducer from "./timerSlice";
import auctionReducer from "./auctionSlice";
import productsReducer from "./productsSlice";
import categoriesReducer from "./categoriesSlice";
import purchasesReducer from "./purchasesSlice";
import donationReducer from "./donationsSlice";
import auctionFeedReducer from "./auctionFeedSlice";
import userReducer from "./userSlice";
import favoriteReducer from "./favoritesSlice";
import loginRegistrationModalReducer from "./loginRegistrationModalSlice";
import transactionReducer from "./slices/transactionsSlice";
import globalModalReducer from "./modalSlice";

const enableDevtools =
    window.location.hostname.search(/localhost|([^main]\.dev\.qgiv\.com)/gi) >
    -1;

const devTools = enableDevtools
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : false;

const storeConfigObject = {
    devTools,
    reducer: {
        cms: controlReducer,
        timer: timerReducer,
        auction: auctionReducer,
        products: productsReducer,
        categories: categoriesReducer,
        purchases: purchasesReducer,
        donations: donationReducer,
        feed: auctionFeedReducer,
        user: userReducer,
        favorites: favoriteReducer,
        loginRegistrationModal: loginRegistrationModalReducer,
        transactions: transactionReducer,
        globalModal: globalModalReducer,
    },
};

const store = configureStore({
    ...storeConfigObject,
});

export default store;

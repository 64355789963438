/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
    categoryStateMutator,
    listInitialState,
} from "./stateMutators/stateMutators";

const categoriesSlice = createSlice({
    name: "categories",
    initialState: listInitialState,
    reducers: {
        updateCategories: categoryStateMutator,
    },
});

export default categoriesSlice.reducer;
export const { updateCategories } = categoriesSlice.actions;

/** @typedef {import("../../../types/Navigation").Navigation} Navigation */
/** @typedef {import("../../../types/CustomPage").CustomPage} CustomPage */

/** @enum {string} */
export const SitePage = {
    Home: "%home%",
    Donate: "%donate%",
    Register: "%register%",
    Sonsors: "%sponsors%",
    ProductList: "%product_list%",
    Custom: "%custom%",
};

const syncItemWeight = (item, weight) => ({ ...item, weight });
/**
 * @description gets new array of items after updating a single item
 * @param {Navigation[]} list item array
 * @param {number} index old index
 * @param {Navigation} newItem new item
 * @returns {Navigation[]} updated array
 */
export const updateItemInList = (list, index, newItem) =>
    list
        .toSpliced(index, 1)
        .toSpliced(newItem.weight, 0, newItem)
        .map(syncItemWeight);

/**
 * @description gets new array of items after adding a single item
 * @param {Navigation[]} list item array
 * @returns {Navigation[]} updated array
 */
export const addItemToList = (list) => [
    ...list,
    {
        id: `new_${Date.now()}`,
        title: "",
        weight: list.length,
        isOpen: true,
    },
];

/**
 * @description gets new array of items after removing a single item
 * @param {Navigation[]} list item array
 * @param {number} index old index
 * @returns {Navigation[]} updated array
 */
export const removeItemFromList = (list, index) =>
    list.toSpliced(index, 1).map(syncItemWeight);

/**
 * @description get the id for a custom page
 * @param {CustomPage[]} customPages custom pages
 * @param {string} target target id or alias
 * @returns {string} id
 */
export const getCustomPageId = (customPages, target) =>
    customPages.find(({ alias }) => alias === target)?.id || target;

/**
 * @description gets the url for an internal page
 * @param {Navigation} item navigation item
 * @param {CustomPage[]} customPages custom pages
 * @returns {string} url
 */
export const getInternalUrl = (item, customPages) => {
    if (item.url === SitePage.Custom) {
        return getCustomPageId(customPages, item.targetid);
    }
    return item.url;
};
/**
 * @description gets the url value
 * @param {string} value link to value
 * @returns {string} url
 */
export const getNavigationUrl = (value) => {
    if (!Object.values(SitePage).includes(value)) return SitePage.Custom;
    return value;
};

/**
 * @description gets the target id value
 * @param {string} value link to value
 * @returns {string} target id
 */
export const getNavigationTargetId = (value) => {
    if (Object.values(SitePage).includes(value)) return null;
    return value;
};

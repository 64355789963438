/* eslint-disable no-param-reassign */
import {
    parseCategory,
    parseProduct,
    getActiveCategories,
    getActiveProducts,
    parseActivity,
    getActiveActivity,
    parseAuction,
} from "../../models";
/** @typedef {import("../../models/Product/Product").Product} Product */
/** @typedef {import("../../models/Category/Category").Category} Category */
/** @typedef {import("../../models/Activity/Activity").Activity} Activity */

const timeInitialState = {
    time: Date.now(),
};
/**
 * @description for updating time
 * @param {{time:number}} state state object with time
 */
function timeStateMutator(state) {
    state.time = Date.now();
}

const valueInitialState = {
    value: null,
};
/**
 * @description for updating state stored as value
 * @param {{value:any}} state state with value
 * @param {{payload:any}} action action with object
 */
function valueStateMutator(state, action) {
    state.value = action.payload;
}

const listInitialState = {
    list: [],
};

/**
 * @description adds items to unique list
 * @param {{list:any[]}} state state with list array
 * @param {{payload:any[]}} action action with list array
 */
function uniqueListMutator(state, action) {
    const list = new Set(state.list);
    action.payload.forEach((item) => {
        list.add(item);
    });

    state.list = [...list.values()];
}

/**
 * @description adds items to unique list
 * @param {{list:any[]}} state state with list array
 * @param {{payload:any[]}} action action with list array
 */
function mergeFavoriteMutator(state, action) {
    const list = new Set(state?.value?.map((i) => i.product) ?? []);
    action.payload.forEach((item) => {
        list.add(item.product);
    });

    state.value = [...list.values()].map((i) => ({ product: i }));
}

/**
 * @description for updating a list of parsed type
 * @param {any[]} existingItems existing items
 * @param {any[]} newItems new items
 * @param {Function} parser type parser function
 * @returns {any[]} products
 */
function typeMapMerge(existingItems, newItems, parser) {
    // create a copy as a Map
    const itemMap = new Map(existingItems.map((item) => [item.id, item]));
    // loop through payload
    newItems.forEach((item) => {
        // update/add item
        itemMap.set(item.id, parser ? parser(item) : item);
    });
    // convert back to array
    return [...itemMap.values()];
}

/**
 * @description for updating a list of type array, items must have id property
 * @param {{list:any[]}} state state with list array
 * @param {{payload:any[]}} action action with list array
 */
function listStateMapMerge(state, action) {
    state.list = typeMapMerge(state.list, action.payload);
}

/**
 * @description handles auction update
 * @param {{value: Product[]}} state state object
 * @param {{payload: any[]}} action action object
 */
export function auctionStateMutator(state, action) {
    state.value = parseAuction(action.payload);
}

/**
 * @description handles products update
 * @param {{list: Product[]}} state state object
 * @param {{payload: any[]}} action action object
 */
function productStateMutator(state, action) {
    const products = typeMapMerge(state.list, action.payload, parseProduct);
    // only include valid products for the UI
    state.list = getActiveProducts(products);
}

/**
 * @description handles categories update
 * @param {{list: Category[]}} state state object
 * @param {{payload: any[]}} action action object
 */
function categoryStateMutator(state, action) {
    const categories = typeMapMerge(state.list, action.payload, parseCategory);
    // only include valid categories for the UI
    state.list = getActiveCategories(categories);
}

/**
 * @description handles activity updates
 * @param {{list: Activity[]}} state state object
 * @param {{payload: any[]}} action action object
 */
function activityStateMutator(state, action) {
    const activity = typeMapMerge(state.list, action.payload, parseActivity);
    // filter by active items
    state.list = getActiveActivity(activity);
}

export {
    timeInitialState,
    timeStateMutator,
    listInitialState,
    listStateMapMerge,
    valueInitialState,
    valueStateMutator,
    productStateMutator,
    categoryStateMutator,
    activityStateMutator,
    uniqueListMutator,
    mergeFavoriteMutator,
};

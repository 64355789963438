import React, { Fragment } from "react";
import { Combobox } from "@headlessui/react";
import { QuestionTooltip } from "@qgiv/core-react";

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.label
 * @param {string|Node} [props.labelTooltip]
 * @param {boolean} [props.isRequired]
 * @returns {React.JSX.Element}
 */
const Label = ({ id, isRequired, label, labelTooltip }) => (
    <Combobox.Label as={Fragment}>
        {() => (
            <label className="combo-box__label" htmlFor={id}>
                {label}
                {isRequired && (
                    <strong className="combo-box__required">*</strong>
                )}
                {labelTooltip && (
                    <QuestionTooltip className="ml-1" content={labelTooltip} />
                )}
            </label>
        )}
    </Combobox.Label>
);

export default Label;

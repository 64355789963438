import * as Yup from "yup";

/**
 * @description gets initial value
 * @param {import("../../../types").Attendee} attendee attendee
 * @returns {{company:string, firstName:string, lastName:string, phoneNumber:string, notificationTypes:number[]}} field values
 */
export const getInitialValues = ({
    firstName,
    lastName,
    company,
    phone: phoneNumber,
    notificationTypes,
}) => ({
    company,
    firstName,
    lastName,
    phoneNumber,
    notificationTypes,
});

export const getValidationSchema = () =>
    Yup.object({
        firstName: Yup.string(),
        lastName: Yup.string(),
        phoneNumber: Yup.string().matches(
            /^\(?\d{3}\)? ?\d{3}[ -]?\d{4}$/,
            "Invalid (XXX) XXX-XXXX",
        ),
    });

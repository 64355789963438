import { EventEmitter } from "events";
import { MessageType } from "../../core";
import { ConfirmationModalQueryStringKey } from "../../../utility";

export const ToastEmitterType = {
    Add: "toast:add",
    Clear: "toast:clear",
};

let id = 0;
const toastEmitter = new EventEmitter();
export const addToast = (message, type, timeout = 0) => {
    toastEmitter.emit(ToastEmitterType.Add, {
        id: (id += 1),
        message,
        type,
        timeout,
    });
};
export const addToastSuccess = (message, timeout) =>
    addToast(message, MessageType.Success, timeout);
export const addToastError = (message, timeout) =>
    addToast(message, MessageType.Error, timeout);
export const clearToasts = () => {
    toastEmitter.emit(ToastEmitterType.Clear);
};
export const AddToastSuccessParam = "addToastSuccess";
/**
 * @param {string} message
 * @param {boolean} isConfirmation
 */
export const reloadPageWithToastSuccess = (message, isConfirmation = false) => {
    const queryString = isConfirmation
        ? `${ConfirmationModalQueryStringKey}=true`
        : `${AddToastSuccessParam}=${message}`;
    const url = `${window.top.window.location.pathname}?${queryString}`;
    window.top.location.href = url;
};
export default toastEmitter;

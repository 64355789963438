import { LogInstance, constants } from "@qgiv/core-js";
import { clearSession, getFromSession, saveToSession } from "./session";
import {
    BroadcastMessageType,
    RETRY_COUNT,
    postBroadcastChannelMessage,
    getSSEHost,
    waitAsync,
    isIgnoredMessageType,
} from "./feedBaseUtilities";

const {
    ENUMS: { StoreProductType },
} = constants;

// #region helper data
const NAMESPACE = "QGIV.AuctionFeedRepository";
const { debug, error, log } = LogInstance(`${NAMESPACE}:React`);
// #endregion

// #region configuration data
/** @enum {string} */
export const AuctionBroadcastType = {
    ...BroadcastMessageType,
    Products: "product",
    Categories: "categories",
    Checkout: "checkout",
    Purchases: "purchases",
    Donations: "donations",
    Balance: "balance",
    Bidders: "bidders",
    Event: "event",
    Favorites: "favorites",
    FavoritesUpdate: "favorites-update",
};
/** @enum {string} */
const ParameterKey = {
    ItemDescriptionAsHTML: "returnItemDescAsHTML",
    FormId: "auctionId",
    BidderId: "bidderId",
    AccountId: "accountId",
    APIToken: "api_token",
};
/** @type {Map<string, string>} */
const parameters = new Map();
/** @type {BroadcastChannel} */
let broadcastChannel;
/** @type {number} */
let channelDisconnectTimeout = 0;
/** @type {EventSource} */
let eventSource;
let errorCount = 0;
/** @type {AuctionBroadcastType} */
let status = AuctionBroadcastType.Connecting;
// #endregion

// #region raw data cache
const cache = {
    auction: null,
    isFromSession: true,
};
/** @type {Map<string, any>} */
export const products = new Map();
/** @type {Map<string, any>} */
export const categories = new Map();
/** @type {Map<string, any>} */
export const purchases = new Map();
/** @type {Map<string, any>} */
export const donations = new Map();
export const favorites = new Set();
// #endregion

// #region helper methods
/**
/**
 * @description for converting the paramters into a querystring
 * @returns {string} querystring
 */
const createQuerystring = () =>
    [...parameters.entries()]
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

/**
 * @description checks if cache is loaded
 * @returns {boolean} has cache
 */
export const hasCache = () => !!cache.auction;

/**
 * @description clears all cache
 */
function clearAllCache() {
    cache.auction = null;
    cache.isFromSession = true;
    products.clear();
    categories.clear();
    purchases.clear();
    donations.clear();
    favorites.clear();
}

/**
 * @description get auction from cache, create a copy with updated arrays
 * @returns {any} auction object
 */
export const getAuction = () => ({
    ...cache.auction,
    products: [...products.values()],
    categories: [...categories.values()],
    myPurchases: [...purchases.values()],
    myDonations: [...donations.values()],
    favorites: [...favorites.values()],
});

/**
 * @description for updating a product
 * @param {any} product product
 */
export function updateProduct(product) {
    products.set(product.id, product);
    postBroadcastChannelMessage(
        broadcastChannel,
        AuctionBroadcastType.Products,
        [product],
    );
}

/**
 * @description for manually updating the favorites as SSE doesn't support removals
 * @param {{product:string}[]} newFavorites
 */
export function updateFavorites(newFavorites) {
    debug(`updateFavorites ${newFavorites.length}`);
    favorites.clear();
    newFavorites.forEach((fav) => favorites.add(fav));
    postBroadcastChannelMessage(
        broadcastChannel,
        AuctionBroadcastType.FavoritesUpdate,
        newFavorites,
    );
}

/**
 * @description gets the connection status
 * @returns {AuctionBroadcastType} status
 */
export const getStatus = () => status;

/**
 * @description for parsing the auction object from the response
 * @param {Event} e event
 * @returns {any} auction object
 */
function parseAuctionData({ data }) {
    const { response } = typeof data === "string" ? JSON.parse(data) : data;
    if (response.errors) {
        error(response.errors);
        postBroadcastChannelMessage(
            broadcastChannel,
            AuctionBroadcastType.Unstable,
        );
        return null;
    }
    return response.auction;
}

/**
 * @description checks if updated category is now in check out
 * @param {any} updated updated category
 * @returns {boolean} is newly checked out
 */
function isNewCheckout(updated) {
    if (!categories.has(updated.id)) return false;
    return (
        updated.checkout === "1" && categories.get(updated.id).checkout === "0"
    );
}

/**
 * @description checks if is attendee feed
 * @returns {boolean} is attendee feed
 */
export const getAttendeeId = () => parameters.get(ParameterKey.BidderId);

/**
 * @description checks if is attendee feed
 * @returns {boolean} is attendee feed
 */
export const isAttendeeFeed = () => parameters.has(ParameterKey.BidderId);

/**
 * @description checks if there is a balance change
 * @param {any} data auction feed data
 * @returns {boolean} has balance change
 */
const hasBalanceChange = (data) =>
    !cache.auction ||
    cache.auction.totalBilled !== data.totalBilled ||
    cache.auction.totalSpent !== data.totalSpent ||
    cache.auction.totalPaid !== data.totalPaid ||
    cache.auction.totalPending !== data.totalPending;

/**
 * @description get bid history for product
 * @param {any} product product object
 * @returns {Array} history
 */
function getProductBidHistory({ bidHistory }) {
    if (!bidHistory?.length) return [];
    return bidHistory.filter((history) => history.status === "1");
}

// #endregion

// #region core methods
/**
 * @description updates cache
 * @param {any} auction auction object
 * @param {boolean} fromSessionStorage is from session storage
 * @returns {Map<string, any>} updated items
 */
function cacheAuctionData(auction, fromSessionStorage = false) {
    const actions = new Map();
    // NOTE: we'll send a ping to prevent other listeners from falling into the lost channel logic
    if (
        !auction ||
        (!auction.products?.length &&
            !auction.categories?.length &&
            !auction.myPurchases?.length &&
            !auction.myDonations?.length)
    ) {
        return actions.set(AuctionBroadcastType.Ping, true);
    }
    if (!cache.auction || cache.isFromSession) {
        log(auction);
        /* istanbul ignore next */
        if (cache.isFromSession) {
            clearAllCache();
        }
        cache.auction = auction;
        cache.isFromSession = fromSessionStorage;

        if (auction.products?.length) {
            auction.products.forEach((product) => {
                products.set(product.id, product);
            });
        }
        if (auction.categories?.length) {
            auction.categories.forEach((category) => {
                categories.set(category.id, category);
            });
        }
        if (auction.myPurchases?.length) {
            auction.myPurchases.forEach((purchase) => {
                purchases.set(purchase.id, purchase);
            });
        }
        if (auction.myDonations?.length) {
            auction.myDonations.forEach((donation) => {
                donations.set(donation.id, donation);
            });
        }
        if (auction.favorites?.length) {
            auction.favorites.forEach((favorite) => {
                favorites.add(favorite);
            });
        }
        return actions.set(AuctionBroadcastType.Initialize, auction);
    }
    if (
        cache.auction.status !== auction.status ||
        cache.auction.auctionStatus !== auction.auctionStatus ||
        cache.auction.startDateIso !== auction.startDateIso ||
        cache.auction.endDateIso !== auction.endDateIso
    ) {
        cache.auction = auction;
        actions.set(AuctionBroadcastType.Event, auction);
    }
    if (isAttendeeFeed() && hasBalanceChange(auction)) {
        log(auction);
        cache.auction = auction;
        actions.set(AuctionBroadcastType.Balance, auction);
    }
    if (auction.products?.length) {
        log(auction.products);
        const newProductBidders = [];
        auction.products.forEach((product) => {
            // check for new bidder on product
            const existingProduct = products.has(product.id)
                ? products.get(product.id)
                : null;
            products.set(product.id, product);

            if (
                !existingProduct ||
                existingProduct.categoryType !== StoreProductType.BID.toString()
            ) {
                return;
            }
            const bidHistory = getProductBidHistory(product);
            if (!bidHistory.length) return;

            // check for new activity
            const existingHistory = getProductBidHistory(existingProduct);
            if (existingHistory.length >= bidHistory.length) return;

            const existingBidderIds = existingHistory.map(
                ({ bidderId }) => bidderId,
            );
            bidHistory.forEach(({ bidderId }) => {
                if (existingBidderIds.includes(bidderId)) return;
                newProductBidders.push({ itemId: product.id, bidderId });
            });
        });
        actions.set(AuctionBroadcastType.Products, auction.products);
        if (newProductBidders.length) {
            actions.set(AuctionBroadcastType.Bidders, newProductBidders);
        }
    }
    if (auction.categories?.length) {
        log(auction.categories);
        const checkoutIds = [];
        auction.categories.forEach((category) => {
            const updated = category;
            if (isNewCheckout(updated)) {
                checkoutIds.push(category.id);
            }
        });
        if (checkoutIds.length) {
            actions.set(AuctionBroadcastType.Checkout, checkoutIds);
        }
        actions.set(AuctionBroadcastType.Categories, auction.categories);
    }
    if (isAttendeeFeed()) {
        if (auction.myPurchases?.length) {
            log(auction.myPurchases);
            auction.myPurchases.forEach((purchase) => {
                purchases.set(purchase.id, purchase);
            });
            actions.set(AuctionBroadcastType.Purchases, auction.myPurchases);
        }
        if (auction.myDonations?.length) {
            log(auction.myDonations);
            auction.myDonations.forEach((donation) => {
                donations.set(donation.id, donation);
            });
            actions.set(AuctionBroadcastType.Donations, auction.myDonations);
        }
        if (auction.favorites?.length) {
            log(auction.favorites);
            auction.favorites.forEach((favorite) => {
                favorites.add(favorite);
            });
            actions.set(AuctionBroadcastType.Favorites, auction.favorites);
        }
    }
    return actions;
}

/**
 * @description handles event source message
 * @param {Event} e event
 * @returns {void}
 */
function eventSourceMessageHandler(e) {
    if (errorCount) errorCount = 0;
    const auction = parseAuctionData(e);
    const updated = cacheAuctionData(auction);
    // share update over channel
    updated.forEach((value, key) => {
        postBroadcastChannelMessage(broadcastChannel, key, value);
    });
}

/**
 * @description for starting event source
 * @returns {void}
 */
function connectToEventSource() {
    clearTimeout(channelDisconnectTimeout);
    /* istanbul ignore next */
    if (eventSource || !broadcastChannel) return;
    debug("connecting to feed...");
    postBroadcastChannelMessage(
        broadcastChannel,
        AuctionBroadcastType.Connecting,
        broadcastChannel.name,
    );
    const auctionUrl = `${getSSEHost()}/views/auction/auctions_sse.php?${createQuerystring()}`;
    eventSource = new EventSource(auctionUrl);
    eventSource.addEventListener("message", eventSourceMessageHandler);
    eventSource.addEventListener("open", () => {
        debug(`connected to feed ${broadcastChannel.name}`);
        status = AuctionBroadcastType.Connect;
    });
    eventSource.addEventListener("error", async (e) => {
        error("error connecting to feed", e);
        eventSource.close();
        eventSource = null;
        if (errorCount >= RETRY_COUNT) {
            debug("retry count exceeded");
            status = AuctionBroadcastType.Failure;
            postBroadcastChannelMessage(broadcastChannel, status);
            return;
        }
        errorCount += 1;
        status = AuctionBroadcastType.Unstable;
        postBroadcastChannelMessage(broadcastChannel, status);

        await waitAsync(1000);
        debug(`connection retry ${errorCount}`);
        connectToEventSource();
    });
}

/**
 * @description handles connection to event source on timeout
 * @param {number} timeout timeout in milliseconds
 */
function lostChannelHandler(timeout) {
    clearTimeout(channelDisconnectTimeout);
    channelDisconnectTimeout = setTimeout(connectToEventSource, timeout);
}

/**
 * @description handles broadcast messages
 * @param {MessageEvent} message post message
 */
function broadcastChannelMessageHandler(
    /** @type {{data:import("./feedBaseUtilities.js").PostMessageResponse}} */ {
        data: { type, payload },
    },
) {
    // handle new connection requests
    if (
        type === AuctionBroadcastType.Connect &&
        broadcastChannel.name === payload &&
        eventSource &&
        cache.auction
    ) {
        debug(`responding to connect ${payload}`);
        postBroadcastChannelMessage(
            broadcastChannel,
            AuctionBroadcastType.Initialize,
            getAuction(),
        );
        return;
    }
    // NOTE: prevent reconnect loops between channels
    if (isIgnoredMessageType(type)) {
        // clearTimeout(channelDisconnectTimeout);
        return;
    }
    // NOTE: we'll ignore messages from page loads if we already have feed data
    if (!cache.isFromSession && type === AuctionBroadcastType.Restore) {
        return;
    }
    // NOTE: the only external messages will be connect, if we receive a message another channel has taken over as parent and we can close the feed
    const stayConnectedTypes = [
        AuctionBroadcastType.Restore,
        AuctionBroadcastType.FavoritesUpdate,
    ];
    if (eventSource && !stayConnectedTypes.includes(type)) {
        eventSource.close();
        eventSource = null;
        errorCount = 0;
        debug("disconnected feed, changed to broadcast channel from parent");
    }
    // update cache from channel
    if (
        (cache.isFromSession && type === AuctionBroadcastType.Initialize) ||
        (!cache.auction && type === AuctionBroadcastType.Restore)
    ) {
        cacheAuctionData(payload, type === AuctionBroadcastType.Restore);
    }
    if (type === AuctionBroadcastType.Balance && hasBalanceChange(payload)) {
        cacheAuctionData(payload);
    }
    if (type === AuctionBroadcastType.Categories) {
        cacheAuctionData({ categories: payload });
    }
    if (type === AuctionBroadcastType.Products) {
        cacheAuctionData({ products: payload });
    }
    if (type === AuctionBroadcastType.Purchases) {
        cacheAuctionData({ myPurchases: payload });
    }
    if (type === AuctionBroadcastType.Donations) {
        cacheAuctionData({ myDonations: payload });
    }
    if (type === AuctionBroadcastType.Favorites) {
        cacheAuctionData({ favorites: payload });
    }
    if (type === AuctionBroadcastType.FavoritesUpdate) {
        debug(`received favorites update ${payload.length}`);
        favorites.clear();
        if (payload.length) {
            payload.forEach((fav) => favorites.add(fav));
        }
    }
    // NOTE: we use a random timeout for disconnect to help prevent all listeners from creating feeds at the same time
    const disconnectTimeout = Math.floor(Math.random() * 4000);
    if (type === AuctionBroadcastType.Disconnect) {
        debug(
            `parent was disconnected, will open feed in ${disconnectTimeout}`,
        );
        cache.isFromSession = true;
    }
    lostChannelHandler(
        type === AuctionBroadcastType.Disconnect ? disconnectTimeout : 10000,
    );
}

/**
 * @param {string} key
 * @returns {void}
 */
function loadFromSessionStorage(key) {
    const data = getFromSession(key);
    if (!data) return;
    cacheAuctionData(data, true);
    postBroadcastChannelMessage(
        broadcastChannel,
        AuctionBroadcastType.Restore,
        data,
    );
}

/**
 * @description for starting broadcast channel
 * @param {string} formId form id
 * @returns {string} name
 */
function connectToBroadcastChannel(formId) {
    if (broadcastChannel) {
        broadcastChannel.close();
    }
    const name = [NAMESPACE, formId];
    if (parameters.has(ParameterKey.BidderId)) {
        name.push(parameters.get(ParameterKey.BidderId));
    }
    broadcastChannel = new BroadcastChannel(name.join(":"));
    debug(`repository started for ${broadcastChannel.name}`);
    postBroadcastChannelMessage(
        broadcastChannel,
        AuctionBroadcastType.Connect,
        broadcastChannel.name,
    );
    broadcastChannel.addEventListener(
        "message",
        broadcastChannelMessageHandler,
    );
    loadFromSessionStorage(broadcastChannel.name);
    // perform a short wait as we would expect an existing channel to respond fast
    lostChannelHandler(50);
    return broadcastChannel.name;
}
// #endregion

// #region main repository export methods
/**
 * @description for connecting to feed
 * @param {string} formId form id
 * @param {string} bidderId bidder id
 * @param {string} accountId account id
 * @param {string} apiToken api token
 * @returns {string} name
 */
export function connectAuctionFeedRepository(
    formId,
    bidderId,
    accountId,
    apiToken,
) {
    if (!formId) throw new Error("A form id is required");
    errorCount = 0;
    parameters.clear();
    parameters.set(ParameterKey.ItemDescriptionAsHTML, 1);
    parameters.set(ParameterKey.FormId, formId);
    if (bidderId && accountId && apiToken) {
        parameters.set(ParameterKey.BidderId, bidderId);
        parameters.set(ParameterKey.AccountId, accountId);
        parameters.set(ParameterKey.APIToken, apiToken);
    }
    return connectToBroadcastChannel(formId);
}

/**
 * @description for disconnecting from feed
 */
export function disconnectAuctionFeedRepository() {
    clearTimeout(channelDisconnectTimeout);
    debug("disconnectAuctionFeedRepository");
    status = AuctionBroadcastType.Disconnect;
    if (eventSource) {
        eventSource.close();
        eventSource = null;
        postBroadcastChannelMessage(broadcastChannel, status);
    }
    if (broadcastChannel) {
        clearSession();
        saveToSession(broadcastChannel.name, getAuction());
        broadcastChannel.close();
        broadcastChannel = null;
    }
    clearAllCache();
}
// #endregion

// @ts-check
import React from "react";
import { twMerge } from "tailwind-merge";
import { ImageLoader } from "@qgiv/core-react";

/** @enum {string} */
export const ImageZoomSize = {
    Small: "md:max-h-[100px]",
    Medium: "md:max-h-[200px]",
    Large: "md:max-h-[300px]",
};

/**
 * @description handles mouse events
 * @param {React.MouseEvent} e event
 */
function onMouseMoveHandler(e) {
    // @ts-ignore
    e.target.style.transformOrigin = `${e.nativeEvent.offsetX}px ${e.nativeEvent.offsetY}px`;
}

/**
 * @param {object} props
 * @param {string} props.src
 * @param {string} props.alt
 * @param {ImageZoomSize} [props.size]
 * @returns {React.ReactNode}
 */
const ImageZoom = ({ src, alt, size = ImageZoomSize.Large }) => (
    <div
        className={twMerge(
            "relative flex aspect-video items-center justify-center overflow-hidden",
            size,
        )}
        data-testid="image-zoom-container"
    >
        <ImageLoader
            loadedClassName="hover:scale-[2] ease-linear duration-150 h-full z-[1] cursor-zoom-in"
            src={src}
            alt={alt}
            onMouseOver={onMouseMoveHandler}
            onMouseMove={onMouseMoveHandler}
            onFocus={(e) => {
                e.target.style.transformOrigin = `center`;
            }}
        />
    </div>
);

export default ImageZoom;

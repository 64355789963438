import {
    ccTypeAccepted,
    constants,
    cvvLength,
    validateCCNum,
    validateRouting,
} from "@qgiv/core-js";
import * as Yup from "yup";

const {
    ENUMS: { PaymentType },
} = constants;
/** @enum {string} */
export const formFieldLabels = {
    cardNumber: "Card Number",
    expiration: "Exp. Date",
    cvv: "CVV",
    routingNumber: "Routing Number",
    accountNumber: "Account Number",
    firstName: "First Name",
    lastName: "Last Name",
    address: "Address",
    city: "City",
    state: "State",
    zip: "Zip Code",
    country: "Country",
};
/** @enum {string} */
const message = {
    Required: "Required",
    Number: "Must be a number",
};
/** @enum {string} */
const Format = {
    Card: "0000 0000 0000 0000",
    Expiration: "MM/YYYY",
    Routing: "000000000",
    Zip: "00000 or 00000-0000",
};
const getRequiredMessage = (format) => {
    const builder = [message.Required];
    if (format) builder.push(format);
    return builder.join(" ");
};
const getInvalidMessage = (name, format) => `Invalid ${name}, ${format}`;

export const getInitialValues = (paymentType) => ({
    ...Object.keys(formFieldLabels).reduce(
        (obj, name) => ({ ...obj, [name]: "" }),
        {},
    ),
    paymentType,
    country: "US",
});

// #region field schemas
const getCardNumberSchema = (VisaAmexDisc) => {
    const base = Yup.string();

    return base.when(["paymentType"], {
        is: (paymentType) => paymentType === PaymentType.CREDITCARD,
        then: base
            .required(getRequiredMessage(Format.Card))
            .test("validCardType", "Card not accepted", (value) =>
                ccTypeAccepted(VisaAmexDisc, value),
            )
            .test(
                "validCardNumber",
                getInvalidMessage(formFieldLabels.cardNumber, Format.Card),
                (value) => validateCCNum(value),
            ),
        otherwise: base,
    });
};

const getExpirationSchema = () => {
    const base = Yup.string();

    return base.when(["paymentType"], {
        is: (paymentType) => paymentType === PaymentType.CREDITCARD,
        then: base
            .required(getRequiredMessage(Format.Expiration))
            .test(
                "validExpirationPattern",
                getInvalidMessage(
                    formFieldLabels.expiration,
                    Format.Expiration,
                ),
                (value) => {
                    const match = value?.match(
                        /^(?<month>1[0-2]|0?[1-9])\/(?<year>\d{4})$/,
                    );
                    if (!match?.groups) return false;
                    return true;
                },
            )
            .test("validExpirationDate", "Expired", (value) => {
                const today = new Date();
                const currentYear = today.getFullYear();
                const match = value?.match(
                    /^(?<month>1[0-2]|0?[1-9])\/(?<year>\d{4})$/,
                );
                if (!match?.groups) return false;
                const {
                    groups: { month, year },
                } = match;
                const yearInt = parseInt(year, 10);
                if (yearInt < currentYear) {
                    return false;
                }
                if (
                    yearInt === currentYear &&
                    parseInt(month, 10) < today.getMonth() + 1
                ) {
                    return false;
                }
                return true;
            }),
        otherwise: base,
    });
};

const getCvvSchema = () => {
    const base = Yup.number().typeError(message.Number);
    let max = 3;
    const setMax = (v) => {
        max = v;
        return max;
    };
    const getMaxFormat = () => "0".repeat(max);

    return base.when(["paymentType"], {
        is: (paymentType) => paymentType === PaymentType.CREDITCARD,
        then: base.required(message.Required).test({
            name: "validCvvLength",
            message: () =>
                getInvalidMessage(formFieldLabels.cvv, getMaxFormat()),
            test: (value, context) => {
                const { cardNumber } = context.from[0].value;
                const newMax = setMax(cardNumber ? cvvLength(cardNumber) : 4);
                return value?.toString().length === newMax;
            },
        }),
        otherwise: base,
    });
};

const getAccountSchema = () => {
    const base = Yup.string().typeError(message.Number);

    return base.when(["paymentType"], {
        is: (paymentType) => paymentType === PaymentType.ECHECK,
        then: base.required(message.Required),
        otherwise: base,
    });
};

const getRoutingSchema = () => {
    const base = Yup.string();

    return base.when(["paymentType"], {
        is: (paymentType) => paymentType === PaymentType.ECHECK,
        then: base
            .required(getRequiredMessage(Format.Routing))
            .test(
                "validRoutingNumber",
                getInvalidMessage(
                    formFieldLabels.routingNumber,
                    Format.Routing,
                ),
                (value) => value && validateRouting(value),
            ),
        otherwise: base,
    });
};

const getStringSchema = (label) =>
    Yup.string().required(getRequiredMessage(label));

const getZipCodeSchema = () => {
    const base = Yup.string();

    return base.when(["country"], {
        is: (value) => value === "US",
        then: base
            .required(getRequiredMessage(Format.Zip))
            .test(
                "validateZipCode",
                getInvalidMessage(formFieldLabels.zip, Format.Zip),
                (value) => /^\d{5}(-\d{4})?$/.test(value),
            ),
        otherwise: base.required(message.Required),
    });
};
// #endregion

export const getValidationSchema = (VisaAmexDisc) =>
    Yup.object({
        cardNumber: getCardNumberSchema(VisaAmexDisc),
        expiration: getExpirationSchema(),
        cvv: getCvvSchema(),
        accountNumber: getAccountSchema(),
        routingNumber: getRoutingSchema(),
        firstName: getStringSchema(),
        lastName: getStringSchema(),
        address: getStringSchema(),
        apartment: Yup.string(),
        city: getStringSchema(),
        state: getStringSchema(),
        zip: getZipCodeSchema(),
        country: getStringSchema(),
    });

export { Yup };

/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
    valueInitialState,
    valueStateMutator,
} from "./stateMutators/stateMutators";

const userSlice = createSlice({
    name: "attendee",
    initialState: valueInitialState,
    reducers: {
        updateUser: valueStateMutator,
    },
});

export default userSlice.reducer;
export const { updateUser } = userSlice.actions;

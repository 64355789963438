// @ts-check

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalModalType, NoTicketsModal } from "@qgiv/auction";
import { selectModalType, resetGlobalModal } from "../../redux/modalSlice";
import { getUser } from "../../redux";

const ConnectedGlobalModal = () => {
    const dispatch = useDispatch();
    const { account } = useSelector(getUser) ?? {};
    const modalType = useSelector(selectModalType);

    switch (modalType) {
        case GlobalModalType.NoTickets:
            return (
                <NoTicketsModal
                    email={account?.username}
                    show
                    closeHandler={() => dispatch(resetGlobalModal())}
                />
            );
        default:
            return null;
    }
};
export default ConnectedGlobalModal;

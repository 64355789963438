// @ts-check

/** @enum {string} */
export const GiviStore = {
    Apple: "apple",
    Google: "google",
};

/** @type {Map<GiviStore, string>} */
const storeUrls = new Map([
    [
        GiviStore.Apple,
        `https://itunes.apple.com/us/app/givi-mobile-donations/id1289812506?ls=1&mt=8`,
    ],
    [
        GiviStore.Google,
        `https://play.google.com/store/apps/details?id=com.qgiv.givi&hl=en`,
    ],
]);
/** @type {Map<GiviStore, string>} */
const storeLegalText = new Map([
    [GiviStore.Apple, "Apple and the Apple Logo are trademarks of Apple Inc."],
    [
        GiviStore.Google,
        "Google Play and the Google Play logo are trademarks of Google LLC.",
    ],
]);
/** @type {Map<GiviStore, string>} */
const storeBadgeAltText = new Map([
    [GiviStore.Apple, "Download Givi on the App Store"],
    [GiviStore.Google, "Download Givi on Google Play"],
]);

/**
 * @description gets app store url
 * @param {GiviStore} store store identifier
 * @returns {string|undefined} store url
 */
export function getGiviStoreUrl(store) {
    if (!storeUrls.has(store)) throw new Error(`Unknown store ${store}`);
    return storeUrls.get(store);
}

/**
 * @description for getting store legal text
 * @param {GiviStore} store store
 * @returns {string|undefined} legal text
 */
export function getStoreLegalText(store) {
    if (!storeLegalText.has(store)) throw new Error(`Unknown store ${store}`);
    return storeLegalText.get(store);
}

/**
 * @description for getting store badge alt text
 * @param {GiviStore} store store
 * @returns {string|undefined} alt text
 */
export function getStoreBadgeAltText(store) {
    if (!storeBadgeAltText.has(store)) {
        throw new Error(`Unknown store ${store}`);
    }
    return storeBadgeAltText.get(store);
}

// @ts-check
import React, { useState } from "react";
import { Icon } from "@qgiv/core-react";
import Button, { ButtonStyle } from "../../core/Button/Button";
import ItemListFilter from "../ItemListFilter";
import ItemListItem from "../ItemListItem";
import {
    getSortOptions,
    sortItems,
    filterItems,
    getFiltersCount,
    getFilterOptions,
    defaultItemSort,
} from "../../../utility";
import {
    IconContentWrapper,
    IconContentWrapperIcon,
} from "../../system/IconContentWrapper";
import "./ItemList.scss";

/** @typedef {import("../../../types").Product} Product */
/**
 * @typedef {object} Options
 * @property {string} title - The title of the item list.
 * @property {string} columns - The number of columns to display.
 * @property {string} defaultSort - The default sorting order.
 * @property {string|boolean} displayFilterSection - Whether to display the filter section.
 */

const sortOptions = getSortOptions();
const defaultOptions = {
    title: "",
    columns: "3",
    defaultSort: defaultItemSort,
    displayFilterSection: "1",
};

/**
 * @param {object} props
 * @param {Options} props.options
 * @param {Product[]} props.items
 * @param {import("../../../types").Attendee} props.attendee
 * @param {number} props.time
 * @param {string} props.baseUrl
 * @param {string[]} props.attendeeActivity
 * @param {string[]} props.favorites
 * @param {Function} props.onWatchHandler
 * @returns {React.JSX.Element}
 */
const ItemList = ({
    options,
    items,
    attendee,
    time,
    baseUrl,
    attendeeActivity = [],
    favorites = [],
    onWatchHandler,
}) => {
    const cmsOptions = {
        ...defaultOptions,
        ...options,
    };
    const { title, columns, defaultSort, displayFilterSection } = cmsOptions;
    const filterOptions = getFilterOptions(items);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [filters, setFilters] = useState([]);
    const [sort, setSort] = useState(defaultSort);

    /**
     * Handle filters/sort changes
     * @param {any} updatedFilters Updated filters object.
     * @param {any} updatedSort Updated sort object.
     */
    function handleFiltersSortChange(updatedFilters, updatedSort) {
        setFilters(updatedFilters);
        setSort(updatedSort);
    }

    /**
     * Handle opening/closing the sidebar.
     * @param {boolean} toggle Open state.
     */
    function handleToggleSidebar(toggle) {
        setIsSidebarOpen(toggle);
    }

    const filteredItems = filterItems(items, filters);
    const sortedItems = sortItems(filteredItems, sort);
    const filtersCount = getFiltersCount(filters);
    const hasFilter =
        displayFilterSection === "1" || displayFilterSection === true;

    const noItems = (
        <IconContentWrapper iconGlyph={IconContentWrapperIcon.Timer}>
            <span>
                Items for this category are not available yet. Please check back
                later.
            </span>
        </IconContentWrapper>
    );
    const itemList = sortedItems.length ? (
        <div data-columns={columns} className={`items__list c-${columns} mt-6`}>
            {sortedItems.map((item) => (
                <ItemListItem
                    key={item.id}
                    item={item}
                    attendee={attendee}
                    hasActivityForBidder={attendeeActivity?.includes(item.id)}
                    time={time}
                    baseUrl={baseUrl}
                    watching={favorites.includes(item.id)}
                    onWatchHandler={onWatchHandler}
                />
            ))}
        </div>
    ) : (
        <IconContentWrapper iconGlyph={IconContentWrapperIcon.FaceFrown}>
            <span>
                No results found! Please change the filter settings and try
                again.
            </span>
        </IconContentWrapper>
    );

    return (
        <div className="items rounded-lg border border-none border-[#cfd7da] p-0 sm:border-solid sm:p-7">
            <div className="items__header flex items-center justify-between">
                {title ? (
                    <h1
                        data-testid="itemListTitle"
                        className="text-2xl font-bold text-[color:var(--Theme-Text-Black,#394146)]"
                    >
                        {title}
                    </h1>
                ) : (
                    <div />
                )}
                {hasFilter && (
                    <Button
                        btnStyle={ButtonStyle.Secondary}
                        className="btn items__filter flex items-center"
                        size="small"
                        onClick={() => handleToggleSidebar(true)}
                    >
                        <Icon type="Custom" glyph="qgiv-filter" ariaHidden />
                        <span className="ml-2">Filter ({filtersCount})</span>
                    </Button>
                )}
            </div>
            {!items.length ? noItems : itemList}

            {hasFilter && (
                <ItemListFilter
                    sortOptions={sortOptions}
                    defaultItemSort={sort}
                    filterOptions={filterOptions}
                    isOpen={isSidebarOpen}
                    onChanged={(updatedValues) =>
                        handleFiltersSortChange(
                            updatedValues.filters,
                            updatedValues.sort,
                        )
                    }
                    onClose={() => handleToggleSidebar(false)}
                />
            )}
        </div>
    );
};

export default ItemList;

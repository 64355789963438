import React from "react";
import { twMerge } from "tailwind-merge";
import { isCurrentRoute } from "../../../utility";
import { ButtonHighlightStyle } from "../../core";

/**
 * @typedef NavigationListItem
 * @property {string} id id
 * @property {Node} content content
 * @property {string} href link url
 * @property {boolean} isTargetBlank is target _blank
 * @property {string} title title
 * @property {Function} onClickHandler on click handler
 */

/** @enum {string} */
export const NavigationListPosition = {
    Header: "top-nav",
    Sidebar: "side-nav",
};
/** @enum {string} */
export const NavigationListType = {
    Social: "social",
    Event: "event",
    Account: "account",
    Dropdown: "dropdown",
};
/**
 * @param {object} props
 * @param {NavigationListItem[]} props.items
 * @param {NavigationListPosition} [props.position]
 * @param {NavigationListType} [props.type]
 * @param {string} [props.className]
 * @returns {React.ReactNode}
 */
const NavigationList = ({
    items = [],
    position = NavigationListPosition.Header,
    type = "",
    className = "",
}) => {
    if (!items.length) return null;

    return (
        <nav
            className={twMerge(
                type !== NavigationListType.Dropdown &&
                    position === NavigationListPosition.Header &&
                    "hidden lg:flex",
            )}
        >
            <ul
                className={twMerge(
                    "flex",
                    type === NavigationListType.Social && "gap-7 xl:gap-5",
                    type === NavigationListType.Event && "gap-x-7 gap-y-7",
                    type === NavigationListType.Event &&
                        position === NavigationListPosition.Header &&
                        "xl:gap-x-10 xl:gap-y-10",
                    type === NavigationListType.Account && "gap-5 md:gap-7",
                    type === NavigationListType.Account &&
                        position === NavigationListPosition.Header &&
                        "xl:gap-10",
                    type === NavigationListType.Dropdown && "mt-3 gap-7",
                    type === NavigationListType.Dropdown &&
                        position === NavigationListPosition.Header &&
                        "lg:mt-0 xl:gap-7",
                    position === NavigationListPosition.Sidebar && "flex-col",
                    className,
                )}
            >
                {items.map(
                    ({
                        id,
                        href,
                        content,
                        isTargetBlank,
                        title,
                        onClickHandler,
                    }) => {
                        const isCurrentPage = isCurrentRoute(href);
                        const disabled = isCurrentPage && !isTargetBlank;
                        const onClick = (e) => {
                            if (disabled) {
                                e.preventDefault();
                                return;
                            }
                            if (!onClickHandler) return;
                            e.preventDefault();
                            onClickHandler(href);
                        };

                        return (
                            <li key={id}>
                                <a
                                    className={twMerge(
                                        "header__item flex h-full items-center gap-1.5 whitespace-nowrap rounded text-[15px] focus:outline-none",
                                        ButtonHighlightStyle.Focus,
                                        type === NavigationListType.Event &&
                                            position ===
                                                NavigationListPosition.Header &&
                                            "font-bold lg:text-lg",
                                        position ===
                                            NavigationListPosition.Header &&
                                            "flex-col justify-center",
                                        disabled && "opacity-50",
                                        !disabled &&
                                            "hover:underline hover:opacity-80",
                                    )}
                                    href={href}
                                    target={isTargetBlank ? "_blank" : "_self"}
                                    rel="noreferrer"
                                    title={title}
                                    aria-label={title}
                                    aria-current={isCurrentPage ? "page" : null}
                                    disabled={disabled}
                                    role={onClickHandler ? "button" : "link"}
                                    onClick={onClick}
                                >
                                    {content}
                                </a>
                            </li>
                        );
                    },
                )}
            </ul>
        </nav>
    );
};

export default NavigationList;

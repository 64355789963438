import React from "react";

const FieldError = ({ children }) => {
    if (!children) return null;
    return (
        <span role="alert" className="text-red-600 pb-3 text-sm">
            {children}
        </span>
    );
};

export default FieldError;

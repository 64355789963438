import { createSlice } from "@reduxjs/toolkit";
import { RegistrationModalType, RegistrationModalView } from "@qgiv/auction";
import { removeRegistrationQueryString } from "../utilities/queryStringHelpers";

const initialState = {
    formId: "",
    view: RegistrationModalView.CHECK_EMAIL,
    isModalOpen: false,
    email: "",
    name: "",
    phone: "",
    isLoading: false,
    eventId: "",
    eventName: "",
    eventUrl: "",
    type: RegistrationModalType.PRE_REGISTRATION,
    accountId: null,
    resetKey: null,
};

export const slice = createSlice({
    name: "loginRegistrationModal",
    initialState,
    reducers: {
        resetModal: (state, action) => {
            removeRegistrationQueryString();

            return {
                ...initialState,
                view: !action.payload
                    ? RegistrationModalView.CHECK_EMAIL
                    : RegistrationModalView.LOGIN_NO_PASSWORD,
                eventId: state.eventId,
                eventName: state.eventName,
                eventUrl: state.eventUrl,
            };
        },
        setModalView: (state, action) => ({
            ...state,
            view: action.payload,
        }),
        setIsModalOpen: (state, action) => ({
            ...state,
            isModalOpen: action.payload,
        }),
        setEmail: (state, action) => ({
            ...state,
            email: action.payload,
        }),
        setName: (state, action) => ({
            ...state,
            name: action.payload,
        }),
        setPhone: (state, action) => ({
            ...state,
            phone: action.payload,
        }),
        setIsLoading: (state, action) => ({
            ...state,
            isLoading: action.payload,
        }),
        setEventId: (state, action) => ({
            ...state,
            eventId: action.payload,
        }),
        setEventName: (state, action) => ({
            ...state,
            eventName: action.payload,
        }),
        setEventUrl: (state, action) => ({
            ...state,
            eventUrl: action.payload,
        }),
        setModalType: (state, action) => ({
            ...state,
            type: action.payload,
        }),
        setAccountId: (state, action) => ({
            ...state,
            accountId: action.payload,
        }),
        setResetKey: (state, action) => ({
            ...state,
            resetKey: action.payload,
        }),
        setFormId: (state, action) => ({
            ...state,
            formId: action.payload,
        }),
    },
});

// actions
export const {
    resetModal,
    setModalView,
    setIsModalOpen,
    setEmail,
    setName,
    setPhone,
    setIsLoading,
    setEventId,
    setEventName,
    setEventUrl,
    setAccountId,
    setModalType,
    setResetKey,
    setFormId,
} = slice.actions;

export default slice.reducer;

// selectors
export const selectRegistrationModalView = (state) =>
    state.loginRegistrationModal.view;
export const selectRegistrationModalType = (state) =>
    state.loginRegistrationModal.type;
export const selectResetKey = (state) => state.loginRegistrationModal.resetKey;
export const selectEmail = (state) => state.loginRegistrationModal.email;
export const selectName = (state) => state.loginRegistrationModal.name;
export const selectPhone = (state) => state.loginRegistrationModal.phone;
export const selectFormId = (state) => state.loginRegistrationModal.formId;
export const selectEventId = (state) => state.loginRegistrationModal.eventId;
export const selectEventUrl = (state) => state.loginRegistrationModal.eventUrl;
export const selectEventName = (state) =>
    state.loginRegistrationModal.eventName;
export const selectIsLoading = (state) =>
    state.loginRegistrationModal.isLoading;
export const selectIsModalOpen = (state) =>
    state.loginRegistrationModal.isModalOpen;
export const selectAccountId = (state) =>
    state.loginRegistrationModal.accountId;

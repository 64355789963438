import {
    ApiStatus,
    createApiRepository,
    System,
    Version,
} from "@qgiv/auction/source/api";
import { LogInstance } from "@qgiv/core-js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const { log, error } = LogInstance("transactionSlice");

export const getTransactions = (state) => state.transactions.transactions;
export const getTransactionsStatus = (state) => state.transactions.status;
export const getTransactionsError = (state) => state.transactions.error;

export const fetchTransactions = createAsyncThunk(
    "transactions/fetchTransactions",
    async ({ auctionId, bidderId, accountId, api_token }) => {
        const postData = {
            bidderId,
            accountId,
            api_token,
        };
        const repo = createApiRepository(
            System.AUCTIONS,
            Version.V3,
            auctionId,
        );
        const response = await repo.postAsync(
            `attendees/${bidderId}/transactions`,
            postData,
        );
        if (!response?.transactions?.length) {
            error(response);
            throw new Error("No transactions found");
        }
        log(response);
        return response.transactions;
    },
    {
        condition(_, thunkApi) {
            const transactionsStatus = getTransactionsStatus(
                thunkApi.getState(),
            );
            return transactionsStatus === ApiStatus.Idle;
        },
    },
);
const extraReducers = (builder) => {
    builder
        .addCase(fetchTransactions.pending, (state) => {
            state.status = ApiStatus.Pending;
        })
        .addCase(fetchTransactions.fulfilled, (state, action) => {
            state.status = ApiStatus.Succeeded;
            state.transactions = action.payload;
        })
        .addCase(fetchTransactions.rejected, (state, action) => {
            state.status = ApiStatus.Failed;
            state.error = action.error.message ?? "An error occurred";
        });
};

const initialState = {
    transactions: [],
    status: ApiStatus.Idle,
    error: null,
};

const transactionsSlice = createSlice({
    name: "transactions",
    initialState,
    extraReducers,
});

export default transactionsSlice.reducer;
export const { setTransactions } = transactionsSlice.actions;

import React, { useEffect, useRef } from "react";
import { Icon } from "@qgiv/core-react";
import { EventInstructions } from "../../../EventInstructions";
import { EventPage, getEventRoute } from "../../../../utility";

/**
 * @param {object} props
 * @param {string} props.email
 * @param {string} props.name
 * @param {string} props.seatLabelDisplay
 * @param {string} props.checkinText
 * @returns {React.ReactNode}
 */
const LoginConfirmation = ({
    email = "",
    name = "",
    seatLabelDisplay,
    checkinText,
}) => {
    const firstInput = useRef(null);

    useEffect(() => {
        firstInput?.current?.focus();
    }, [firstInput]);

    return (
        <div className="flex flex-col">
            <div className="mb-[15px] text-center">
                <Icon
                    className="-color--theme-primary text-6xl"
                    glyph="qgiv-confirmation"
                    type="Custom"
                />
            </div>
            <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                You’re all set{name ? ` ${name}` : ""}!
            </div>
            <div className="m-[5px] text-center text-[13px] font-bold text-[color:var(--Background-Tertiary-Gray,#66737D)]">
                {email}
            </div>
            <div className="mt-[5px] text-center text-[15px] tracking-[0.12px] text-[#66737D]">
                Take a look at some of the items available and start placing
                bids. Good luck!
            </div>
            {(!!seatLabelDisplay || !!checkinText) && (
                <>
                    <div className="my-7 border-t border-solid border-[#e5e5e5]" />
                    <div className="h-64 overflow-auto">
                        <EventInstructions
                            seatLabelDisplay={seatLabelDisplay}
                            checkinText={checkinText}
                        />
                    </div>
                </>
            )}

            <a
                ref={firstInput}
                data-testid="view-items-btn"
                className="btn btn--primary mt-[30px] p-[12px] text-center text-lg"
                href={getEventRoute(EventPage.Items)}
            >
                View Auction Items
            </a>
        </div>
    );
};

export default LoginConfirmation;

// @ts-check
import React, { useEffect, useState } from "react";
import { Icon } from "@qgiv/core-react";
import Button, { ButtonSize, ButtonStyle } from "../../core/Button/Button";
import "./Total.scss";
import { PayBalanceModal, ReceiptsModal } from "../../account";
import { ApiStatus } from "../../../api";
import { Tooltip } from "../../core";
import { EventInstructionsModal } from "../../EventInstructions";
/** @typedef {import("../../../types").Auction} Auction */
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Account} Account */
/** @typedef {import("../../../types").Activity} Activity */
/**
 * @typedef Transaction
 * @property {string} id id
 * @property {string} status status
 * @property {string} amount amount
 * @property {string} paymentType payment Type
 * @property {string} name name
 * @property {string} lastFour last Four
 * @property {string} receiptId receipt Id
 * @property {string} bidder bidder
 * @property {string} ccLabel cc Label
 */

const AccountModal = {
    None: "",
    PayBalance: "/payBalance",
    ViewReceipts: "/viewReceipt",
    AddPayment: "/addPayment",
    SelectPayment: "/selectPayment",
    EventInstructions: "/eventInstructions",
};

const Total = (
    /** @type {{auction:Auction, transactions:Transaction[], account:Account, attendee:Attendee, useDispatch:Function, fetchTransactions:Function, transactionsStatus:string, payBalanceAsync:Function, pendingItems:Activity[]}} */
    {
        auction,
        transactions = [],
        account,
        attendee,
        useDispatch,
        fetchTransactions,
        transactionsStatus,
        payBalanceAsync,
        pendingItems,
    },
) => {
    const [modal, setModal] = useState(AccountModal.None);
    const closeHandler = () => setModal(AccountModal.None);
    const dispatch = useDispatch();
    const total = auction.totalBilled - auction.totalPaid;

    useEffect(() => {
        if (
            modal === AccountModal.ViewReceipts &&
            transactionsStatus === ApiStatus.Idle
        ) {
            dispatch(
                fetchTransactions({
                    auctionId: auction.id,
                    bidderId: attendee.id,
                    accountId: account.id,
                    api_token: account.token,
                }),
            );
        }
    }, [
        transactionsStatus,
        dispatch,
        auction,
        attendee,
        account,
        fetchTransactions,
        modal,
    ]);

    const headers = [
        {
            title: "Winning",
            tooltip:
                "Amount for items currently winning and will be billed when checkout is opened",
            position: "left-[-50px] right-[-160px] top-[-100px]",
        },
        {
            title: "Balance",
            tooltip: "Billed amount the attendee has not paid yet",
            position: "left-[-130px] right-[-80px] top-[-80px]",
        },
        {
            title: "Billed",
            tooltip: "Billed amount the attendee has already paid",
            position: "left-[-200px] right-[-10px] top-[-80px]",
        },
    ];

    return (
        <>
            <div className="total w-full">
                <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                    Total
                </div>
                <div className="total__table mt-2.5">
                    <table className="w-full">
                        <thead className="">
                            <tr>
                                {headers.map((header) => (
                                    <th key={header.title}>
                                        <Tooltip
                                            text={header.tooltip}
                                            className={header.position}
                                        >
                                            <div className="flex flex-row flex-nowrap items-center gap-1.5 text-center text-[15px] font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                                                {header.title}
                                                <Icon
                                                    glyph="circle-exclamation-regular"
                                                    type="FontAwesome"
                                                    ariaHidden
                                                />
                                            </div>
                                        </Tooltip>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${auction.totalPending.toFixed(0)}</td>
                                <td>${total.toFixed(0)}</td>
                                <td>${auction.totalBilled.toFixed(0)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-5">
                    <Button
                        btnStyle={ButtonStyle.Primary}
                        className="w-full py-[15px]"
                        onClick={() => setModal(AccountModal.PayBalance)}
                        disabled={!total}
                    >
                        Pay Balance
                    </Button>
                </div>
                <div className="mt-5 text-center text-sm">
                    <Button
                        btnStyle={ButtonStyle.None}
                        size={ButtonSize.Small}
                        className="ignore-typography"
                        onClick={() => setModal(AccountModal.ViewReceipts)}
                        data-testid="viewReceiptsButton"
                    >
                        <span className="ui-text-primary-color font-bold">
                            View Receipts
                        </span>
                    </Button>
                </div>
            </div>
            {modal === AccountModal.ViewReceipts && (
                <ReceiptsModal
                    auction={auction}
                    transactions={transactions}
                    account={account}
                    attendee={attendee}
                    useDispatch={useDispatch}
                    transactionsStatus={transactionsStatus}
                    fetchTransactions={fetchTransactions}
                    closeHandler={closeHandler}
                    show={modal === AccountModal.ViewReceipts}
                />
            )}
            <PayBalanceModal
                closeHandler={closeHandler}
                auction={auction}
                payBalanceAsync={payBalanceAsync}
                pendingItems={pendingItems}
                show={modal === AccountModal.PayBalance}
                showEventInstructions={() =>
                    setModal(AccountModal.EventInstructions)
                }
            />
            {!!auction?.checkoutText && (
                <EventInstructionsModal
                    closeHandler={closeHandler}
                    show={modal === AccountModal.EventInstructions}
                    checkoutText={auction.checkoutText}
                />
            )}
        </>
    );
};

export default Total;

import parse from "../Base";
/** @typedef {import("@qgiv/auction/source/types").AttributeOption} AttributeOption */

const intProperties = new Set(["maxQty", "qtySold"]);
const floatProperties = new Set(["originalAmount"]);
const booleanProperties = new Set(["unlimited"]);

/**
 * @description for parsing an attribute
 * @param {{name:string, options:Array}} attribute attribute
 * @returns {{name:string, options:AttributeOption[]}} attribute
 */
export function parseAttribute({ name, options }) {
    return {
        name,
        options: options.map((o) =>
            parse(o, intProperties, floatProperties, booleanProperties),
        ),
    };
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ForgotPassword } from "@qgiv/auction";
import {
    selectEmail,
    setIsLoading,
} from "../../../redux/loginRegistrationModalSlice";
import { resetPassword } from "../../../api/registration";

const ConnectedForgotPassword = () => {
    const email = useSelector(selectEmail);
    const [isSent, setIsSent] = useState(false);

    const dispatch = useDispatch();

    /**
     * Handle on submit click.
     * @param {string} emailValue The email
     */
    async function handleSubmitPasswordClickAsync(emailValue) {
        if (!emailValue) {
            return;
        }

        dispatch(setIsLoading(true));

        try {
            await resetPassword(emailValue);
        } catch (err) {
            // not surfacing error.
            return;
        } finally {
            setIsSent(true);
            dispatch(setIsLoading(false));
        }
    }

    return (
        <ForgotPassword
            email={email}
            isSent={isSent}
            handleSubmitPasswordClick={async (emailValue) =>
                handleSubmitPasswordClickAsync(emailValue)
            }
        />
    );
};

export default ConnectedForgotPassword;

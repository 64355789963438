import { LogInstance, constants } from "@qgiv/core-js";
import store, { getIsWatching, updateProducts } from "../redux";
import { createApiRepository, System, Version } from "./base";
import { updateFavorites } from "../utilities";
/** @typedef {import("../models/Product/Product").Product} Product */
/** @typedef {import("./base").ApiRepository} ApiRepository */

const {
    ENUMS: { StoreProductType },
} = constants;

const { log } = LogInstance("QGIV.api.item");
/** @type {Map<string, ApiRepository>} */
const auctionRepos = new Map();

/**
 * @description gets the auction api repo on demand
 * @param {Version} version repo version
 * @returns {ApiRepository} repository
 */
function getAuctionRepo(version) {
    if (!auctionRepos.has(version)) {
        auctionRepos.set(
            version,
            createApiRepository(System.AUCTIONS, version),
        );
    }
    return auctionRepos.get(version);
}

/**
 * @description gets watching state of item
 * @param {string} itemId item id
 * @returns {boolean} is watching
 */
function isWatching(itemId) {
    const state = store.getState();
    return getIsWatching(itemId)(state);
}

/**
 * @description handler for following an item
 * @param {string} itemId item id
 * @returns {Promise} await
 */
export async function followHandler(itemId) {
    const action = isWatching(itemId) ? "unfollow" : "follow";
    const route = `items/${itemId}/${action}`;
    const { favorites } = await getAuctionRepo(Version.V3).postAsync(route);
    log(favorites);
    // NOTE: we force a feed update here as SSE doesn't support removals, and this will update redux
    updateFavorites(favorites);
}

/**
 * @description for placing a bid
 * @param {string} id item id
 * @param {number} amount bid amount
 * @returns {Promise} promise
 */
async function bidAsync(id, amount) {
    const route = `items/${id}/bid`;
    const data = {
        bidAmount: amount,
    };
    const response = await getAuctionRepo(Version.V3).postAsync(route, data);
    log(response.product);
    store.dispatch(updateProducts([response.product]));
    return { ...response, success: true };
}

/**
 * @description for purchasing an item
 * @param {string} id item id
 * @param {number} qty purchase qty
 * @param {string} attribute item attribute
 * @returns {Promise} promise
 */
async function buyAsync(id, qty, attribute) {
    const route = `items/${id}/buy`;
    const data = {
        qty,
    };
    if (attribute) {
        data.option = attribute;
    }
    const response = await getAuctionRepo(Version.V3).postAsync(route, data);
    log(response.item);
    return { ...response, success: true };
}

/**
 * @description for purchasing a fund-a-need item
 * @param {string} id item id
 * @param {number} amount donate amount
 * @returns {Promise} promise
 */
async function donateAsync(id, amount) {
    const route = `items/${id}/donate`;
    const data = {
        amount,
        qty: 1,
    };
    const response = await getAuctionRepo(Version.V3).postAsync(route, data);
    log(response.item);
    return { ...response, success: true };
}

/**
 * @description performs item action, such as bid, buy, or donate
 * @param {string} id item id
 * @param {number} amount action amount
 * @param {string} attribute item attribute
 * @returns {Promise} promise
 */
export function actionHandlerAsync(id, amount, attribute) {
    /** @type {{products: {list:import("@qgiv/auction/source/types/Product").Product[]}}} */
    const {
        products: { list },
    } = store.getState();
    const { categoryType, cost } = list.find((item) => item.id === id);
    switch (categoryType) {
        case StoreProductType.BID:
            return bidAsync(id, amount);
        case StoreProductType.BUY:
            return buyAsync(id, amount, attribute);
        case StoreProductType.DONATE:
            return donateAsync(id, cost ? cost * amount : amount);
        default:
            throw new Error(
                `Invalid item type: ${categoryType}, expected: ${Object.values(StoreProductType)}`,
            );
    }
}

import React from "react";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import Button, { ButtonSize, ButtonStyle } from "../core/Button/Button";

/** @enum {number} */
const IncrementModifier = {
    Add: 1,
    Subtract: -1,
};

/**
 * @param {object} props - The properties object.
 * @param {number} props.increment
 * @param {number} props.amount
 * @param {Function} props.onUpdateAmount
 * @param {number} [props.min]
 * @param {number} [props.max]
 * @param {boolean} [props.isCurrency]
 * @param {boolean} [props.roundButtons]
 * @param {string} [props.className]
 * @returns {React.JSX.Element} The rendered Incrementer component.
 */
const Incrementer = ({
    increment,
    amount,
    onUpdateAmount,
    min = 1,
    max = Infinity,
    isCurrency = true,
    roundButtons = false,
    className,
}) => {
    const getErrorMessage = (value) => {
        if (!value || value < min) return `Must be ${min} or more`;
        if (value > max) return `Must be ${max} or less`;
        if (value % increment && value !== max)
            return `Must be increment of $${increment}`;
        return "";
    };
    const amountError = getErrorMessage(amount);

    const incrementAmount = (modifier) => {
        let value = amount + increment * modifier;
        if (value > max) value = max;
        if (value < min) value = min;
        const remainder = value % increment;
        if (remainder) {
            if (modifier === IncrementModifier.Add) {
                value -= remainder;
            } else {
                value += increment - remainder;
            }
        }
        onUpdateAmount(value);
    };
    const changeHandler = ({ target }) => {
        if (!target?.value) {
            onUpdateAmount("");
            return;
        }
        const nextValue = parseInt(
            target.value.split(".")[0].replace("$", ""),
            10,
        );
        if (Number.isNaN(nextValue)) {
            onUpdateAmount("");
            return;
        }

        onUpdateAmount(nextValue);
    };
    const keyDownHandler = (e) => {
        switch (e.key) {
            case "ArrowUp":
                incrementAmount(IncrementModifier.Add);
                e.preventDefault();
                break;
            case "ArrowDown":
                incrementAmount(IncrementModifier.Subtract);
                e.preventDefault();
                break;
            default:
        }
    };

    const getIncrementerButton = (modifier) => {
        const title =
            modifier === IncrementModifier.Add ? "Increase" : "Decrease";
        const iconStyle = roundButtons ? "circle-solid" : "regular";
        const icon = `${modifier === IncrementModifier.Add ? "plus" : "minus"}-${iconStyle}`;
        const isDisabled =
            modifier === IncrementModifier.Add ? amount >= max : amount <= min;

        return (
            <Button
                btnStyle={ButtonStyle.Icon}
                size={ButtonSize.xxLarge}
                onClick={() => incrementAmount(modifier)}
                title={title}
                aria-label={title}
                className="flex items-center"
                disabled={isDisabled}
            >
                <Icon type="FontAwesome" glyph={icon} ariaHidden />
            </Button>
        );
    };

    return (
        <div className={cx(className && className)}>
            <div className="incrementer flex justify-center gap-2">
                {getIncrementerButton(IncrementModifier.Subtract)}
                <input
                    className={cx(
                        "-ui-border w-[140px] bg-white text-center text-2xl",
                        !!amountError &&
                            "border-red-600 bg-red-50 text-red-600",
                    )}
                    type="text"
                    step={increment}
                    min={0}
                    value={`${isCurrency ? "$" : ""}${amount}`}
                    onChange={changeHandler}
                    aria-label={isCurrency ? "Amount" : "Quantity"}
                    onKeyDown={keyDownHandler}
                />
                {getIncrementerButton(IncrementModifier.Add)}
            </div>
            {!!amountError && (
                <div role="alert" className="text-red-600 text-center text-xs">
                    {amountError}
                </div>
            )}
        </div>
    );
};

export default Incrementer;

import React, { useEffect, useState } from "react";
import { LogInstance } from "@qgiv/core-js";
import { EventPage, getEventRoute } from "@qgiv/auction/source/utility";
import { PaymentIFrameChannelName } from "@qgiv/auction/source/components";
import {
    systemEmitter,
    SystemEventType,
} from "@qgiv/auction/source/components/system/SystemEvent/SystemEvent";

const { log } = LogInstance("PaymentIFrame");

export const PaymentIFrame = () => {
    const [showIFrame, setShowIFrame] = useState(false);

    useEffect(() => {
        const paymentChannel = new BroadcastChannel(PaymentIFrameChannelName);
        const hideHandler = (event) => {
            if (!showIFrame && event.data === SystemEventType.HidePaymentIframe)
                return;
            log(`Received message: ${event.data}`);
            setShowIFrame(false);
        };
        const showHandler = () => {
            if (showIFrame) return;
            log(`Received event ${SystemEventType.ShowPaymentIframe}`);
            setShowIFrame(true);
        };
        // NOTE: we use broadcast channels to hide the payment iframe so that it can be triggered from the iframe
        paymentChannel.addEventListener("message", hideHandler);
        // NOTE: we use events to open the payment iframe to prevent it from opening in all windows
        systemEmitter.on(SystemEventType.ShowPaymentIframe, showHandler);

        return () => {
            paymentChannel.removeEventListener("message", hideHandler);
            paymentChannel.close();
            systemEmitter.removeListener(
                SystemEventType.ShowPaymentIframe,
                showHandler,
            );
        };
    });

    if (!showIFrame) return null;

    return (
        <iframe
            src={getEventRoute(EventPage.Payment)}
            title="Account Payment Method"
            className="absolute left-0 top-0 z-20 h-screen w-full"
        />
    );
};

// @ts-check
import React, { useState } from "react";
import { Icon, ImageSlider } from "@qgiv/core-react";
import ItemHeading from "../ItemHeading";
import { GiviUpsellModal } from "../../givi";
import ItemStatus from "../ItemStatus";
import ItemAmount from "../ItemAmount/ItemAmount";
import ItemDescriptionAndSubmittedBy from "../ItemDescriptionAndSubmittedBy";
import {
    EventPage,
    getEventRoute,
    getItemStatus,
    getItemStatusColor,
    getItemStatusText,
    isActionDisabledStatus,
} from "../../../utility";
import ItemImage from "../../ItemImage/ItemImage";
import { Breadcrumb, Button, ButtonStyle, ImageZoom } from "../../core";
import ItemImagesModal from "../ItemImagesModal";
/** @typedef {import("../../../utility/itemHelpers.js").Product} Product */
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Account} Account */
/** @typedef {import("../../../types").Auction} Auction */
/** @typedef {import("../../../types/Image.js").Image} Image */

/**
 * @param {object} props - The properties object.
 * @param {object} props.item
 * @param {Attendee} [props.attendee]
 * @param {boolean} [props.hasActivityForBidder]
 * @param {Function|null} [props.onWatchClick]
 * @param {Image[]} props.images
 * @param {number} props.time
 * @param {Function|null} [props.actionClickHandler]
 * @param {Auction} props.auction
 * @param {boolean} [props.watching]
 * @param {Account} props.account
 * @param {Function} props.onLoginClick
 * @returns {React.JSX.Element} The rendered ItemDetails component.
 */
const ItemDetails = ({
    item,
    attendee,
    hasActivityForBidder = false,
    onWatchClick = null,
    watching = false,
    images = [],
    time,
    actionClickHandler = null,
    auction,
    account,
    onLoginClick,
}) => {
    const [activeImageKey, onToggle] = useState(0);
    const status = getItemStatus(item, time, attendee, hasActivityForBidder);
    const [showImagesModal, setShowImagesModal] = useState(false);
    const disabled = isActionDisabledStatus(status);
    const statusText = getItemStatusText(status, item);
    const statusColor = getItemStatusColor(status);
    const thumbnails = images.filter((_, index) => index < 4);
    const remaining = images.length - thumbnails.length;
    const overlay = remaining ? (
        <Button
            btnStyle={ButtonStyle.None}
            className="absolute top-0 flex size-full items-center justify-center rounded bg-black/50 font-bold text-white"
            onClick={() => setShowImagesModal(true)}
        >
            +{remaining} more
        </Button>
    ) : null;
    const thumbnailSection =
        images.length > 1 ? (
            <div className="thumbnail-container -gap--10 static mt-3">
                {thumbnails.map(({ id, filename, url }, index) => (
                    <div key={id} className="w-25 relative">
                        <ImageSlider.Thumbnails
                            imageKey={index}
                            shape="squared"
                            size="large"
                            element="div"
                        >
                            <img
                                className="max-h-10"
                                src={url}
                                alt={filename}
                            />
                        </ImageSlider.Thumbnails>
                        {index === 3 ? overlay : null}
                    </div>
                ))}
            </div>
        ) : null;
    const imageZoomLabel = (
        <span className="hidden items-center justify-center gap-2 pt-3 md:flex">
            Roll over image to zoom in
            <Icon type="FontAwesome" glyph="magnifying-glass-plus" ariaHidden />
        </span>
    );
    const breadcrumbTrail = new Map();
    breadcrumbTrail.set("Items", getEventRoute(EventPage.Items));

    return (
        <>
            <Breadcrumb label="Item Details" trail={breadcrumbTrail} />
            <div className="item-details -ui-border -background-color--system-white m:p-10 p-5">
                <div
                    data-testid="itemDetailsContainer"
                    className="mb-5 flex flex-col justify-start md:flex-row md:justify-center md:gap-14"
                >
                    <div className="w-full md:w-1/2">
                        {images.length ? (
                            <ImageSlider
                                activeImageKey={activeImageKey}
                                onToggle={onToggle}
                                images={images}
                            >
                                <div className="flex size-full flex-col items-stretch justify-center">
                                    {images.map(
                                        ({ id, filename, url }, index) => (
                                            <ImageSlider.Content
                                                key={id}
                                                imageKey={index}
                                                imageCount={images.length}
                                                hideArrowButtons
                                                className="grow md:ml-3"
                                            >
                                                <ImageZoom
                                                    src={url}
                                                    alt={filename}
                                                />
                                            </ImageSlider.Content>
                                        ),
                                    )}
                                    {imageZoomLabel}
                                </div>
                                {thumbnailSection}
                            </ImageSlider>
                        ) : (
                            <div className="flex h-full flex-col items-center justify-center">
                                <ItemImage item={item} />
                                {imageZoomLabel}
                            </div>
                        )}
                    </div>
                    <div className="flex w-full flex-col gap-4 md:w-1/2">
                        <ItemHeading
                            item={item}
                            onWatchClick={onWatchClick}
                            watching={watching}
                        />
                        <div className="py-2.5">
                            <ItemStatus text={statusText} color={statusColor} />
                        </div>
                        <ItemAmount
                            item={item}
                            time={time}
                            actionClickHandler={actionClickHandler}
                            disabled={disabled}
                            account={account}
                            attendee={attendee}
                            auction={auction}
                            onLoginClick={onLoginClick}
                        />
                        <ItemDescriptionAndSubmittedBy {...item} />
                    </div>
                </div>
                <GiviUpsellModal auction={auction} />
                {overlay && (
                    <ItemImagesModal
                        images={images}
                        show={showImagesModal}
                        closeHandler={setShowImagesModal}
                    />
                )}
            </div>
        </>
    );
};

export default ItemDetails;

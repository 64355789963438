/**
 * @description parses an item
 * @param {any} rawItem raw item to be parsed
 * @param {Set<string>} intProperties property keys to parse as int
 * @param {Set<string>} floatProperties property keys to parse as float
 * @param {Set<string>} booleanProperties property keys to parse as boolean
 * @returns {any} newItem
 */
export default function parse(
    rawItem,
    intProperties = new Set(),
    floatProperties = new Set(),
    booleanProperties = new Set(),
) {
    const newItem = {};
    Object.entries(rawItem).forEach(([key, value]) => {
        let parsedValue = value;
        if (intProperties.has(key)) {
            parsedValue = parseInt(value, 10);
        }
        if (floatProperties.has(key)) {
            parsedValue = parseFloat(value);
        }
        if (booleanProperties.has(key)) {
            parsedValue = value === "1";
        }
        // set property
        newItem[key] = parsedValue;
    });
    return newItem;
}

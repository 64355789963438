import React from "react";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import Button, { ButtonStyle } from "./Button";

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} [props.irremovable]
 * @param {object} [props.rest]
 * @returns {React.JSXElement}
 */
const ButtonPill = ({ children, irremovable = false, ...rest }) => (
    <Button
        {...rest}
        btnStyle={ButtonStyle.Secondary}
        className={cx("btn--pill", irremovable && "btn--irremovable")}
    >
        <span className="flex items-center gap-1">
            {children}
            {!irremovable && (
                <Icon glyph="times-regular" label="Remove" type="FontAwesome" />
            )}
        </span>
    </Button>
);

export default ButtonPill;

// @ts-check
import { constants } from "@qgiv/core-js";

/** @typedef {import("../types/Product").Product} Product */

const {
    ENUMS: { StoreProductType },
} = constants;

export const defaultItemSort = "numberasc";

/**
 * Handle state update for filters
 * @param {string} filter Filter key.
 * @param {string} value Filter value.
 * @param {object} activeFilters The collection of active filters.
 * @param {Function} setActiveFilters The set state function.
 */
export function toggleFilter(filter, value, activeFilters, setActiveFilters) {
    const filters = { ...activeFilters };
    if (!filters[filter]) {
        filters[filter] = [];
    }

    if (filters[filter].includes(value)) {
        filters[filter] = filters[filter].filter((f) => f !== value);
    } else {
        filters[filter].push(value);
    }

    setActiveFilters(filters);
}

/**
 * Handle resetting all filters
 * @param {Function} setActiveFilters State handler for active filters.
 * @param {Function} setActiveSort State handler for active sort.
 * @param {Function} onChanged Change handler.
 * @param {Function} onClose Close handler.
 */
export function resetFilters(
    setActiveFilters,
    setActiveSort,
    onChanged,
    onClose,
) {
    setActiveFilters({});
    setActiveSort(defaultItemSort);

    onChanged?.call(undefined, { filters: {}, sort: defaultItemSort });
    onClose?.call(undefined);
}

/**
 * Handle Apply button clicked
 * @param {object} activeFilters Active filter options.
 * @param {object} activeSort Active sort key.
 * @param {Function} onChanged Change handler.
 * @param {Function} onClose Close handler
 */
export function applyFilters(activeFilters, activeSort, onChanged, onClose) {
    const newFilters = { ...activeFilters };

    onChanged?.call(undefined, { filters: newFilters, sort: activeSort });
    onClose?.call(undefined);
}

/**
 * Get List of sort options
 * @returns {Array} sort options.
 */
export function getSortOptions() {
    return [
        { value: "priceasc", name: "Price: low to high" },
        { value: "pricedesc", name: "Price: high to low" },
        { value: "numberasc", name: "Item Number, low to high" },
        { value: "numberdesc", name: "Item Number, high to low" },
        { value: "nameasc", name: "Item Name, A to Z" },
        { value: "namedesc", name: "Item Name, Z to A" },
        { value: "bidasc", name: "Number of Bids: low to high" },
        { value: "biddesc", name: "Number of Bids: high to low" },
    ];
}

/**
 * Sort items by key.
 * @param {Product[]} items List of items.
 * @param {string} key Key to sort by.
 * @returns {Array} Sorted list of items.
 */
export function sortItems(items, key) {
    return [...items].sort((a, b) => {
        let aVal;
        let bVal;
        const dir = key?.includes("desc") ? -1 : 1;

        switch (key) {
            case "priceasc":
            case "pricedesc":
                aVal = a.currentPrice || a.cost;
                bVal = b.currentPrice || b.cost;
                break;
            case "numberasc":
            case "numberdesc":
                aVal = parseInt(a.number, 10);
                bVal = parseInt(b.number, 10);
                break;
            case "nameasc":
            case "namedesc":
                aVal = a.title.trim().toUpperCase();
                bVal = b.title.trim().toUpperCase();
                break;
            case "bidasc":
            case "biddesc":
                aVal = a.activityCount;
                bVal = b.activityCount;
                break;
            default:
                return 0;
        }

        if (aVal === bVal) {
            return 0;
        }

        const returnVal = (aVal > bVal ? 1 : -1) * dir;

        return returnVal;
    });
}

/**
 * Filter item results.
 * @param {Array} items The list of items.
 * @param {any} filters The filters to apply.
 * @returns {Array} The filtered list of items.
 */
export function filterItems(items, filters) {
    if (!filters) {
        return items;
    }

    const filteredItems = [...items].filter((item) => {
        if (
            filters.bids?.length &&
            filters.bids[0] === "no-bids" &&
            (item.bidCount !== 0 || item.categoryType !== StoreProductType.BID)
        ) {
            return false;
        }

        if (
            filters.categories?.length &&
            !filters.categories.includes(item.category)
        ) {
            return false;
        }

        if (
            filters.tags?.length &&
            !filters.tags.every((t) => item.tags && item.tags.includes(t))
        ) {
            return false;
        }

        return true;
    });

    return filteredItems;
}

/**
 * Get number of filters applied
 * @param {any} filters The filters.
 * @returns {number} The number of filters applied.
 */
export function getFiltersCount(filters) {
    return Object.keys(filters).reduce(
        (prev, curr) => prev + (filters[curr].length ? 1 : 0),
        0,
    );
}

/**
 * Get list of filters.
 * @param {Array} items List of items.
 * @returns {any} List of filters.
 */
export function getFilterOptions(items) {
    const allCategories = items.map((item) => ({
        id: item.category,
        name: item.categoryName,
        type: item.categoryType,
    }));
    const categories = allCategories
        .filter(
            (category, index, original) =>
                original.map((o) => o.id).indexOf(category.id) === index,
        )
        .map((category) => ({
            ...category,
            itemCount: allCategories.filter((c) => c.id === category.id).length,
        }));

    const allTags = items.flatMap((item) => item.tags);
    const tags = allTags
        .filter(
            (tag, index, original) => !!tag && original.indexOf(tag) === index,
        )
        .map((tag) => ({
            name: tag,
            itemCount: allTags.filter((t) => t === tag).length,
        }));

    const bids = [
        {
            id: "no-bids",
            name: "No Bids",
            itemCount: items.filter(
                (item) =>
                    !item.bidCount &&
                    item.categoryType === StoreProductType.BID,
            ).length,
        },
    ];

    return {
        categories,
        tags,
        bids,
    };
}

/**
 * @description for finding an item by keyword in a filter
 * @param {string} keyword item
 * @returns {(item:Product) => boolean}} is match
 */
export function itemKeywordFilterHandler(keyword) {
    const searchText = keyword.toLowerCase();
    return ({ title, description, tags }) =>
        title.toLowerCase().includes(searchText) ||
        description.toLowerCase().includes(searchText) ||
        tags
            .map((t) => t.toLowerCase())
            .join(",")
            .includes(searchText);
}

/**
 * @param {object} activeFilters
 * @param {object} filterOptions
 * @returns {boolean}
 */
export function hasBiddingFilters(activeFilters, filterOptions) {
    return (
        !activeFilters?.categories?.length ||
        activeFilters.categories.some(
            (c) =>
                filterOptions.categories.find((fo) => fo.id === c)?.type ===
                StoreProductType.BID,
        )
    );
}

/**
 * @param {object[]} sortOptions
 * @param {boolean} hasBidding
 * @returns {object[]}
 */
export function getFilteredSortOptions(sortOptions, hasBidding) {
    return sortOptions.filter(
        (so) => hasBidding || !so.value.startsWith("bid"),
    );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RegistrationModalView, RegisterSelect } from "@qgiv/auction";
import {
    resetModal,
    setModalView,
    selectEventUrl,
    selectEmail,
} from "../../../redux/loginRegistrationModalSlice";

const ConnectedRegisterSelect = () => {
    const dispatch = useDispatch();
    const eventUrl = useSelector(selectEventUrl);
    const email = useSelector(selectEmail);

    /**
     * Handle click to open event registration iframe.
     */
    function handleOpenRegistrationClick() {
        const { loadFullScreenModal = () => {} } = window?.QGIV?.Embed ?? {};

        dispatch(resetModal(false));
        loadFullScreenModal(null, {
            eventUrl: `${eventUrl}?email=${email ?? ""}`,
            eventName: "Registration",
        });
    }

    /**
     * Handle on submit click.
     */
    function handleLoginClick() {
        dispatch(setModalView(RegistrationModalView.OTP_CODE_SELECT));
    }

    return (
        <RegisterSelect
            email={email}
            handleOpenRegistrationClick={() => handleOpenRegistrationClick()}
            handleLoginClick={() => handleLoginClick()}
        />
    );
};

export default ConnectedRegisterSelect;

// @ts-check
import React, { useCallback } from "react";
import {
    getItemStatus,
    getItemStatusColor,
    getItemStatusText,
} from "../../../utility/itemHelpers";
import Sidebar from "../../Sidebar";
import ItemStatus from "../ItemStatus";
import "./ItemActivityFeed.scss";
import ItemActivityFeedItem from "./ItemActivityFeedItem";

/**
 * @param {object} props - The properties object.
 * @param {object} props.item
 * @param {object} [props.attendee]
 * @param {boolean} props.isOpen
 * @param {Function} props.onClose
 * @param {boolean} [props.hasActivityForBidder]
 * @param {number} props.time
 * @returns {React.JSX.Element} The rendered ItemActivityFeed component.
 */
const ItemActivityFeed = ({
    item,
    attendee,
    onClose,
    isOpen,
    hasActivityForBidder = false,
    time,
}) => {
    const handleOnClose = useCallback(onClose, [onClose]);
    const status = getItemStatus(item, time, attendee, hasActivityForBidder);
    const statusText = getItemStatusText(status, item);
    const statusColor = getItemStatusColor(status);

    /**
     * Sort the item bid history by timestamp.
     * @returns {Array} Sorted list of activities.
     */
    function getSortedBidHistory() {
        return [...item.bidHistory].sort((a, b) => {
            if (a.timestamp === b.timestamp) {
                return 0;
            }

            return new Date(a.timestamp) > new Date(b.timestamp) ? -1 : 1;
        });
    }

    return (
        <Sidebar title="Activity Feed" onClose={handleOnClose} isOpen={isOpen}>
            <Sidebar.Body>
                <div>
                    {statusText && (
                        <div className="mb-1">
                            <ItemStatus text={statusText} color={statusColor} />
                        </div>
                    )}
                    <div>
                        {!item.bidHistory?.length
                            ? "No item activity."
                            : getSortedBidHistory().map(
                                  (
                                      /** @type {{id: string, bidder: string; amount: string; timestamp_iso: string}} */ activity,
                                      i,
                                  ) => (
                                      <ItemActivityFeedItem
                                          {...activity}
                                          index={i}
                                          key={activity.id}
                                      />
                                  ),
                              )}
                    </div>
                </div>
            </Sidebar.Body>
            <Sidebar.Actions>
                <button
                    type="button"
                    data-testid="close-button"
                    className="btn btn--regular btn--secondary w-full"
                    onClick={() => handleOnClose()}
                >
                    Close
                </button>
            </Sidebar.Actions>
        </Sidebar>
    );
};

export default ItemActivityFeed;

// @ts-check
import React, { useState } from "react";
import { Icon } from "@qgiv/core-react";
import { twMerge } from "tailwind-merge";
import { HeaderDropdown } from "../HeaderDropdown";
import {
    EventPage,
    getEventRoute,
    itemKeywordFilterHandler,
} from "../../../utility";
/** @typedef {import("../../../types/Product").Product} Product */

const Search = (/** @type {{items:Product[]}} */ { items }) => {
    const [searchText, setSearchText] = useState("");
    const [hasFocus, setHasFocus] = useState(false);
    const labelText = "Search Items";

    if (!items.length) return null;
    const filtered = !searchText
        ? items
        : items.filter(itemKeywordFilterHandler(searchText));
    const results = filtered.length ? (
        <nav>
            <ul>
                {filtered.map((i) => (
                    <li
                        className="rounded border-solid border-[#cfd7da] py-3 focus-within:shadow-[0_0_0_1px_white,0_0_0_2px_black] [&:not(:first-child)]:border-t"
                        key={i.id}
                    >
                        <a
                            className="focus:outline-none"
                            href={`${getEventRoute(EventPage.Item)}/${i.id}`}
                        >
                            <strong>{i.title}</strong>
                            <br />
                            <small className="text-[color:var(--Background-Tertiary-Gray,#66737D)]">
                                {i.categoryName}
                            </small>
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    ) : (
        "No Items Found"
    );

    return (
        <HeaderDropdown>
            <HeaderDropdown.MenuItem>
                <div
                    className={twMerge(
                        "border-1 w-48 rounded border border-solid sm:w-60",
                        !hasFocus && "pl-3",
                    )}
                >
                    {!hasFocus && (
                        <Icon
                            glyph="search-solid"
                            ariaHidden
                            type="FontAwesome"
                        />
                    )}
                    <input
                        className={twMerge(
                            "w-40 px-3 py-2 focus:bg-white focus:text-black",
                            hasFocus && "w-full",
                        )}
                        type="text"
                        value={searchText}
                        placeholder={labelText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                        aria-label={labelText}
                    />
                </div>
            </HeaderDropdown.MenuItem>
            <HeaderDropdown.DropdownContent
                show={!!searchText}
                width="w-screen min-w-[320px] min-[425px]:w-[400px] right-[-12px] min-[425px]:right-0 lg:right-auto"
                theme="text-[color:var(--Theme-Text-Black,#394146)] bg-white"
            >
                <div className="text-inherit" role="alert">
                    <div className="flex items-center justify-between pb-3">
                        <h2>Search Results</h2>
                        <button
                            className="flex items-center"
                            type="button"
                            aria-label="Close"
                            onClick={() => setSearchText("")}
                        >
                            <Icon
                                type="FontAwesome"
                                glyph="times-regular"
                                ariaHidden
                            />
                        </button>
                    </div>
                    {results}
                </div>
            </HeaderDropdown.DropdownContent>
        </HeaderDropdown>
    );
};

export default Search;

import React from "react";
import { Icon } from "@qgiv/core-react";
import { EventPage, getEventRoute } from "../../../utility";
import {
    hasPreviousRouteInHistory,
    navigateBackWithHistory,
} from "./breadcrumbHelper";

/**
 * @param {object} props
 * @param {string} props.label
 * @param {Map<string,string>} [props.trail]
 * @returns {React.ReactNode}
 */
const Breadcrumb = ({ label, trail = new Map() }) => {
    const backClickHandler = (e) => {
        e.preventDefault();
        navigateBackWithHistory();
    };
    const divider = (
        <Icon type="FontAwesome" glyph="chevron-right-solid" ariaHidden />
    );
    const breadcrumbTrail = [...trail.entries()].map(([text, url]) => (
        <li
            key={`${text.replace(" ", "-")}/${url}`}
            className="flex items-center gap-2"
        >
            <a href={url}>{text}</a>
            {divider}
        </li>
    ));

    return (
        <nav className="pb-3" aria-label="Breadcrumb">
            <ol className="flex gap-2 text-[color:var(--Theme-Text-Black,#394146)]">
                {hasPreviousRouteInHistory() && (
                    <li className="flex items-center gap-2">
                        <a
                            href="../"
                            className="ui-text-primary-color"
                            role="button"
                            onClick={backClickHandler}
                        >
                            Back
                        </a>
                        <span aria-hidden="true">•</span>
                    </li>
                )}
                <li className="flex items-center gap-2">
                    <a href={getEventRoute(EventPage.Home)}>Home</a>
                    {divider}
                </li>
                {breadcrumbTrail}
                <li className="flex items-center">
                    <a
                        className="text-[color:var(--Theme-medium-dark,#66737d)]"
                        href={window.location.href}
                        aria-current="page"
                    >
                        {label}
                    </a>
                </li>
            </ol>
        </nav>
    );
};

export default Breadcrumb;

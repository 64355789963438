import { RegistrationModalView, RegistrationModalType } from "@qgiv/auction";
import {
    setIsModalOpen,
    setEmail,
    setModalView,
    setModalType,
    setFormId,
    setName,
} from "../redux/loginRegistrationModalSlice";
import { removeRegistrationQueryString } from "./queryStringHelpers";

/**
 * Handle auction login message.
 * @param {MessageEvent} message The message event.
 * @param {object} store The redux store.
 */
export async function auctionLoginMessageHandler(message, store) {
    // for security, we need to make sure that the message comes from our environment or the registered widget
    if (
        // eslint-disable-next-line no-undef
        (message.origin !== QGIV?.Embed?.settings?.environment &&
            message.origin !== `https://${window.location.host}`) ||
        typeof message.data !== "string" ||
        message.data.length < 1
    ) {
        return;
    }

    try {
        const { event, data } = JSON.parse(message.data);

        if (event === "closeFullScreenModal") {
            removeRegistrationQueryString();
        }

        if (event !== "openAuctionLoginModal") {
            return;
        }

        const { email, formId, name } = data;

        store.dispatch(setFormId(formId));
        store.dispatch(setEmail(email));
        store.dispatch(setName(name));
        store.dispatch(setModalType(RegistrationModalType.POST_REGISTRATION));
        store.dispatch(setModalView(RegistrationModalView.LOGIN_NO_PASSWORD));
        store.dispatch(setIsModalOpen(true));
    } catch (e) {
        /* empty */
    }
}

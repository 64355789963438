import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { Icon } from "@qgiv/core-react";
import {
    ButtonHighlightStyle,
    Button,
    ButtonStyle,
    Dialog,
    ModalSize,
} from "../../core";
import { ApiStatus } from "../../../api";
import LoadingIcon from "../../core/LoadingIcon";
import { EventPage, getEventRoute } from "../../../utility";
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Auction} Auction */
/** @typedef {import("../../../types").Account} Account */
/**
 * @typedef Transaction
 * @property {string} amount amount
 * @property {string} bidder bidder
 * @property {string} ccLabel cc label
 * @property {string} id id
 * @property {string} lastFour last four
 * @property {string} name name
 * @property {string} paymentType payment type
 * @property {string} receiptId receipt id
 * @property {string} status status
 */

/**
 * @param {object} props
 * @param {Transaction[]} [props.transactions]
 * @param {Function} props.closeHandler
 * @param {Auction} props.auction
 * @param {Account} props.account
 * @param {Attendee} props.attendee
 * @param {Function} props.useDispatch
 * @param {Function} props.fetchTransactions
 * @param {string} props.transactionsStatus
 * @param {boolean} [props.show]
 * @param {boolean} [props.open]
 * @returns {React.ReactNode}
 */
const ReceiptsModal = ({
    transactions = [],
    closeHandler,
    auction,
    account,
    attendee,
    useDispatch,
    fetchTransactions,
    transactionsStatus,
    show,
    open,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (transactionsStatus === ApiStatus.Idle) {
            dispatch(
                fetchTransactions({
                    auctionId: auction.id,
                    bidderId: attendee.id,
                    accountId: account.id,
                    api_token: account.token,
                }),
            );
        }
    }, [
        transactionsStatus,
        dispatch,
        auction,
        attendee,
        account,
        fetchTransactions,
    ]);

    return (
        <Dialog
            title="View Receipts"
            closeHandler={closeHandler}
            className={ModalSize.Medium}
            show={show}
            open={open}
        >
            {[ApiStatus.Idle, ApiStatus.Pending].includes(
                transactionsStatus,
            ) && <LoadingIcon />}
            {transactionsStatus === ApiStatus.Succeeded &&
                transactions.length && (
                    <ul className="max-h-[70vh] overflow-y-auto">
                        {transactions.map((transaction) => (
                            <li
                                key={transaction.id}
                                className="mb-4 flex flex-col gap-2.5 border-b border-dashed border-[#AAB6BC] pb-4"
                            >
                                <div className="flex flex-col gap-2 sm:flex-row sm:gap-20">
                                    <strong className="grow text-lg">
                                        Total: ${transaction.amount}
                                    </strong>
                                    <a
                                        className={twMerge(
                                            "ui-text-primary-color flex items-center gap-1 rounded font-bold",
                                            ButtonHighlightStyle.Focus,
                                        )}
                                        href={getEventRoute(
                                            EventPage.Transaction,
                                            transaction.receiptId,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon
                                            glyph="receipt-solid"
                                            type="FontAwesome"
                                            ariaHidden
                                        />
                                        View Receipt
                                    </a>
                                </div>
                                <div className="flex flex-col gap-1 text-[15px] text-[#66737D]">
                                    <span>Transaction #{transaction.id}</span>
                                    <span>
                                        Payment Method: {transaction.ccLabel}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            {[ApiStatus.Succeeded, ApiStatus.Failed] &&
                !transactions.length && <p>No receipts found</p>}
            <Button
                className="mt-5 w-full"
                btnStyle={ButtonStyle.Primary}
                onClick={closeHandler}
            >
                Return Home
            </Button>
        </Dialog>
    );
};

export default ReceiptsModal;

import React, { useEffect, useRef } from "react";

const RegisterSelect = (
    /** @type {{ handleOpenRegistrationClick:Function, handleLoginClick:Function, email?: string }} */
    { handleOpenRegistrationClick = null, handleLoginClick = null, email = "" },
) => {
    const firstInput = useRef(null);

    useEffect(() => {
        firstInput?.current?.focus();
    }, [firstInput]);

    return (
        <>
            <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                You&apos;re already registered, log in to bid!
            </div>
            <div className="mt-[10px] flex items-center justify-center gap-1 text-[15px] tracking-[0.12px] text-[#66737D]">
                Ticket found for {email}
            </div>
            <button
                data-testid="login-btn"
                className="btn btn--primary mt-[30px] w-full p-[12px] text-lg tracking-[0.12px]"
                type="button"
                onClick={() => handleLoginClick()}
            >
                Log In
            </button>
            <div className="mt-[15px] flex items-center justify-center gap-1 text-[15px] tracking-[0.12px] text-[#66737D]">
                Still want to register?{" "}
                <button
                    type="button"
                    className="font-bold text-[color:var(--user-theme\_\_color--primary,#1F6FD9)] hover:underline"
                    onClick={() => handleOpenRegistrationClick()}
                >
                    Register here
                </button>
            </div>
        </>
    );
};

export default RegisterSelect;

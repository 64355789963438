import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { tick } from "../../redux";

const SystemTimer = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const id = setInterval(() => dispatch(tick()), 1000);
        return () => clearInterval(id);
    }, [dispatch]);
    return null;
};

export default SystemTimer;

import { constants } from "@qgiv/core-js";
import { hasPassed } from "../../utility";
import parse from "../Base";
import {
    sampleBidItemImage1,
    sampleBidItemImage2,
    sampleBidItemImage3,
    sampleBidItemImage4,
} from "../../images";
import { parseAttribute } from "../Attribute";

const {
    ENUMS: { StoreProductType },
} = constants;

/** @typedef {import("../../../../../packages/auction/source/types").Product} Product */

const intProperties = new Set([
    "bidCount",
    "categoryType",
    "featureInReg",
    "purchaseLimit",
    "purchaseStatus",
    "status",
    "watchers",
    "weight",
    "maxQty",
    "qtySold",
    "perc_complete",
    "activityCount",
    "fulfilledTotal",
]);
const floatProperties = new Set([
    "bidIncrement",
    "cost",
    "currentPrice",
    "fairMarketValue",
    "fundANeedGoal",
    "itemValue",
    "reserve",
    "value",
    "maxBid",
    "maxUserBid",
    "raised",
    "procurementCost",
]);
const booleanProperties = new Set([
    "checkout",
    "displayValue",
    "hasAttributes",
    "private",
    "reassigned",
    "unlimited",
]);

/**
 * @description parses a prodcut object
 * @param {any} rawProduct raw product
 * @returns {Product} product
 */
export function parseProduct(rawProduct) {
    /** @type {Product} */
    const product = parse(
        rawProduct,
        intProperties,
        floatProperties,
        booleanProperties,
    );
    // custom properties
    product.isActive = product.status === 1;
    product.isBidType = product.categoryType === StoreProductType.BID;
    product.isBuyType = product.categoryType === StoreProductType.BUY;
    product.isDonateType = product.categoryType === StoreProductType.DONATE;
    // objects
    if (product.isBuyType && product.hasAttributes) {
        product.attribute = parseAttribute(product.attribute);
    }

    return product;
}

/**
 * @description for filtering products by category ids
 * @param {Product[]} products products
 * @param {string[]} categoryIds category ids
 * @returns {Product[]} products
 */
export function getProductsByCategoryIds(products, categoryIds) {
    if (!products?.length || !categoryIds?.length) return [];
    return products.filter(({ categoryId }) =>
        categoryIds.includes(categoryId),
    );
}

/** @type {Product} */
const sampleProduct = {
    id: "preview",
    title: "1927 Babe Ruth Signed Baseball Cards",
    description:
        'Rare 1927 Babe Ruth Signed Baseball Card, a coveted piece of baseball history. Featuring the iconic signature of the "The sultan of swat!", "The king of crash!", "The colossus of clout!", "BABE RUTH!" "THE GREAT BAMBINO!"',
    maxQty: 1,
    purchaseLimit: 1,
    cost: 5,
    soldOutMsg: "",
    featureInReg: 2,
    category: "87192",
    unlimited: false,
    hasAttributes: false,
    icon: "icon-tag",
    status: 1,
    categoryId: "87192",
    categoryName: "Sports",
    categoryType: 1,
    bidStartTime: "0000-00-00 00:00:00",
    startTimeIso: new Date(Date.now() + 1000 * 60 * 60 * 24 * 2)
        .toISOString()
        .replace(/\.\d{3}Z$/, "-0000"),
    endTimeIso: new Date(Date.now() + 1000 * 60 * 60 * 24 * 5)
        .toISOString()
        .replace(/\.\d{3}Z$/, "-0000"),
    bidStartTimeIso: "",
    categoryEnded: false,
    categoryStarted: true,
    private: false,
    checkout: false,
    weight: 0,
    winner: "",
    itemWinner: "",
    bidCount: 0,
    winningBidderNumber: "",
    winningBidderName: "",
    currentPrice: 1500,
    maxBid: 100,
    reserve: 0,
    bidIncrement: 5,
    number: "101",
    value: 0,
    displayItemValue: "",
    itemValue: 0,
    fairMarketValue: 5000,
    procurementCost: 15,
    displayValue: false,
    fundANeedGoal: 0,
    reassigned: false,
    maxUserBid: 100,
    submittedBy: "Diamond Dreams Foundation",
    purchaseId: "",
    transaction: "",
    purchaseTime: "",
    purchaseStatus: 0,
    fulfilledTotal: 0,
    activityCount: 0,
    raised: 0,
    raisedMinusProcurementCost: 0,
    images: [
        sampleBidItemImage1,
        sampleBidItemImage2,
        sampleBidItemImage3,
        sampleBidItemImage4,
        sampleBidItemImage1,
        sampleBidItemImage2,
        sampleBidItemImage3,
        sampleBidItemImage4,
    ],
    tags: ["Vintage", "Collectible"],
    watchers: 0,
    isBidType: true,
    isBuyType: false,
    isDonateType: false,
    isActive: true,
    qtySold: 0,
    attribute: null,
    bidHistory: [],
};

/**
 * @description get product by id
 * @param {Product[]} products products
 * @param {string} id product id
 * @returns {Product} product
 */
export function getProduct(products, id) {
    if (!id) throw new Error("Product id is required");
    if (id === "preview") {
        return products.length ? products[0] : sampleProduct;
    }
    if (!products?.length) return null;
    return products.find((p) => p.id === id);
}

/**
 * @description get active products
 * @param {Product[]} products products
 * @returns {Product[]} products
 */
export function getActiveProducts(products) {
    if (!products?.length) return [];
    return products.filter(
        (p) => p.isActive && !p.private && hasPassed(p.startTimeIso),
    );
}

import React from "react";
import "./ItemImage.scss";
/** @typedef {import("../../../types").Product} Product */

const ItemImage = (/** @type {{item:Product}} */ { item }) =>
    (item.images?.length && (
        <div
            className="bg-slate-50 relative flex size-full h-[177px] overflow-hidden"
            data-testid="itemImage"
        >
            <img
                src={item.images[0]}
                alt={item.title}
                loading="lazy"
                className="size-full object-contain object-center"
            />
        </div>
    )) || (
        <div
            className="bg-slate-50 flex size-full h-[177px]"
            data-testid="itemIcon"
        >
            <div
                className={`store-icon js-auction-item-icon text-7xl flex w-full items-center justify-center ${
                    item.icon ?? "icon-tag"
                }`}
                aria-label={item.title}
                role="img"
            />
        </div>
    );

export default ItemImage;

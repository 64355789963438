import { Icon } from "@qgiv/core-react";
import React from "react";
import cx from "classnames";
import {
    HeaderDropdown,
    DropdownContentWidth,
} from "../../header/HeaderDropdown";
import "./SettingsDropdown.scss";
import { NavigationListPosition } from "../../header/NavigationList";

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {NavigationListPosition} [props.position]
 * @param {boolean} [props.show]
 * @param {Function} props.toggle
 * @returns {React.JSX.Element}
 */
const SettingsDropdown = ({
    children,
    position = NavigationListPosition.Header,
    show = false,
    toggle,
}) => {
    const iconClasses = cx(
        position === NavigationListPosition.Header && "hidden lg:flex",
        position === NavigationListPosition.Sidebar && "flex",
        "-settings-dropdown--button items-center justify-center",
        show && "-active",
    );
    const width =
        position === NavigationListPosition.Sidebar
            ? DropdownContentWidth.Auto
            : DropdownContentWidth[400];

    return (
        <HeaderDropdown className="-settings-dropdown">
            <HeaderDropdown.MenuItem>
                <span className={iconClasses}>
                    <button
                        className="items--center flex"
                        type="button"
                        onClick={() => toggle(!show)}
                        aria-label="Edit"
                    >
                        <Icon
                            glyph="gear"
                            type="FontAwesome"
                            ariaHidden
                            label="Edit"
                        />
                    </button>
                </span>
            </HeaderDropdown.MenuItem>
            <HeaderDropdown.DropdownContent show={show} width={width}>
                {children}
            </HeaderDropdown.DropdownContent>
        </HeaderDropdown>
    );
};

export default SettingsDropdown;

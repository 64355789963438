import { LogInstance } from "@qgiv/core-js";
import store, { updateNavigation } from "../redux";
import { hasEqualProperties } from "../utilities";
/** @typedef {import("@qgiv/auction/source/types/Navigation").Navigation} Navigation */

const { log } = LogInstance("api.legacyCMS");

/**
 * @description sends update to legacy CMS
 * @param {Navigation[]} activeItems active navigation items
 * @param {string[]} deletedIds deleted ids
 */
function updateNavigationCMS(activeItems, deletedIds) {
    if (!activeItems.length && !deletedIds.length) return;

    const pageTarget = "navigation";
    /** @type {{navigation: Navigation[]}} */
    const {
        cms: { navigation },
    } = store.getState();
    let updates = 0;

    if (activeItems.length) {
        activeItems.forEach((item) => {
            const { id, title, newWindow, targetid, url, weight, internal } =
                item;
            const existing = navigation.find((nav) => nav.id === id);
            if (existing && hasEqualProperties(existing, item)) return;
            const link = {
                title,
                new_window: newWindow,
                id,
                url,
                weight,
            };
            if (!internal) {
                link.isCustom = true;
            }
            if (url === "%custom%") {
                link.targetId = targetid;
            }
            log("update", item, link);
            window.hobnob.cms.updatePage(pageTarget, link);
            updates += 1;
        });
    }
    if (deletedIds.length) {
        deletedIds.forEach((id) => {
            log(`delete id: ${id}`);
            window.hobnob.cms.updatePage(pageTarget, { id, delete: 1 });
            updates += 1;
        });
    }
    if (!updates) return;
    store.dispatch(updateNavigation(activeItems));
    const event = new Event("cms:navigationupdated");
    document.dispatchEvent(event);
}

export default updateNavigationCMS;

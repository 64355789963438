/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
    listInitialState,
    activityStateMutator,
} from "./stateMutators/stateMutators";

const purchasesSlice = createSlice({
    name: "purchases",
    initialState: listInitialState,
    reducers: {
        updatePurchases: activityStateMutator,
    },
});

export default purchasesSlice.reducer;
export const { updatePurchases } = purchasesSlice.actions;

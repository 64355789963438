import React, { useCallback, useMemo } from "react";
import { LogInstance } from "@qgiv/core-js";

import "./Icon.scss";

// Previously, the `.icon` class applied a vertical-align: text-bottom style.
// This logic was introduced to allow us to overwrite that style using a
// modifier class. If a `-vertical-align--*` (modifier) or `align-text-*`
// (Tailwind) class is provided, we use it as-is. If neither is passed in, both
// `-vertical-align--text-bottom` and `align-text-bottom` are applied.
export const getAlignmentClasses = (className) =>
    /-vertical-align--/.test(className) || /align-text-/.test(className)
        ? ""
        : "-vertical-align--text-bottom align-text-bottom";

export const getIconClasses = (classNameProps) =>
    ["icon", classNameProps, getAlignmentClasses(classNameProps)]
        .filter(Boolean)
        .join(" ")
        .replace(/\s+/g, " ") // remove multiple spaces
        .trim();

/**
 * @param {object} props
 * @param {string|boolean} [props.ariaHidden]
 * @param {string} [props.className]
 * @param {string[]} [props.classNames] DEPRECATED
 * @param {string} props.glyph
 * @param {string} props.type
 * @param {string|boolean} [props.label]
 * @param {object} [props.props]
 * @returns {React.ReactElement}
 */
const Icon = ({
    ariaHidden,
    className,
    classNames = [],
    glyph,
    label = glyph,
    type,
    ...props
}) => {
    const { error } = LogInstance("Icon");
    if (classNames.length) {
        error(
            "The `classNames` prop in `Icon` is deprecated and will be removed in 4.6.9. This will break existing implementations. Please migrate to `className` now.",
        );
    }

    // Normalize class names into a single string
    const classNameProps = [...classNames, className].filter(Boolean).join(" ");
    const _className = getIconClasses(classNameProps);

    // -------------------------------------------------------------------------
    // NOTE:
    // For accessibility, we need to add the 'aria-labelledby' property to the svg
    // which will point to title element within the svg. This will connect the
    // content of the title element to svg element for screen readers. Since there
    // are several can be icons of the same type (progress bar circles, tooltip question marks)
    // in the DOM at once, we need to generate a unique ID to pass to both the
    // 'aria-labelledby' svg property and the svg's title element.
    // -------------------------------------------------------------------------
    const generateUniqueTitleId = useCallback(() => {
        const ranNum = Math.floor(Math.random() * 100000);
        return `title-${ranNum}`;
    }, []);

    const titleId = generateUniqueTitleId();
    const useAriaHidden = ariaHidden || label === glyph;

    // When using the aria-hidden="true" attribute for an HTML element,
    // we need to not assign the aria-labelledby attribute since it will
    // confuse screen readers (JAWS), causing it to read out the svg.
    const svgProps = useMemo(
        () => ({
            role: "img",
            ...(useAriaHidden
                ? { "aria-hidden": "true" }
                : { "aria-labelledby": titleId }),
        }),
        [useAriaHidden, titleId],
    );

    return (
        <span className={_className}>
            <svg {...svgProps}>
                <title id={titleId}>{label}</title>
                <use href={`#${type}__${glyph}`} {...props} />
            </svg>
        </span>
    );
};

export default Icon;

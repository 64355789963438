// @ts-check
import { Icon } from "@qgiv/core-react";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import "./PageHeader.scss";
import "./PageHeaderSidebar.scss";
import Sidebar, { SidebarType } from "../../Sidebar";
import Search from "../Search";
import EventNavigation from "../EventNavigation";
import { NavigationListPosition } from "../NavigationList";
import AccountNavigation from "../AccountNavigation";
import SocialNavigation from "../SocialNavigation/SocialNavigation";
import { Toast } from "../../system";
import { ButtonHighlightStyle } from "../../core";
/** @typedef {import("../../../types").Product} Product */
/** @typedef {import("../../../types").Navigation} Navigation */
/** @typedef {import("../../../types").CustomPage} CustomPage */
/** @typedef {import("../../../types").Auction} Auction */
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Account} Account */
/**
 * @typedef Social
 * @property {string} [facebookURL] facebook URL
 * @property {string} [instagramURL] instagram URL
 * @property {string} [twitterURL] twitter URL
 * @property {string} [youtubeURL] youtube URL
 */

/**
 * @param {object} props
 * @param {Product[]} [props.items]
 * @param {Navigation[]} [props.navigation]
 * @param {boolean} [props.responsive]
 * @param {Social} [props.social]
 * @param {number} [props.cartCount]
 * @param {boolean} [props.editMode]
 * @param {CustomPage[]} [props.customPages]
 * @param {Function} [props.updateNavigationHandler]
 * @param {Function} props.logoutAsync
 * @param {Function} [props.ticketSelectAsync]
 * @param {Array} [props.tickets]
 * @param {Auction} [props.auction]
 * @param {Array} [props.transactions]
 * @param {Account} [props.account]
 * @param {Attendee} [props.attendee]
 * @param {Function} [props.useDispatch]
 * @param {Function} [props.fetchTransactions]
 * @param {string} [props.transactionsStatus]
 * @param {Function} [props.updateTicketAsync]
 * @param {Function} [props.onLoginClick]
 * @returns {React.ReactNode}
 */
const PageHeader = ({
    items = [],
    navigation = [],
    responsive = false,
    social = {},
    cartCount = 0,
    editMode = false,
    customPages = [],
    updateNavigationHandler,
    logoutAsync,
    ticketSelectAsync,
    tickets = [],
    transactions = [],
    auction,
    account,
    attendee,
    useDispatch,
    fetchTransactions,
    transactionsStatus,
    updateTicketAsync,
    onLoginClick,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuButtonHandler = () => {
        setIsOpen(!isOpen);
    };
    const logoutHandlerAsync = async () => {
        const success = await logoutAsync();
        if (success) window.location.reload();
    };

    return (
        <>
            <header
                className={twMerge(
                    "page__header $ main-navigation theme-only flex min-h-[74px] flex-row items-center gap-3 bg-white px-3 md:min-h-[84px] xl:gap-10 xl:px-10",
                    responsive && "responsive",
                )}
            >
                {responsive ? (
                    <>
                        <EventNavigation
                            links={navigation}
                            position={NavigationListPosition.Header}
                            editMode={editMode}
                            customPages={customPages}
                            updateNavigation={updateNavigationHandler}
                        />
                        <SocialNavigation
                            links={social}
                            position={NavigationListPosition.Header}
                        />
                    </>
                ) : null}
                <button
                    type="button"
                    className={twMerge(
                        "header__item flex items-center gap-1.5",
                        "hover:underline hover:opacity-60 focus:outline-none",
                        ButtonHighlightStyle.Focus,
                        responsive && "lg:!hidden",
                    )}
                    onClick={menuButtonHandler}
                >
                    <Icon glyph="bars" type="FontAwesome" ariaHidden />
                    Menu
                </button>

                <div className="grow" />
                <Search items={items} />

                {responsive ? (
                    <AccountNavigation
                        position={NavigationListPosition.Header}
                        cartCount={cartCount}
                        logoutHandler={logoutHandlerAsync}
                        ticketSelectAsync={ticketSelectAsync}
                        tickets={tickets}
                        transactions={transactions}
                        account={account}
                        attendee={attendee}
                        useDispatch={useDispatch}
                        transactionsStatus={transactionsStatus}
                        fetchTransactions={fetchTransactions}
                        auction={auction}
                        updateTicketAsync={updateTicketAsync}
                        onLoginClick={onLoginClick}
                    />
                ) : null}
            </header>
            <Toast />
            {isOpen && (
                <Sidebar
                    title="Menu"
                    type={SidebarType.Menu}
                    isOpen
                    onClose={menuButtonHandler}
                >
                    <div className="-page-header__sidebar gap-5 pt-5">
                        <AccountNavigation
                            position={NavigationListPosition.Sidebar}
                            cartCount={cartCount}
                            logoutHandler={logoutHandlerAsync}
                            ticketSelectAsync={ticketSelectAsync}
                            tickets={tickets}
                            transactions={transactions}
                            account={account}
                            attendee={attendee}
                            useDispatch={useDispatch}
                            transactionsStatus={transactionsStatus}
                            fetchTransactions={fetchTransactions}
                            auction={auction}
                            updateTicketAsync={updateTicketAsync}
                            onLoginClick={onLoginClick}
                        />
                        <div className="-divider" />
                        <EventNavigation
                            links={navigation}
                            position={NavigationListPosition.Sidebar}
                            editMode={editMode}
                            customPages={customPages}
                            updateNavigation={updateNavigationHandler}
                        />
                        <SocialNavigation
                            links={social}
                            position={NavigationListPosition.Sidebar}
                        />
                        {account && (
                            <>
                                <div className="-divider grow" />
                                <button
                                    className="btn btn--error -padding--15"
                                    type="button"
                                    onClick={logoutHandlerAsync}
                                >
                                    LOG OUT
                                </button>
                            </>
                        )}
                    </div>
                </Sidebar>
            )}
        </>
    );
};

export default PageHeader;

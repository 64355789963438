import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./SortableListItem.scss";

/**
 * @param {object} props
 * @param {string} props.id
 * @param {number} props.index
 * @param {React.node} props.children
 * @param {Function} props.moveItem
 * @returns {React.JSX.Element}
 */
const SortableListItem = ({ id, index, children, moveItem }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: "sortableListItem",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: "sortableListItem",
        item: () => ({
            id,
            index,
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.5 : 1;
    drag(drop(ref));

    return (
        <li
            className="-sortable-list--item -padding-bottom--5 -padding-top--10"
            ref={ref}
            style={{
                opacity,
            }}
            data-handler-id={handlerId}
            data-testid={`sortableListItem-${id}`}
        >
            {children}
        </li>
    );
};

export default SortableListItem;

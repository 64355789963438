export const RegistrationModalView = {
    CHECK_EMAIL: "CHECK_EMAIL",
    REGISTER_SELECT: "REGISTER_SELECT",
    LOGIN_NO_PASSWORD: "LOGIN_NO_PASSWORD",
    LOGIN_PASSWORD: "LOGIN_PASSWORD",
    OTP_CODE_SELECT: "OTP_CODE_SELECT",
    OTP_CODE_ENTER: "OTP_CODE_ENTER",
    CREATE_PASSWORD: "CREATE_PASSWORD",
    CONFIRMATION: "CONFIRMATION",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    ALREADY_LOGGED_IN: "ALREADY_LOGGED_IN",
};

export const RegistrationModalType = {
    POST_REGISTRATION: "POST_REGISTRATION",
    PRE_REGISTRATION: "PRE_REGISTRATION",
    LOGIN: "LOGIN",
};

export const OtpCodeType = {
    NONE: "NONE",
    EMAIL: "EMAIL",
    SMS: "SMS",
};

import React, { useState } from "react";
import cx from "classnames";

import { constants } from "@qgiv/core-js";

import { Icon } from "@qgiv/core-react";

import "./Checkbox.scss";

/**
 * @typedef {import("../../../types").ComponentTypes.CheckboxProps} CheckboxProps
 */

/**
 *
 * @param {CheckboxProps} props
 * @returns {React.ReactElement}
 */
const Checkbox = ({
    // HTML attributes
    ariaRequired = "false",
    checked = false,
    disabled = false,
    fieldType = "",
    indeterminate = false,
    name,
    id,
    required = false,
    value = false,

    // change handler
    handleBlur,
    handleChange,
    handleFocus,

    // error
    error = false,
    errorText = "",

    // other
    inset = false,
    innerPadding = true,
    ignoreBottomPadding = false,
    children = "",
}) => {
    const { FieldType } = constants.ENUMS;
    const checkboxFieldClasses = cx(
        "qg-vendor-input-box",
        "input-box",
        checked && "input-box--checked",
        disabled && "input-box--disabled",
        inset && "input-box--inset",
        error && "input-box--error",
    );
    const checkboxInnerClasses = cx(
        "qg-vendor-input-box__inner",
        "input-box__inner",
        innerPadding && "input-box__inner--padding",
        !disabled && "-cursor--pointer",
        ignoreBottomPadding && "input-box__inner--ignore-bottom-padding",
    );

    let checkedIcon;
    const roleAttribute = {};

    if (indeterminate) {
        checkedIcon = (
            <Icon className="input-box__svg" glyph="minus" type="FontAwesome" />
        );
    } else {
        checkedIcon = (
            <Icon
                className="input-box__svg input-box__svg--checkmark"
                glyph="check-solid"
                type="FontAwesome"
            />
        );
    }

    const [inputFocused, setInputFocused] = useState(false);

    const checkboxFocus = (e) => {
        setInputFocused(true);
        if (handleFocus && typeof handleFocus === "function") {
            handleFocus(e);
        }
    };

    const checkboxBlur = (e) => {
        setInputFocused(false);
        if (handleBlur && typeof handleBlur === "function") {
            handleBlur(e);
        }
    };

    // Assign the appropriate role attribute as checkboxes that are part of a
    // multiple selection are nested within an element that has an ARIA role
    // of listbox and list boxes contain children whose role is option.
    if (Number(fieldType) === FieldType.MULTIPLE_SELECTION) {
        roleAttribute.role = "option";
    } else {
        roleAttribute.role = "checkbox";
    }

    const ariaRequiredAttribute = {};

    if (ariaRequired) {
        ariaRequiredAttribute["aria-required"] = true;
    }

    return (
        <div className={checkboxFieldClasses}>
            <label htmlFor={id} className={checkboxInnerClasses}>
                <span
                    className={`qg-vendor-input-box__icon input-box__icon -cursor--pointer ${
                        inputFocused ? "input-box__icon--focused" : ""
                    }`}
                >
                    {checkedIcon}
                    <input
                        {...roleAttribute}
                        {...ariaRequiredAttribute}
                        className="input-box__input -cursor--pointer"
                        id={id}
                        type="checkbox"
                        checked={checked}
                        disabled={disabled}
                        name={name}
                        required={required}
                        value={value}
                        onBlur={checkboxBlur}
                        onChange={handleChange}
                        onFocus={checkboxFocus}
                    />
                </span>
                <span className="input-box__label">{children}</span>
            </label>
            {error && errorText?.length > 0 && (
                <div className="input-box__error-text -padding-left--30">
                    {errorText}
                </div>
            )}
        </div>
    );
};

export default Checkbox;

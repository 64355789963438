// @ts-check
import React, { useState } from "react";
import Countdown from "../../categories/Countdown";
import ItemStats from "../ItemStats";
import ItemActivityFeed from "../ItemActivityFeed";
import "./ItemAmount.scss";
import {
    getActionButtonText,
    getItemValue,
    getReserveNotMetText,
    getElapsedTime,
    getNextBid,
} from "../../../utility";
import ItemAmountHeader from "./ItemAmountHeading";
import ItemActionModal from "../ItemActionModal";
import { Button, ButtonStyle } from "../../core";
import ItemGoal from "../ItemGoal/ItemGoal";
import { NoTicketsModal, PaymentModalType } from "../../account";
import { usePaymentIframe } from "../../../hooks";
/** @typedef {import("../../../utility/itemHelpers.js").Product} Product */
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Account} Account */
/** @typedef {import("../../../types").Auction} Auction */

/**
 * @param {object} props - The properties object.
 * @param {Product} props.item
 * @param {boolean} [props.isInList]
 * @param {number} props.time
 * @param {Function|null} [props.actionClickHandler]
 * @param {boolean} [props.disabled]
 * @param {Attendee} [props.attendee]
 * @param {Account} [props.account]
 * @param {Auction} [props.auction]
 * @param {Function} [props.onLoginClick]
 * @returns {React.ReactNode}
 */
const ItemAmount = ({
    item,
    isInList = false,
    time,
    actionClickHandler = null,
    disabled = true,
    attendee,
    account,
    auction,
    onLoginClick,
}) => {
    const itemValueText = getItemValue(item);
    const itemValueSection = itemValueText
        ? `Valued at: ${itemValueText}`
        : null;
    const reserveNotMetText = getReserveNotMetText(item);
    const reserveNotMetSection = reserveNotMetText ? (
        <span className="-color--system-error">{reserveNotMetText}</span>
    ) : null;
    const lastBid =
        item.isBidType && item.bidCount
            ? item.bidHistory[item.bidHistory.length - 1]
            : null;
    const lastBidTimeText =
        item.isBidType && item.bidCount
            ? getElapsedTime(lastBid.timestamp_iso, time)
            : "";
    const [showActivityFeed, setShowActivityFeed] = useState(false);
    const winningBidSection =
        item.isBidType && item.bidCount ? (
            <div className="m:w-[426px] m:h-6 h-auto w-full text-[15px] font-normal leading-[23px] tracking-[0.12px] text-[color:var(--Theme-Text-Black,#394146)]">
                <strong>
                    Bidder #{item.winningBidderNumber} is in the lead
                </strong>
                <span className="display-inline m:h-auto h-0 text-[20px] leading-3 text-[color:var(--Theme-medium-dark,#66737d)]">
                    {" • "}
                </span>
                <span className="text-[color:var(--Theme-medium-dark,#66737d)]">
                    Last bid: {lastBidTimeText}
                </span>
            </div>
        ) : null;
    const activityButtonSection = item.activityCount ? (
        <div className="flex items-center justify-center leading-[21px] tracking-[0.5px]">
            <Button
                onClick={() => setShowActivityFeed(true)}
                btnStyle={ButtonStyle.None}
                data-testid="view-feed"
                className="ui-text-primary-color ml-1 p-0 text-[13px] font-bold"
            >
                View Activity Feed
            </Button>
        </div>
    ) : null;
    const actionButtonText = getActionButtonText(
        item,
        attendee?.id,
        auction?.maxBidType,
        getNextBid(item),
        !!account,
    );
    const [showActionModal, setShowActionModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(PaymentModalType.None);
    const [showNoTicketModal, setShowNoTicketModal] = useState(false);
    const hasGoal =
        item.isDonateType && (item.fundANeedGoal || item.maxQty) > 0;
    const clickHandler = () => {
        // open login modal if not logged in
        if (!account && onLoginClick) {
            onLoginClick();
            return;
        }
        // open no ticket modal
        if (!attendee) {
            setShowNoTicketModal(true);
            return;
        }
        // open payment modal if no payment method
        if (!attendee?.paymentMethod && account?.paymentMethods) {
            setPaymentModal(PaymentModalType.AddPayment);
            return;
        }
        setShowActionModal(true);
    };

    usePaymentIframe(paymentModal, () =>
        setPaymentModal(PaymentModalType.None),
    );

    if (isInList) {
        return (
            <section className="item-amount flex flex-col gap-4">
                <div>
                    <ItemAmountHeader item={item} isInList time={time} />
                    <div>
                        <div>
                            <ItemStats item={item} isInList={isInList} />
                        </div>
                    </div>
                </div>
                <div>
                    <Countdown item={item} time={time} />
                </div>
            </section>
        );
    }

    return (
        <section className="item-amount -background-color--system-pale p-4">
            <div className="flex flex-col gap-4">
                <ItemAmountHeader item={item} time={time} />
                {(reserveNotMetSection || itemValueSection) && (
                    <div className="flex gap-7">
                        {reserveNotMetSection && (
                            <span>{reserveNotMetSection}</span>
                        )}
                        {itemValueSection && <span>{itemValueSection}</span>}
                    </div>
                )}
                {hasGoal && <ItemGoal item={item} />}
                {winningBidSection}
                <ItemStats item={item} />
                <Countdown item={item} time={time} />
                <span>
                    {actionClickHandler && (
                        <Button
                            className="-padding--15 w-full"
                            btnStyle={ButtonStyle.Primary}
                            disabled={disabled}
                            data-testid="action-button"
                            onClick={clickHandler}
                        >
                            {actionButtonText}
                        </Button>
                    )}
                </span>
                <span>{item.isBidType && activityButtonSection}</span>
            </div>
            {item.isBidType && (
                <ItemActivityFeed
                    item={item}
                    isOpen={showActivityFeed}
                    onClose={() => setShowActivityFeed(false)}
                    time={time}
                />
            )}
            {actionClickHandler && showActionModal && (
                <ItemActionModal
                    item={item}
                    bidderId={attendee?.id}
                    actionAsync={actionClickHandler}
                    closeHandler={() => setShowActionModal(false)}
                    show={showActionModal}
                    time={time}
                    auction={auction}
                />
            )}
            <NoTicketsModal
                closeHandler={() => setShowNoTicketModal(false)}
                show={showNoTicketModal}
                email={account?.username || ""}
            />
        </section>
    );
};

export default ItemAmount;

import React from "react";

import Icon from "../Icon";
import Tooltip from "./Tooltip";

const QuestionTooltip = (props) => (
    <Tooltip {...props} interactive>
        <Icon
            className="-color--system-medium-dark"
            glyph="question-circle-regular"
            label={false}
            type="FontAwesome"
        />
    </Tooltip>
);

export default QuestionTooltip;

import { EventEmitter } from "events";

export const systemEmitter = new EventEmitter();

export const SystemEventType = {
    ShowPaymentIframe: "system:paymentIframe:show",
    HidePaymentIframe: "system:paymentIframe:hide",
};

export const openPaymentIframe = () => {
    systemEmitter.emit(SystemEventType.ShowPaymentIframe);
};

import React, { useEffect, useRef, useState } from "react";

const LoginPassword = (
    /** @type {{ email: string, handleOpenLoginNoPassword: Function, handleOpenForgotPassword: Function, handleSubmitPasswordClick: Function, loginError:string }} */
    {
        email = "",
        handleOpenLoginNoPassword = null,
        handleOpenForgotPassword = null,
        handleSubmitPasswordClick = null,
        loginError = "",
    },
) => {
    const [emailValue, setEmailValue] = useState(email);
    const [passwordValue, setPasswordValue] = useState("");
    const firstInput = useRef(null);

    useEffect(() => {
        firstInput?.current?.focus();
    }, [firstInput]);

    return (
        <>
            <div className="text-center text-xl font-bold text-[color:var(--Theme-Text-Black,#394146)]">
                Login
            </div>
            <div className="mt-[10px] text-center text-[15px] tracking-[0.12px] text-[#66737D]">
                Enter the email used when registering for this event
            </div>
            {loginError && (
                <div className="mt-[10px] rounded bg-[#d03424] p-[10px] text-center text-[15px] tracking-[0.12px] text-[#fff]">
                    {loginError}
                </div>
            )}
            <input
                ref={firstInput}
                placeholder="Email"
                aria-label="Email"
                data-testid="email-input"
                className="mt-[20px] w-full rounded-[5px] border border-solid border-[#AAB6BC] p-[15px] text-[15px] leading-[23px] tracking-[0.12px] shadow-inner focus-within:border-black"
                type="email"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
            />
            <input
                placeholder="Password"
                aria-label="Password"
                data-testid="password-input"
                className="mb-[5px] mt-[15px] w-full rounded-[5px] border border-solid border-[#AAB6BC] p-[15px] text-[15px] leading-[23px] tracking-[0.12px] shadow-inner focus-within:border-black"
                type="password"
                value={passwordValue}
                onKeyUp={(e) =>
                    e.key === "Enter" &&
                    handleSubmitPasswordClick(emailValue, passwordValue)
                }
                onChange={(e) => setPasswordValue(e.target.value)}
            />
            <div className="flex items-center justify-end">
                <button
                    type="button"
                    className="font-bold text-[color:var(--user-theme\_\_color--primary,#1F6FD9)] hover:underline"
                    onClick={() => handleOpenForgotPassword()}
                >
                    Forgot Password?
                </button>
            </div>

            <button
                data-testid="submit-email-btn"
                className="btn btn--primary mt-[30px] w-full p-[12px] text-lg tracking-[0.12px]"
                type="button"
                onClick={() =>
                    handleSubmitPasswordClick(emailValue, passwordValue)
                }
            >
                Log in
            </button>
            <div className="mt-[15px] flex items-center justify-center gap-1 text-[15px] tracking-[0.12px] text-[#66737D]">
                Don’t want a password?{" "}
                <button
                    type="button"
                    className="font-bold text-[color:var(--user-theme\_\_color--primary,#1F6FD9)] hover:underline"
                    onClick={() => handleOpenLoginNoPassword()}
                >
                    Receive code.
                </button>
            </div>
        </>
    );
};

export default LoginPassword;

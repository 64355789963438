// @ts-check
import React, { useState } from "react";
import { LogInstance } from "@qgiv/core-js";
import {
    getItemAmount,
    getActionButtonText,
    getItemAmountLabel,
    getRecommendedBidAmounts,
    getMaxAmount,
    getTotalForItem,
    getIncrement,
    getNextBid,
    getActionSuccessText,
    getMaxBidText,
    isWinning,
} from "../../../utility/itemHelpers";
import GiviUpsellModal from "../../givi/GiviUpsellModal";
import Incrementer from "../../Incrementer";
import Button, { ButtonSize, ButtonStyle } from "../../core/Button/Button";
import { Dialog, ModalSize } from "../../core";
import ComboBox from "../../core/ComboBox/ComboBox";
import { getAxiosResponseError } from "../../../api";
import { addToastSuccess, clearToasts } from "../../system";
import { getStartTime, hasPassed } from "../../../utility";

const { log } = LogInstance("ItemActionModal");

/**
 * @param {object} props
 * @param {import("../../../types").Product} props.item
 * @param {string} [props.bidderId]
 * @param {Function} props.closeHandler
 * @param {Function} props.actionAsync
 * @param {string[]} [props.errors]
 * @param {boolean} props.show
 * @param {boolean} [props.open]
 * @param {number} props.time
 * @param {import("../../../types").Auction} props.auction
 * @returns {React.JSX.Element}
 */
const ItemActionModal = ({
    item,
    bidderId,
    closeHandler,
    actionAsync,
    errors = [],
    show,
    open,
    time,
    auction,
}) => {
    const increment = getIncrement(item);
    const nextBid = getNextBid(item, bidderId);
    const isQtyType = item.isBuyType || (item.isDonateType && !!item.cost);
    const cost = isQtyType ? 1 : nextBid;
    const recommendedBids = getRecommendedBidAmounts(item, bidderId);
    const [amount, setAmount] = useState(cost);
    const [attribute, setAttribute] = useState("");
    const max = getMaxAmount(item, attribute);
    const disabled =
        !hasPassed(getStartTime(item), time) ||
        hasPassed(item.endTimeIso, time) ||
        (item.hasAttributes && !attribute) ||
        amount < cost ||
        (max && amount > max);
    const attributeError =
        item.hasAttributes && !attribute
            ? `${item.attribute.name} is required`
            : undefined;
    const [apiError, setApiError] = useState(errors);

    const actionHandler = async () => {
        try {
            clearToasts();
            setApiError([]);
            const response = await actionAsync(item.id, amount, attribute);
            if (!response.success) throw response;
            closeHandler();
            addToastSuccess(getActionSuccessText(item), 2500);
        } catch (err) {
            log(err);
            const responseErrors = getAxiosResponseError(err);
            setApiError(responseErrors);
        }
    };

    return (
        <Dialog
            title={
                <>
                    #{item.number} {item.title}{" "}
                    <span className="inline-block align-top">
                        • {getItemAmountLabel(item, time)}{" "}
                        {getItemAmount(item, time)}
                    </span>
                </>
            }
            closeHandler={closeHandler}
            show={show}
            open={open}
            className={ModalSize.Medium}
        >
            <div className="flex flex-col">
                {!!apiError?.length && (
                    <div
                        className="border-red-600 bg-red-50 text-red-600 mb-3 flex flex-col items-center rounded border border-solid p-3"
                        role="alert"
                    >
                        {apiError.map((m, i) => (
                            <div key={m.replace(" ", i.toString())}>{m}</div>
                        ))}
                    </div>
                )}

                <Incrementer
                    min={cost}
                    max={max}
                    amount={amount}
                    increment={isQtyType ? 1 : increment}
                    onUpdateAmount={setAmount}
                    isCurrency={!isQtyType}
                    roundButtons
                    className="mt-3"
                />

                {!!item.isBidType && !!item.maxBid && (
                    <Button
                        size={ButtonSize.Small}
                        type="button"
                        btnStyle={ButtonStyle.Text}
                        className="buy-now mx-auto mt-3"
                        onClick={() => setAmount(item.maxBid)}
                    >
                        Buy Now for ${item.maxBid}
                    </Button>
                )}

                {item.isBidType && !!recommendedBids.length && (
                    <div className="suggested-bids mt-6">
                        <div className="text-center text-sm text-[#4E5052]">
                            Recommended bids
                        </div>
                        <div className="mt-2.5 flex gap-4">
                            {recommendedBids.map(
                                (/** @type {number} */ recommended) => (
                                    <Button
                                        key={recommended}
                                        btnStyle={ButtonStyle.Transparent}
                                        size={ButtonSize.Large}
                                        className="ui-text-primary-color mx-auto w-1/3"
                                        onClick={() => setAmount(recommended)}
                                    >
                                        ${recommended}
                                    </Button>
                                ),
                            )}
                        </div>
                    </div>
                )}

                {item.isBuyType && item.hasAttributes && (
                    <ComboBox
                        label={item.attribute.name}
                        value={attribute}
                        options={item.attribute.options.map(({ name }) => ({
                            value: name,
                            name,
                        }))}
                        onChange={setAttribute}
                        error={attributeError}
                    />
                )}

                <Button
                    btnStyle={ButtonStyle.Primary}
                    className="mx-auto mt-8 w-1/2"
                    onClick={actionHandler}
                    disabled={disabled}
                    data-testid="actionButton"
                >
                    {getActionButtonText(
                        item,
                        bidderId,
                        auction.maxBidType,
                        amount,
                    )}{" "}
                    ${getTotalForItem(amount, isQtyType, item.cost)}
                </Button>

                {isWinning(item, bidderId) && (
                    <div className="max-bid pt-3 text-center text-sm">
                        {getMaxBidText(auction.maxBidType)}: ${item.maxUserBid}
                    </div>
                )}

                <GiviUpsellModal auction={auction} />
            </div>
        </Dialog>
    );
};

export default ItemActionModal;

// @ts-check
import React, { useEffect } from "react";
import {
    ActivityFilters,
    EventPage,
    getEmptyActivityActionText,
    getEmptyActivityIcon,
    getEmptyActivityNoticeText,
    getEventRoute,
    getFilteredActivityItems,
} from "../../../utility";
import { IconContentWrapper } from "../../system/IconContentWrapper";
import ItemCard from "../ItemCard";
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Auction} Auction */
/** @typedef {import("../../../types").Product} Product */

/**
 * @param {object} props
 * @param {ActivityFilters} props.activityFilter
 * @param {Product[]} props.items
 * @param {Attendee} props.attendee
 * @param {Array} props.paymentMethods
 * @param {string[]} props.favorites
 * @param {string[]} props.activityIds
 * @param {Auction} props.auction
 * @param {Function} props.onWatchHandler
 * @param {number} props.time
 * @param {Function} props.actionHandlerAsync
 * @returns {React.ReactNode}
 */
const ItemCardList = ({
    activityFilter,
    items,
    attendee,
    paymentMethods,
    favorites,
    activityIds,
    auction,
    onWatchHandler,
    time,
    actionHandlerAsync,
}) => {
    useEffect(() => {
        document.title = `${auction.title} - ${activityFilter.label}`;
    }, [activityFilter, auction.title]);
    const watchingItems = getFilteredActivityItems(
        activityFilter,
        items,
        attendee.id,
        favorites,
    );

    if (!watchingItems.length) {
        return (
            <IconContentWrapper
                iconGlyph={getEmptyActivityIcon(activityFilter)}
            >
                <>
                    {getEmptyActivityNoticeText(activityFilter)}
                    <br />
                    <a href={getEventRoute(EventPage.Items)}>
                        Browse the auction
                    </a>{" "}
                    {getEmptyActivityActionText(activityFilter)}
                </>
            </IconContentWrapper>
        );
    }
    return watchingItems.map((item) => (
        <ItemCard
            key={item.id}
            item={item}
            attendee={attendee}
            paymentMethods={paymentMethods}
            auction={auction}
            time={time}
            hasActivityForBidder={activityIds.includes(item.id)}
            watching={favorites.includes(item.id)}
            onWatchHandler={onWatchHandler}
            actionHandlerAsync={actionHandlerAsync}
        />
    ));
};

export default ItemCardList;

/** @typedef {import("../types").Product} Product */
/** @typedef {import("../types").PaymentMethod} PaymentMethod */

import { IconContentWrapperIcon } from "../components/system/IconContentWrapper";

export const ActivityPageBroadcastChannelName =
    "QGIV. ActivityPageBroadcastChannel";

/** @enum {{label:string, icon:string, isCheckout:boolean}} */
export const ActivityFilters = {
    WATCHING: {
        label: "Watching",
        icon: "bookmark-solid",
        isCheckout: false,
    },
    BIDDING: {
        label: "Bidding",
        icon: "gavel-regular",
        isCheckout: false,
    },
    CART: {
        label: "Cart",
        icon: "shopping-cart-solid",
        isCheckout: true,
    },
};

/**
 * Get list of items by filter
 * @param {any} key Tab key.
 * @param {Product[]} items list of items
 * @param {string} attendeeId Attendee id
 * @param {string[]} favorites favorites
 * @returns {Array} filtered list of items
 */
export function getFilteredActivityItems(key, items, attendeeId, favorites) {
    return [...items].filter((item) => {
        if (key === ActivityFilters.BIDDING && item.isBidType) {
            return !!item.bidHistory?.find((bh) => bh.bidderId === attendeeId);
        }

        if (key === ActivityFilters.WATCHING) {
            return favorites.includes(item.id);
        }

        return false;
    });
}

/**
 * Get count of items by filter
 * @param {any} key Tab key.
 * @param {Product[]} items list of items
 * @param {string} attendeeId Attendee id
 * @param {string[]} favorites favorites
 * @returns {number} count of items
 */
export function getFilteredActivityItemsCount(
    key,
    items,
    attendeeId,
    favorites,
) {
    return getFilteredActivityItems(key, items, attendeeId, favorites).length;
}

/**
 * Get payment method label
 * @param {PaymentMethod} paymentMethod Payment method object.
 * @returns {string} label
 */
export function getPaymentMethodLabel(paymentMethod) {
    if (paymentMethod.card) {
        return `${paymentMethod.card.type} ****${paymentMethod.card.lastFour}`;
    }

    if (paymentMethod.eCheck) {
        return "eCheck";
    }

    if (paymentMethod.offline?.type === "check") {
        return "Check";
    }

    return "Cash";
}

/**
 * Get payment method supporting label
 * @param {PaymentMethod} paymentMethod Payment method object.
 * @returns {string} label
 */
export function getPaymentMethodSupportingLabel(paymentMethod) {
    if (paymentMethod.card) {
        return `Exp: ${paymentMethod.card.expiration}`;
    }

    if (paymentMethod.eCheck) {
        return `****${paymentMethod.eCheck.lastFour}`;
    }

    return "Provide to event organizer";
}

/**
 * @description gets the icon for the empty state
 * @param {ActivityFilters} key activity filter key
 * @returns {string} icon glyph
 */
export function getEmptyActivityIcon(key) {
    if (key === ActivityFilters.WATCHING) {
        return IconContentWrapperIcon.BookmarkStrikethrough;
    }
    return IconContentWrapperIcon.Dollar;
}

/**
 * @description get the empty state notice text
 * @param {ActivityFilters} key activity filter key
 * @returns {string} text
 */
export const getEmptyActivityNoticeText = (key) =>
    `You're not ${key === ActivityFilters.WATCHING ? "watching" : "bidding on"} any items!`;

/**
 * @description get the empty state action text
 * @param {ActivityFilters} key activity filter key
 * @returns {string} text
 */
export const getEmptyActivityActionText = (key) =>
    key === ActivityFilters.WATCHING
        ? "and tap the save icon to add items to your watchlist."
        : "and place a bid to add items to your bidlist.";

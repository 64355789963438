import { useEffect } from "react";
import {
    PaymentIFrameChannelName,
    PaymentModalType,
} from "../components/account/PaymentModal";
import { openPaymentIframe } from "../components/system";

/**
 * @param {PaymentModalType} type
 * @param {Function} closeHandler
 */
export function usePaymentIframe(type, closeHandler) {
    useEffect(() => {
        const paymentChannel = new BroadcastChannel(PaymentIFrameChannelName);
        paymentChannel.addEventListener("message", closeHandler);

        if (type !== PaymentModalType.None) {
            openPaymentIframe();
        }
        return () => {
            paymentChannel.removeEventListener("message", closeHandler);
            paymentChannel.close();
        };
    }, [type, closeHandler]);
}

import React, { useState } from "react";
import { LogInstance } from "@qgiv/core-js";
import { Message, Button, ButtonStyle, Dialog, ModalSize } from "../../core";
import { RadioButton, RadioGroupProps } from "../../core/Button";
import { getAxiosResponseError } from "../../../api";
import { reloadPageWithToastSuccess } from "../../system";
/** @typedef {import("../../../types").Attendee} Attendee */

const { log } = LogInstance("SwitchTicketModal");

/**
 * @param {object} props
 * @param {Attendee[]} props.tickets
 * @param {Function} props.ticketSelectAsync
 * @param {Function} props.closeHandler
 * @param {string} [props.currentBidder]
 * @param {boolean} [props.show]
 * @param {boolean} [props.open]
 * @returns {React.ReactNode}
 */
const SwitchTicketModal = ({
    tickets,
    ticketSelectAsync,
    closeHandler,
    currentBidder = "",
    show,
    open,
}) => {
    const [selectedTicket, setSelectedTicket] = useState(currentBidder);
    const [errorMessage, setErrorMessage] = useState("");
    const [busy, setBusy] = useState(false);
    const confirmHandler = async () => {
        try {
            setBusy(true);
            setErrorMessage("");
            await ticketSelectAsync(selectedTicket);
            reloadPageWithToastSuccess("Ticket switched successfully");
        } catch (err) {
            log(err);
            const errors = getAxiosResponseError(err);
            setErrorMessage(errors.join(", "));
            setBusy(false);
        }
    };

    return (
        <Dialog
            id="switchTickerModal"
            closeHandler={closeHandler}
            title="Choose your ticket"
            show={show}
            open={open}
            className={ModalSize.Small}
        >
            <Message clearHandler={() => setErrorMessage("")} timeout={6000}>
                {errorMessage}
            </Message>
            <div {...RadioGroupProps}>
                {tickets.map((ticket) => {
                    const builder = [];
                    if (ticket.firstName) builder.push(ticket.firstName);
                    if (ticket.lastName) builder.push(ticket.lastName);
                    if (!builder.length) builder.push("Participant");
                    builder.push(
                        ticket.bidderNumber ? `#${ticket.bidderNumber}` : "--",
                    );
                    return (
                        <RadioButton
                            key={ticket.id}
                            selected={selectedTicket === ticket.id}
                            title={builder.join(" ")}
                            detail={ticket.packageName}
                            glyph="ticket-solid"
                            glyphClassName="-rotate-45"
                            onClick={() => {
                                setSelectedTicket(ticket.id);
                                setErrorMessage("");
                            }}
                        />
                    );
                })}
            </div>
            <div className="mt-5 flex gap-3">
                <Button
                    className="grow"
                    btnStyle={ButtonStyle.Primary}
                    onClick={confirmHandler}
                    disabled={
                        busy ||
                        !selectedTicket ||
                        selectedTicket === currentBidder
                    }
                    data-testid="confirmButton"
                >
                    Confirm
                </Button>
                <Button
                    className="grow"
                    btnStyle={ButtonStyle.Secondary}
                    onClick={closeHandler}
                >
                    Cancel
                </Button>
            </div>
        </Dialog>
    );
};

export default SwitchTicketModal;

import { constants } from "@qgiv/core-js";
/** @typedef {import("../types").SavedPayment} SavedPayment */
/** @typedef {import("../types").Auction} Auction */

const {
    ENUMS: { PaymentType },
} = constants;

/**
 * @param {string} expiry Expiry date string in MM/YY format.
 * @returns {boolean}
 */
function isPaymentMethodCurrent(expiry) {
    if (!expiry || !expiry.includes("/")) {
        return false;
    }

    const [month, year] = expiry.split("/");
    const expiryDate = new Date(
        parseInt(`20${year}`, 10),
        parseInt(month, 10) - 1,
    );
    const currentDate = new Date();

    return (
        expiryDate >=
        new Date(currentDate.getFullYear(), currentDate.getMonth())
    );
}

/**
 * @param {Auction} auction
 * @returns {function(SavedPayment): boolean}
 */
const getAcceptedPaymentMethodFilter = (auction) => (paymentMethod) => {
    if (
        paymentMethod.ccType !== "ck" &&
        !isPaymentMethodCurrent(paymentMethod.expiry)
    ) {
        return false;
    }
    const [check, card] = [...auction.checkCard];
    const [visaMC, amex, disc] = [...auction.VisaAmexDisc];
    switch (paymentMethod.ccType) {
        case "VS":
        case "MC":
            return card === "y" && visaMC === "y";
        case "AM":
            return card === "y" && amex === "y";
        case "DC":
            return card === "y" && disc === "y";
        case "ck":
            return check === "y";
        default:
            return false;
    }
};

/**
 * @param {SavedPayment[]} paymentMethods
 * @param {Auction} auction
 * @returns {SavedPayment[]}
 */
export function filterValidPaymentMethods(paymentMethods, auction) {
    if (!auction || !paymentMethods?.length) return [];
    return paymentMethods.filter(getAcceptedPaymentMethodFilter(auction));
}

/**
 * @param {Auction} auction
 * @returns {PaymentType}
 */
export function getDefaultPaymentType({ checkCard }) {
    if (checkCard === "yn") return PaymentType.ECHECK;
    return PaymentType.CREDITCARD;
}

// @ts-check
import React from "react";
import ItemListFilterCheckbox from "../ItemListFilterCheckbox/ItemListFilterCheckbox";
import { toggleFilter } from "../../../utility";

/**
 * @param {object} props - The properties object.
 * @param {string} props.title
 * @param {object[]} props.options
 * @param {string} props.idKey
 * @param {string} props.nameKey
 * @param {string} props.name
 * @param {object} props.activeFilters
 * @param {Function} props.setActiveFilters
 * @returns {React.JSX.Element} The rendered ItemListFilterSection component.
 */
const ItemListFilterSection = ({
    title,
    options,
    idKey,
    nameKey,
    name,
    activeFilters,
    setActiveFilters,
}) => {
    const handleToggleFilter = ({ target }) => {
        toggleFilter(
            target.name,
            target.value,
            activeFilters,
            setActiveFilters,
        );
    };

    return (
        <div className="sidebar__filter">
            <h2>{title}</h2>
            {options.map((option) => (
                <ItemListFilterCheckbox
                    key={option[idKey]}
                    isChecked={activeFilters[name]?.includes(option[idKey])}
                    label={option[nameKey]}
                    name={name}
                    value={option[idKey]}
                    itemCount={option.itemCount}
                    handleToggleFilter={handleToggleFilter}
                />
            ))}
        </div>
    );
};

export default ItemListFilterSection;

// @ts-check
import React from "react";
import {
    getItemStatus,
    getItemStatusText,
    getItemStatusColor,
} from "../../../utility";
import ItemStatus from "../ItemStatus";
import ItemWatchButton, { ItemWatchButtonBorder } from "../ItemWatchButton";
import ItemImage from "../../ItemImage";
import ItemHeading from "../ItemHeading";
import ItemAmount from "../ItemAmount";
/** @typedef {import("../../../types").Product} Product */
/**
 * @param {object} props
 * @param {Product} props.item
 * @param {import("../../../types").Attendee} props.attendee
 * @param {boolean} [props.hasActivityForBidder]
 * @param {number} props.time
 * @param {string} props.baseUrl
 * @param {boolean} [props.watching]
 * @param {Function} props.onWatchHandler
 * @returns {React.JSX.Element}
 */
const ItemListItem = ({
    item,
    attendee,
    hasActivityForBidder = false,
    time,
    baseUrl,
    watching = false,
    onWatchHandler,
}) => {
    const status = getItemStatus(item, time, attendee, hasActivityForBidder);
    const statusText = getItemStatusText(status, item);
    const statusColor = getItemStatusColor(status);

    return (
        <a
            href={`${baseUrl}/item/${item.id}`}
            className="item flex flex-col items-center overflow-hidden rounded-lg shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
            aria-label={item.title}
        >
            <ItemImage item={item} />
            <div className="item__body flex w-full grow flex-col justify-between gap-4 bg-white p-2 sm:p-4">
                <div>
                    <div className="mb-4">
                        <ItemHeading item={item} isInList />
                    </div>
                    <ItemAmount item={item} isInList time={time} />
                </div>
                <div className="flex items-end justify-between justify-self-end">
                    <ItemStatus text={statusText} color={statusColor} />
                    <ItemWatchButton
                        item={item}
                        watching={watching}
                        onWatchClickHandler={onWatchHandler}
                        border={ItemWatchButtonBorder.Small}
                    />
                </div>
            </div>
        </a>
    );
};

export default ItemListItem;

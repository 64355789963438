import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlreadyLoggedIn } from "@qgiv/auction";
import {
    resetModal,
    selectEmail,
    selectEventUrl,
} from "../../../redux/loginRegistrationModalSlice";

const ConnectedAlreadyLoggedIn = () => {
    const dispatch = useDispatch();
    const eventUrl = useSelector(selectEventUrl);
    const email = useSelector(selectEmail);

    /**
     * Handle click to open event registration iframe.
     */
    function handleOpenRegistrationClick() {
        const { loadFullScreenModal = () => {} } = window?.QGIV?.Embed ?? {};

        dispatch(resetModal(false));
        loadFullScreenModal(null, {
            eventUrl: `${eventUrl}?email=${email ?? ""}`,
        });
    }

    /**
     * Handle on submit click.
     */
    function handleItemsClick() {
        const path = window.location.pathname?.match(/event\/([^/]+)/);
        if (path) {
            window.location.href = `/event/${path[1]}/items`;
        }
    }

    return (
        <AlreadyLoggedIn
            email={email}
            handleItemsClick={() => handleItemsClick()}
            handleOpenRegistrationClick={() => handleOpenRegistrationClick()}
        />
    );
};

export default ConnectedAlreadyLoggedIn;

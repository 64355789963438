/* eslint-disable no-param-reassign */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    updateAuction,
    updateAuctionFeed,
    updateCategories,
    updateDonations,
    updateFavorites,
    addFavorite,
    updateProducts,
    updatePurchases,
} from "../../redux";
import {
    connectAuctionFeedRepository,
    AuctionBroadcastType,
    disconnectAuctionFeedRepository,
} from "../../utilities";

/**
 * @param {object} props properties
 * @param {string} props.formId
 * @returns {void}
 */
const SystemFeed = ({ formId }) => {
    const dispatch = useDispatch();
    /* istanbul ignore next */
    const { attendee, account } = useSelector((state) => state.user.value);

    useEffect(() => {
        const name = connectAuctionFeedRepository(
            formId,
            attendee?.id,
            account?.id,
            account?.token,
        );
        const broadcastChannel = new BroadcastChannel(name);
        const closeAll = () => {
            broadcastChannel.close();
            disconnectAuctionFeedRepository();
        };
        broadcastChannel.addEventListener(
            "message",
            ({ data: { type, payload } }) => {
                // NOTE: only update state at key times, initialized or restore = loaded
                if (
                    [
                        AuctionBroadcastType.Initialize,
                        AuctionBroadcastType.Restore,
                        AuctionBroadcastType.Failure,
                    ].includes(type)
                ) {
                    dispatch(updateAuctionFeed(type));
                }
                switch (type) {
                    case AuctionBroadcastType.Initialize:
                    case AuctionBroadcastType.Restore:
                    case AuctionBroadcastType.Balance:
                        // NOTE: we're deleting the arrays to prevent duplicate data in the store
                        if (payload.products?.length) {
                            dispatch(updateProducts(payload.products));
                            delete payload.products;
                        }
                        if (payload.categories?.length) {
                            dispatch(updateCategories(payload.categories));
                            delete payload.categories;
                        }
                        if (payload.myPurchases?.length) {
                            dispatch(updatePurchases(payload.myPurchases));
                            delete payload.myPurchases;
                        }
                        if (payload.myDonations?.length) {
                            dispatch(updateDonations(payload.myDonations));
                            delete payload.myDonations;
                        }
                        if (payload.favorites?.length) {
                            dispatch(updateFavorites(payload.favorites));
                            delete payload.favorites;
                        }
                        dispatch(updateAuction(payload));
                        break;
                    case AuctionBroadcastType.Products:
                        dispatch(updateProducts(payload));
                        break;
                    case AuctionBroadcastType.Categories:
                        dispatch(updateCategories(payload));
                        break;
                    case AuctionBroadcastType.Purchases:
                        dispatch(updatePurchases(payload));
                        break;
                    case AuctionBroadcastType.Donations:
                        dispatch(updateDonations(payload));
                        break;
                    case AuctionBroadcastType.Favorites:
                        dispatch(addFavorite(payload));
                        break;
                    case AuctionBroadcastType.FavoritesUpdate:
                        dispatch(updateFavorites(payload));
                        break;
                    default:
                }
            },
        );
        window.addEventListener("beforeunload", closeAll);
        return () => {
            window.removeEventListener("beforeunload", closeAll);
            closeAll();
        };
    }, [dispatch, formId, attendee, account]);
    return null;
};

export default SystemFeed;

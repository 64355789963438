import React, { Fragment } from "react";
import { Combobox } from "@headlessui/react";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";

/**
 * @param {object} props - The properties object.
 * @param {boolean} [props.group]
 * @param {boolean} [props.irremovable]
 * @param {string} props.name
 * @param {string|string[]} [props.selectedValue]
 * @param {boolean} [props.unavailable]
 * @param {string} props.value
 * @returns {React.JSX.Element} The rendered Option component.
 */
const Option = (props) => {
    const {
        group = false,
        irremovable = false,
        name,
        selectedValue,
        unavailable = false,
        value,
    } = props;

    return (
        <Combobox.Option
            as={Fragment}
            disabled={
                group ||
                unavailable ||
                (irremovable && selectedValue?.includes(value))
            }
            key={value}
            value={props}
        >
            {({ active, disabled, selected }) =>
                group ? (
                    <li className="combo-box__group">{name}</li>
                ) : (
                    <li
                        className={cx(
                            "combo-box__option",
                            active && "combo-box__option--active",
                            disabled && "combo-box__option--disabled",
                            selected && "combo-box__option--selected",
                        )}
                    >
                        {selected && (
                            <Icon
                                glyph="check-regular"
                                label={false}
                                type="FontAwesome"
                            />
                        )}
                        {name}
                    </li>
                )
            }
        </Combobox.Option>
    );
};

export default Option;

import parse from "../Base";
/** @typedef {import("@qgiv/auction/source/types").Auction} Auction */

// properties that require conversion
const intProperties = new Set([
    "feeCoverage",
    "totalTickets",
    "status",
    "maxBidType",
    "auctionStatus",
    "fundraisingGoal",
    "tableManagementType",
    "tz_offset",
    "lastUpdate",
]);
const floatProperties = new Set([
    "feeCoveragePercentage",
    "feeCoverageFlat",
    "totalPaid",
    "totalSpent",
    "totalBilled",
    "totalPending",
    "totalGiftAssist",
]);
const booleanProperties = new Set([
    "enableMatching",
    "thirdPartyMatching",
    "allowCompanyRegistrations",
    "allowDonation",
    "hideGivi",
    "hideStartBidding",
    "enableGenerateBidderNumberAtCheckIn",
    "offlinePermission",
    "hasOutbidNotice",
    "hasUpdateNotice",
]);

/**
 * @description parse an auction object
 * @param {any} rawAuction raw auction
 * @returns {Auction} auction
 */
export default function parseAuction(rawAuction) {
    if (!rawAuction || !Object.keys(rawAuction).length) return {};
    // parse properties
    const auction = parse(
        rawAuction,
        intProperties,
        floatProperties,
        booleanProperties,
    );

    return auction;
}

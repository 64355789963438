// @ts-check
import React, { useState } from "react";
import {
    getItemStatus,
    getItemStatusText,
    getItemStatusColor,
    getActionButtonText,
    isActionDisabledStatus,
    getNextBid,
} from "../../../utility";
import ItemStatus from "../../items/ItemStatus";
import ItemWatchButton, {
    ItemWatchButtonBorder,
} from "../../items/ItemWatchButton";
import ItemImage from "../../ItemImage/ItemImage";
import ItemHeading from "../../items/ItemHeading";
import { ItemAmountHeader } from "../../items";
import Button, { ButtonStyle } from "../../core/Button/Button";
import ItemActionModal from "../../items/ItemActionModal";
import ItemStats from "../../items/ItemStats";
import Countdown from "../../categories/Countdown";
import { PaymentModalType } from "../../account";
import { usePaymentIframe } from "../../../hooks";
/** @typedef {import("../../../types").Product} Product */
/** @typedef {import("../../../types").Attendee} Attendee */
/** @typedef {import("../../../types").Auction} Auction */

/**
 * @param {object} props - The properties object.
 * @param {Product} props.item
 * @param {boolean} [props.hasActivityForBidder]
 * @param {number} props.time
 * @param {Attendee} props.attendee
 * @param {boolean} [props.watching]
 * @param {Function} props.onWatchHandler
 * @param {Function} props.actionHandlerAsync
 * @param {Auction} props.auction
 * @returns {React.ReactNode}
 */
const ItemCard = ({
    item,
    hasActivityForBidder = false,
    time,
    attendee,
    watching = false,
    onWatchHandler,
    actionHandlerAsync,
    auction,
}) => {
    const status = getItemStatus(item, time, attendee, hasActivityForBidder);
    const disabled = isActionDisabledStatus(status);
    const statusText = getItemStatusText(status, item);
    const statusColor = getItemStatusColor(status);
    const actionButtonText = getActionButtonText(
        item,
        attendee.id,
        auction.maxBidType,
        getNextBid(item),
    );

    const [showActionModal, setShowActionModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(PaymentModalType.None);

    usePaymentIframe(paymentModal, () =>
        setPaymentModal(PaymentModalType.None),
    );

    const handleActionClick = () => {
        if (!attendee.paymentMethod) {
            setPaymentModal(PaymentModalType.AddPayment);
            return;
        }
        setShowActionModal(true);
    };

    return (
        <div
            data-testid={`itemCard-${item.id}`}
            className="rounded-[5px] px-2.5 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)] md:px-5 md:pl-0"
        >
            {/* small screen */}
            <div className="flex--tailwind flex-col gap-2.5 py-2 md:hidden">
                <div className="flex flex-col gap-1.5">
                    <div className="flex w-full justify-between">
                        <ItemHeading item={item} isInList />
                        <div className="pr-1">
                            <ItemWatchButton
                                item={item}
                                onWatchClickHandler={onWatchHandler}
                                watching={watching}
                                border={ItemWatchButtonBorder.None}
                            />
                        </div>
                    </div>
                    <div className="flex w-full gap-2">
                        <Countdown item={item} time={time} />
                        <span className="text-[20px] text-[color:var(--Theme-medium-dark,#66737d)]">
                            •
                        </span>
                        <ItemStats item={item} isInList />
                    </div>
                </div>
                <div className="flex items-center justify-between gap-1">
                    <ItemAmountHeader item={item} isInList time={time} />
                    <ItemStatus text={statusText} color={statusColor} />
                </div>
                <Button
                    btnStyle={ButtonStyle.Primary}
                    className="action-button mt-3 w-full"
                    type="button"
                    onClick={handleActionClick}
                    data-testid={`itemCardActionButton-${item.id}`}
                    disabled={disabled}
                >
                    {actionButtonText}
                </Button>
            </div>

            {/* large screen */}
            <div className="hidden content-start gap-4 md:flex md:flex-row">
                <div className="hidden size-[177px] items-center justify-center overflow-hidden rounded-l-[5px] md:flex">
                    <ItemImage item={item} />
                </div>

                <div className="flex flex-col justify-between py-2.5 md:grow lg:w-[200px]">
                    <div className="text-[15px] font-bold">
                        <ItemHeading item={item} isInList />
                    </div>
                    <div className="flex items-center py-2.5">
                        <ItemStatus text={statusText} color={statusColor} />
                    </div>
                    <div className="w-auto">
                        <Button
                            btnStyle={ButtonStyle.Primary}
                            className="action-button min-w-[200px]"
                            onClick={handleActionClick}
                            disabled={disabled}
                        >
                            {actionButtonText}
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col justify-between py-2.5">
                    <div>
                        <div className="col-span-full flex justify-end text-nowrap md:col-span-2 md:pt-0">
                            <ItemAmountHeader
                                item={item}
                                isInList
                                time={time}
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex justify-end text-nowrap">
                                <ItemStats item={item} isInList />
                            </div>
                            <div className="flex justify-end text-nowrap">
                                <Countdown item={item} time={time} />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex">
                        <ItemWatchButton
                            item={item}
                            onWatchClickHandler={onWatchHandler}
                            watching={watching}
                        />
                    </div>
                </div>
            </div>
            {showActionModal && (
                <ItemActionModal
                    item={item}
                    bidderId={attendee.id}
                    show={showActionModal}
                    closeHandler={() => setShowActionModal(false)}
                    actionAsync={actionHandlerAsync}
                    time={time}
                    auction={auction}
                />
            )}
        </div>
    );
};

export default ItemCard;

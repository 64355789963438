import React, { useEffect, useState } from "react";
import { Message, MessageType } from "../../core";
import toastEmitter, {
    ToastEmitterType,
    AddToastSuccessParam,
} from "./ToastEmitter";

const Toast = () => {
    const [toasts, setToasts] = useState([]);
    const removeToast = (id) => setToasts(toasts.filter((t) => t.id !== id));

    useEffect(() => {
        // Check for a success message after page reload
        const queryParams = new URLSearchParams(window.location.search);
        if (!queryParams.has(AddToastSuccessParam)) return;
        const toastMessage = queryParams.get(AddToastSuccessParam);
        setToasts([
            ...toasts,
            {
                id: Date.now(),
                message: toastMessage,
                type: MessageType.Success,
                timeout: 3000,
            },
        ]);
        queryParams.delete(AddToastSuccessParam);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, "", newUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const addHandler = (toast) => {
            setToasts([...toasts, { ...toast }]);
        };
        const clearHandler = () => setToasts([]);

        toastEmitter.on(ToastEmitterType.Add, addHandler);
        toastEmitter.on(ToastEmitterType.Clear, clearHandler);
        return () => {
            toastEmitter.off(ToastEmitterType.Add, addHandler);
            toastEmitter.off(ToastEmitterType.Clear, clearHandler);
        };
    }, [toasts, setToasts]);
    if (!toasts.length) return null;

    return (
        <div className="auction-toast relative">
            <div className="absolute right-0 w-full p-3 md:w-1/2 lg:w-2/6 xl:w-3/12">
                {toasts.map(({ id, message, type, timeout }) => (
                    <Message
                        key={id}
                        type={type}
                        clearHandler={() => removeToast(id)}
                        absolute={false}
                        className="mb-3"
                        timeout={timeout}
                    >
                        {message}
                    </Message>
                ))}
            </div>
        </div>
    );
};

export default Toast;

import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { NavigationSettings, SettingsDropdown } from "../../cms";
import {
    NavigationList,
    NavigationListPosition,
    NavigationListType,
} from "../NavigationList";
/** @typedef {import("../../../types").Navigation} Navigation */
/** @typedef {import("../../../types").CustomPage} CustomPage */

/**
 * @param {object} props
 * @param {Navigation[]} props.links
 * @param {NavigationListPosition} [props.position]
 * @param {boolean} [props.editMode]
 * @param {CustomPage[]} [props.customPages]
 * @param {Function} [props.updateNavigation]
 * @returns {React.ReactNode}
 */
const EventNavigation = ({
    links = [],
    position = NavigationListPosition.Header,
    editMode = false,
    customPages = [],
    updateNavigation,
}) => {
    const sortByWeight = (a, b) => a.weight - b.weight;
    const [showSettings, setShowSettings] = useState(false);

    return (
        <>
            {editMode && (
                <SettingsDropdown
                    position={position}
                    show={showSettings}
                    toggle={setShowSettings}
                >
                    <NavigationSettings
                        navigation={links}
                        customPages={customPages}
                        position={position}
                        toggle={setShowSettings}
                        updateNavigation={updateNavigation}
                    />
                </SettingsDropdown>
            )}
            <NavigationList
                type={NavigationListType.Event}
                position={position}
                className={twMerge(
                    "flex-wrap",
                    position === NavigationListPosition.Header &&
                        "gap-y-1 xl:gap-y-1",
                )}
                items={[...links].sort(sortByWeight).map((link) => ({
                    id: link.id,
                    content: <span>{link.title}</span>,
                    href: link.link,
                    isTargetBlank: link.newWindow === "1",
                }))}
            />
        </>
    );
};

export default EventNavigation;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ConfirmationModalQueryStringKey,
    loginConfirmationRedirect,
    RegistrationModalType,
    RegistrationModalView,
} from "@qgiv/auction";
import {
    ConnectedAlreadyLoggedIn,
    ConnectedCreatePassword,
    ConnectedForgotPassword,
    ConnectedLoginNoPassword,
    ConnectedLoginPassword,
    ConnectedOtpCode,
    ConnectedCheckEmail,
    ConnectedRegisterSelect,
} from "./Views";
import {
    selectRegistrationModalType,
    selectRegistrationModalView,
    setIsModalOpen,
} from "../../redux/loginRegistrationModalSlice";
import { removeRegistrationQueryString } from "../../utilities";
import { getAuction } from "../../redux";

/**
 * @param {object} props
 * @returns {React.ReactNode}
 */
const ModalViewSwitch = (props) => {
    const dispatch = useDispatch();
    const currentView = useSelector(selectRegistrationModalView);
    const modalType = useSelector(selectRegistrationModalType);
    const auction = useSelector(getAuction);

    useEffect(() => {
        // NOTE: if this is a login confirmation modal, only show with event check-in instruction, otherwise reload the page.
        if (
            currentView !== RegistrationModalView.CONFIRMATION ||
            modalType !== RegistrationModalType.LOGIN ||
            auction.checkinText
        ) {
            return;
        }
        dispatch(setIsModalOpen(false));
        window.location.href = `${window.location.pathname}?${ConfirmationModalQueryStringKey}=true`;
    }, [dispatch, currentView, modalType, auction?.checkinText]);
    useEffect(() => {
        const keyDownHandler = (e) => {
            if (e.key !== "Escape") return;
            dispatch(setIsModalOpen(false));
            removeRegistrationQueryString();
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => document.removeEventListener("keydown", keyDownHandler);
    });

    switch (currentView) {
        case RegistrationModalView.CREATE_PASSWORD:
            return <ConnectedCreatePassword {...props} />;
        case RegistrationModalView.FORGOT_PASSWORD:
            return <ConnectedForgotPassword {...props} />;
        case RegistrationModalView.CONFIRMATION:
            dispatch(setIsModalOpen(false));
            return loginConfirmationRedirect();
        case RegistrationModalView.LOGIN_NO_PASSWORD:
            return <ConnectedLoginNoPassword {...props} />;
        case RegistrationModalView.LOGIN_PASSWORD:
            return <ConnectedLoginPassword {...props} />;
        case RegistrationModalView.OTP_CODE_SELECT:
        case RegistrationModalView.OTP_CODE_ENTER:
            return <ConnectedOtpCode {...props} />;
        case RegistrationModalView.CHECK_EMAIL:
            return <ConnectedCheckEmail {...props} />;
        case RegistrationModalView.REGISTER_SELECT:
            return <ConnectedRegisterSelect {...props} />;
        case RegistrationModalView.ALREADY_LOGGED_IN:
            return <ConnectedAlreadyLoggedIn {...props} />;
        default:
            return null;
    }
};

export default ModalViewSwitch;

import { createSlice } from "@reduxjs/toolkit";
import {
    timeInitialState,
    timeStateMutator,
} from "./stateMutators/stateMutators";

const timerSlice = createSlice({
    name: "timer",
    initialState: timeInitialState,
    reducers: {
        tick: timeStateMutator,
    },
});

export default timerSlice.reducer;
export const { tick } = timerSlice.actions;

/** @enum {string} */
export const SocialName = {
    Facebook: "Facebook",
    Instagram: "Instagram",
    X: "X",
    YouTube: "YouTube",
};
/** @enum {string} */
const labels = {
    Find: "Find Us On",
    Follow: "Follow Us On",
};
/** @enum {string} */
export const SocialLabel = {
    Facebook: `${labels.Find} ${SocialName.Facebook}`,
    Instagram: `${labels.Follow} ${SocialName.Instagram}`,
    X: `${labels.Follow} ${SocialName.X}`,
    YouTube: `${labels.Find} ${SocialName.YouTube}`,
};

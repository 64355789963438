import React from "react";
import { useSelector } from "react-redux";
import { LoadingIcon, ItemList, getActivityProductIds } from "@qgiv/auction";
import { getCategoriesByIds, getProductsByCategoryIds } from "../../models";
import {
    getBaseUrl,
    getControl,
    getFavoriteProducts,
    getIsLoading,
    getProducts,
    getAllActivity,
    getAttendee,
} from "../../redux";
import { followHandler } from "../../api";
/** @typedef {import("../../models/Product/Product").Product} Product */
/** @typedef {import("../../models/Product/Product").Category} Category */

const ConnectedItemList = (
    /** @type {{ id: string }} */
    { id = "" },
) => {
    /** @type {Product[]} */
    const purchasesAndDonations = useSelector(getAllActivity);
    /** @type {string} */
    const attendee = useSelector(getAttendee);
    /** @type {Product[]} */
    const products = useSelector(getProducts);
    /** @type {Category[]} */ /* istanbul ignore next */
    const categories = useSelector((state) => state.categories.list);
    /** @type {boolean} */
    const isLoading = useSelector(getIsLoading);
    const baseUrl = useSelector(getBaseUrl);
    const control = useSelector(getControl(id));
    /* istanbul ignore next */
    const time = useSelector((state) => state.timer.time);
    const favorites = useSelector(getFavoriteProducts);

    // loading icon while waiting for SSE
    if (isLoading) {
        return <LoadingIcon />;
    }
    // we only store active categories, so filter the ids from the control
    /** @type {string[]} */
    const categoryIds = control?.options?.categories?.length
        ? getCategoriesByIds(categories, control.options.categories).map(
              (c) => c.id,
          )
        : categories.map((c) => c.id);
    const items = getProductsByCategoryIds(products, categoryIds);

    const attendeeActivity = getActivityProductIds(
        attendee?.id,
        purchasesAndDonations,
        products,
    );

    return (
        <ItemList
            attendee={attendee}
            options={control?.options}
            items={items}
            time={time}
            baseUrl={baseUrl}
            attendeeActivity={attendeeActivity}
            favorites={favorites}
            onWatchHandler={attendee ? followHandler : null}
        />
    );
};

export default ConnectedItemList;

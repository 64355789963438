import { createSlice } from "@reduxjs/toolkit";
import {
    addControlMutator,
    cmsInitialState,
    customPagesMutator,
    editModeMutator,
    layoutMutator,
    navigationMutator,
    socialSettingsMutator,
} from "./stateMutators";

const cmsSlice = createSlice({
    name: "cms",
    initialState: cmsInitialState,
    reducers: {
        addControl: addControlMutator,
        updateNavigation: navigationMutator,
        updateSocialSettings: socialSettingsMutator,
        updateLayout: layoutMutator,
        updateEditMode: editModeMutator,
        updateCustomPages: customPagesMutator,
    },
});

export default cmsSlice.reducer;
export const {
    addControl,
    updateNavigation,
    updateSocialSettings,
    updateLayout,
    updateEditMode,
    updateCustomPages,
} = cmsSlice.actions;

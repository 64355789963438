/**
 * @description converts snake case string to camel case
 * @param {string} snake snake case string
 * @returns {string} camel case string
 */
const snakeToCamelCase = (snake) =>
    snake.replace(/_(\w)/g, ([, c]) => c.toUpperCase());

/**
 * @description creates copy of object with camel case properties
 * @param {any} legacyObject legacy object with snake case properties
 * @returns {any} object with camel case properties
 */
function parseCamelCasePropertyObject(legacyObject) {
    const camelObject = {};
    Object.entries(legacyObject).forEach(([key, value]) => {
        const property = snakeToCamelCase(key);
        camelObject[property] = value;
    });
    return camelObject;
}

export default parseCamelCasePropertyObject;

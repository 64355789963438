import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemDetails, LoadingIcon, getActivityProductIds } from "@qgiv/auction";
import { getProduct } from "../../models";
import {
    getAllActivity,
    getAuction,
    getIsLoading,
    getIsWatching,
    getProducts,
    getUser,
} from "../../redux";
import { followHandler } from "../../api";
import { actionHandlerAsync } from "../../api/item";
import { showLoginModal } from "../../utilities";
/** @typedef {import("../../models/Product/Product").Product} Product */

/**
 * @param {object} props properties
 * @param {string} props.id
 * @returns {React.JSX}
 */
const ConnectedItemDetails = ({ id }) => {
    /** @type {Product[]} */
    const purchasesAndDonations = useSelector(getAllActivity);
    const { account, attendee } = useSelector(getUser);
    /** @type {Product[]} */
    const products = useSelector(getProducts);
    /** @type {boolean} */
    const isLoading = useSelector(getIsLoading);
    /* istanbul ignore next */
    const time = useSelector((state) => state.timer.time);
    const auction = useSelector(getAuction);
    const watching = useSelector(getIsWatching(id));
    const dispatch = useDispatch();
    const hasActivityForBidder =
        getActivityProductIds(
            attendee?.id,
            purchasesAndDonations,
            products,
        )?.includes(id) ?? false;

    // loading icon while waiting for item
    if (isLoading) {
        return <LoadingIcon />;
    }
    const item = getProduct(products, id);
    const onLoginClick = () => {
        showLoginModal(dispatch, auction.id);
    };

    if (!item) {
        return (
            <p className="item-details -ui-border bg-white p-10 text-center">
                Item is not available.
            </p>
        );
    }
    return (
        <ItemDetails
            attendee={attendee}
            item={item}
            onWatchClick={attendee?.id ? followHandler : null}
            watching={watching}
            auction={auction}
            time={time}
            actionClickHandler={actionHandlerAsync}
            hasActivityForBidder={hasActivityForBidder}
            account={account}
            images={
                item?.images?.length
                    ? item.images.map((url, index) => ({
                          id: index.toString(),
                          filename: `${item.title} image ${index + 1}`,
                          url,
                      }))
                    : []
            }
            onLoginClick={onLoginClick}
        />
    );
};

export default ConnectedItemDetails;

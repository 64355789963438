import React, { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortableListItem from "./SortableListItem";
/** @typedef {import("../../../types/Sortable").Sortable} Sortable */

const SortableList = (
    /** @type {{items:Sortable[], itemRenderer:Function, updateItems:(items:Sortable[]) => {}}} */ {
        items,
        itemRenderer,
        updateItems,
    },
) => {
    const moveItem = useCallback(
        (dragIndex, hoverIndex) => {
            updateItems((prevItems) =>
                prevItems
                    .toSpliced(dragIndex, 1)
                    .toSpliced(hoverIndex, 0, prevItems[dragIndex])
                    .map((i, weight) => ({ ...i, weight })),
            );
        },
        [updateItems],
    );

    return (
        <ul className="-sortable-list">
            <DndProvider backend={HTML5Backend}>
                {items.map((item) => (
                    <SortableListItem
                        key={item.id}
                        index={item.weight}
                        id={item.id}
                        moveItem={moveItem}
                    >
                        {itemRenderer(item)}
                    </SortableListItem>
                ))}
            </DndProvider>
        </ul>
    );
};

export default SortableList;

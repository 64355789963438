import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import cx from "classnames";
import countries from "country-region-data/data.json";
import { constants, detectCCTypes } from "@qgiv/core-js";
import { Icon } from "@qgiv/core-react";
import {
    Dialog,
    Button,
    ButtonStyle,
    Input,
    InputWrapper,
    ModalSize,
    Message,
    MaskedInput,
    getMask,
    MaskType,
    CardType,
    getCVVMaskByType,
} from "../../core";
import {
    formFieldLabels,
    getInitialValues,
    getValidationSchema,
} from "./validation";
import RadioTabs from "../../core/RadioTabs";
import { getAxiosResponseError } from "../../../api";
import { reloadPageWithToastSuccess } from "../../system";
import { getDefaultPaymentType } from "../../../utility";

const {
    ENUMS: { PaymentType },
} = constants;
/** @enum {string} */
const cardIconGlyph = {
    [CardType.Visa]: "cc-visa-brands",
    [CardType.Mastercard]: "cc-mastercard-brands",
    [CardType.Amex]: "cc-amex-brands",
    [CardType.Discover]: "cc-discover-brands",
};
/** @enum {string} */
const classNames = {
    Column: "flex flex-col gap-3",
    Row: "flex flex-col sm:flex-row sm:flex-nowrap gap-3",
};
/** @type {{stateName:string, stateCode:string}[]} */
const defaultStates = [
    { stateName: "Select State", stateCode: "" },
    {
        stateName: "Alabama",
        stateCode: "AL",
    },
    {
        stateName: "Alaska",
        stateCode: "AK",
    },
    {
        stateName: "American Samoa",
        stateCode: "AS",
    },
    {
        stateName: "Arizona",
        stateCode: "AZ",
    },
    {
        stateName: "Arkansas",
        stateCode: "AR",
    },
    {
        stateName: "California",
        stateCode: "CA",
    },
    {
        stateName: "Colorado",
        stateCode: "CO",
    },
    {
        stateName: "Connecticut",
        stateCode: "CT",
    },
    {
        stateName: "Delaware",
        stateCode: "DE",
    },
    {
        stateName: "District Of Columbia",
        stateCode: "DC",
    },
    {
        stateName: "Federated States Of Micronesia",
        stateCode: "FM",
    },
    {
        stateName: "Florida",
        stateCode: "FL",
    },
    {
        stateName: "Georgia",
        stateCode: "GA",
    },
    {
        stateName: "Guam",
        stateCode: "GU",
    },
    {
        stateName: "Hawaii",
        stateCode: "HI",
    },
    {
        stateName: "Idaho",
        stateCode: "ID",
    },
    {
        stateName: "Illinois",
        stateCode: "IL",
    },
    {
        stateName: "Indiana",
        stateCode: "IN",
    },
    {
        stateName: "Iowa",
        stateCode: "IA",
    },
    {
        stateName: "Kansas",
        stateCode: "KS",
    },
    {
        stateName: "Kentucky",
        stateCode: "KY",
    },
    {
        stateName: "Louisiana",
        stateCode: "LA",
    },
    {
        stateName: "Maine",
        stateCode: "ME",
    },
    {
        stateName: "Marshall Islands",
        stateCode: "MH",
    },
    {
        stateName: "Maryland",
        stateCode: "MD",
    },
    {
        stateName: "Massachusetts",
        stateCode: "MA",
    },
    {
        stateName: "Michigan",
        stateCode: "MI",
    },
    {
        stateName: "Minnesota",
        stateCode: "MN",
    },
    {
        stateName: "Mississippi",
        stateCode: "MS",
    },
    {
        stateName: "Missouri",
        stateCode: "MO",
    },
    {
        stateName: "Montana",
        stateCode: "MT",
    },
    {
        stateName: "Nebraska",
        stateCode: "NE",
    },
    {
        stateName: "Nevada",
        stateCode: "NV",
    },
    {
        stateName: "New Hampshire",
        stateCode: "NH",
    },
    {
        stateName: "New Jersey",
        stateCode: "NJ",
    },
    {
        stateName: "New Mexico",
        stateCode: "NM",
    },
    {
        stateName: "New York",
        stateCode: "NY",
    },
    {
        stateName: "North Carolina",
        stateCode: "NC",
    },
    {
        stateName: "North Dakota",
        stateCode: "ND",
    },
    {
        stateName: "Northern Mariana Islands",
        stateCode: "MP",
    },
    {
        stateName: "Ohio",
        stateCode: "OH",
    },
    {
        stateName: "Oklahoma",
        stateCode: "OK",
    },
    {
        stateName: "Oregon",
        stateCode: "OR",
    },
    {
        stateName: "Palau",
        stateCode: "PW",
    },
    {
        stateName: "Pennsylvania",
        stateCode: "PA",
    },
    {
        stateName: "Puerto Rico",
        stateCode: "PR",
    },
    {
        stateName: "Rhode Island",
        stateCode: "RI",
    },
    {
        stateName: "South Carolina",
        stateCode: "SC",
    },
    {
        stateName: "South Dakota",
        stateCode: "SD",
    },
    {
        stateName: "Tennessee",
        stateCode: "TN",
    },
    {
        stateName: "Texas",
        stateCode: "TX",
    },
    {
        stateName: "Utah",
        stateCode: "UT",
    },
    {
        stateName: "Vermont",
        stateCode: "VT",
    },
    {
        stateName: "Virgin Islands",
        stateCode: "VI",
    },
    {
        stateName: "Virginia",
        stateCode: "VA",
    },
    {
        stateName: "Washington",
        stateCode: "WA",
    },
    {
        stateName: "West Virginia",
        stateCode: "WV",
    },
    {
        stateName: "Wisconsin",
        stateCode: "WI",
    },
    {
        stateName: "Wyoming",
        stateCode: "WY",
    },
];

/**
 * @param {object} props
 * @param {string} props.currentPaymentId
 * @param {boolean} props.hasSavedPayments
 * @param {import("../../../types").Auction} props.auction
 * @param {Function} props.addPaymentMethodAsync
 * @param {Function} props.closeHandler
 * @param {Function} props.selectPaymentHandler
 * @param {boolean} [props.show]
 * @param {boolean} [props.open]
 * @returns {React.ReactNode}
 */
const AddPaymentModal = ({
    currentPaymentId,
    hasSavedPayments,
    auction,
    addPaymentMethodAsync,
    closeHandler,
    selectPaymentHandler,
    show,
    open,
}) => {
    const [paymentType, setPaymentType] = useState(
        getDefaultPaymentType(auction),
    );
    const [ccType, setCcType] = useState([]);
    const [states, setStates] = useState(defaultStates);
    const [errorMessage, setErrorMessage] = useState("");
    const [busy, setBusy] = useState(false);

    const onFormChangeHandler = async (e) => {
        switch (e.target.name) {
            case "cardNumber":
                setCcType(detectCCTypes(e.target.value));
                break;
            case "paymentType":
                setPaymentType(parseInt(e.target.value, 10));
                break;
            case "country":
                if (e.target.value !== "US") {
                    setStates([]);
                    break;
                }
                setStates(defaultStates);
                break;
            default:
        }
    };
    const onSubmitHandler = async (values) => {
        try {
            setBusy(true);
            const response = await addPaymentMethodAsync(values);
            if (!response.success) throw response;
            reloadPageWithToastSuccess(
                "Payment method added",
                !currentPaymentId,
            );
        } catch (err) {
            const errors = getAxiosResponseError(err);
            setErrorMessage(errors.join(", "));
            setBusy(false);
        }
    };

    const fields = Object.entries(formFieldLabels).reduce(
        (obj, [name, label]) => ({
            ...obj,
            [name]: (
                <Input
                    key={name}
                    name={name}
                    placeholder={label}
                    label={label}
                />
            ),
        }),
        {},
    );

    return (
        <Dialog
            title="Add a payment method"
            size={ModalSize.Large}
            closeHandler={closeHandler}
            show={show}
            open={open}
        >
            <Message timeout={7000} clearHandler={setErrorMessage}>
                {errorMessage}
            </Message>
            <Formik
                initialValues={getInitialValues(paymentType)}
                validationSchema={getValidationSchema(auction.VisaAmexDisc)}
                onSubmit={onSubmitHandler}
            >
                {({ errors, isSubmitting }) => (
                    <Form
                        className={classNames.Column}
                        onChange={onFormChangeHandler}
                    >
                        {auction.checkCard === "yy" ? (
                            <RadioTabs
                                name="paymentType"
                                values={[
                                    [PaymentType.CREDITCARD, "Credit Card"],
                                    [PaymentType.ECHECK, "Bank"],
                                ]}
                                selected={paymentType}
                                center
                            />
                        ) : (
                            <strong>
                                {paymentType === PaymentType.ECHECK
                                    ? "Bank"
                                    : "Credit Card"}
                            </strong>
                        )}
                        {paymentType === PaymentType.CREDITCARD ? (
                            <div className={classNames.Column}>
                                <InputWrapper name="cardNumber">
                                    <Icon
                                        type="FontAwesome"
                                        glyph={
                                            ccType.length
                                                ? cardIconGlyph[ccType[0]]
                                                : "credit-card-regular"
                                        }
                                        ariaHidden
                                    />
                                    <MaskedInput
                                        name="cardNumber"
                                        wrapped
                                        {...getMask(MaskType.CreditCard)}
                                    />
                                </InputWrapper>
                                <div className={classNames.Row}>
                                    <MaskedInput
                                        name="expiration"
                                        className="grow"
                                        {...getMask(MaskType.Expiration)}
                                    />
                                    <MaskedInput
                                        name="cvv"
                                        className="grow"
                                        {...getCVVMaskByType(ccType[0])}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={classNames.Column}>
                                <MaskedInput
                                    name="routingNumber"
                                    {...getMask(
                                        MaskType.Numeric,
                                        "Routing Number",
                                    )}
                                />
                                <MaskedInput
                                    name="accountNumber"
                                    {...getMask(
                                        MaskType.Numeric,
                                        "Account Number",
                                    )}
                                />
                            </div>
                        )}
                        <strong>Billing Section</strong>
                        <div className={classNames.Column}>
                            <div className={classNames.Row}>
                                {React.cloneElement(fields.firstName, {
                                    className: "grow",
                                })}
                                {React.cloneElement(fields.lastName, {
                                    className: "grow",
                                })}
                            </div>
                            {fields.address}
                            <div className={classNames.Row}>
                                {React.cloneElement(fields.city, {
                                    className: "grow",
                                })}
                                {states.length > 1 ? (
                                    <InputWrapper name="state">
                                        <Field
                                            as="select"
                                            name="state"
                                            className="w-full py-[.95rem] focus-visible:outline-0"
                                            aria-label="State"
                                        >
                                            {states.map(
                                                ({ stateName, stateCode }) => (
                                                    <option
                                                        key={stateCode}
                                                        value={stateCode}
                                                    >
                                                        {stateName}
                                                    </option>
                                                ),
                                            )}
                                        </Field>
                                    </InputWrapper>
                                ) : (
                                    React.cloneElement(fields.state, {
                                        className: "grow",
                                    })
                                )}
                                {states.length ? (
                                    <MaskedInput
                                        name="zip"
                                        className="sm:w-[205px]"
                                        {...getMask(MaskType.Zip)}
                                    />
                                ) : (
                                    React.cloneElement(fields.zip, {
                                        className: "grow",
                                    })
                                )}
                            </div>
                            <InputWrapper name="country">
                                <Field
                                    as="select"
                                    className="w-full py-[.95rem] focus-visible:outline-0"
                                    name="country"
                                    aria-label="Country"
                                >
                                    {countries.map(
                                        ({ countryName, countryShortCode }) => (
                                            <option
                                                key={countryShortCode}
                                                value={countryShortCode}
                                            >
                                                {countryName}
                                            </option>
                                        ),
                                    )}
                                </Field>
                            </InputWrapper>
                        </div>
                        <div className="mt-3 flex justify-center gap-3 pt-2">
                            <Button
                                type="submit"
                                className={cx(hasSavedPayments && "grow")}
                                btnStyle={ButtonStyle.Primary}
                                disabled={
                                    busy ||
                                    Object.keys(errors).length ||
                                    isSubmitting
                                }
                            >
                                Add Payment Method
                            </Button>
                            {hasSavedPayments && (
                                <Button
                                    className="grow"
                                    btnStyle={ButtonStyle.Secondary}
                                    onClick={selectPaymentHandler}
                                >
                                    Use Saved Payment
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default AddPaymentModal;

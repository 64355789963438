// @ts-check
import React from "react";
import { twMerge } from "tailwind-merge";
import { ImageLoader } from "@qgiv/core-react";
import { ButtonHighlightStyle } from "../../core";

/**
 * @param {object} props - The properties object.
 * @param {string} props.linkUrl
 * @param {string} props.imageUrl
 * @param {string} props.imageAltText
 * @param {string} [props.imageClassName]
 * @returns {React.JSX.Element} The rendered ImageLink component.
 */
const ImageLink = ({ linkUrl, imageUrl, imageAltText, imageClassName }) => (
    <a
        className={twMerge(
            "rounded focus:outline-none",
            ButtonHighlightStyle.Hover,
            ButtonHighlightStyle.Focus,
        )}
        href={linkUrl}
        target="_blank"
        rel="noreferrer"
    >
        <ImageLoader
            src={imageUrl}
            alt={imageAltText}
            className={imageClassName}
            loadedClassName={undefined}
            loadingClassName={undefined}
            handleImgLoad={undefined}
            loading="lazy"
        />
    </a>
);

export default ImageLink;

import React from "react";
import { Icon } from "@qgiv/core-react";
import {
    NavigationList,
    NavigationListPosition,
    NavigationListType,
} from "../NavigationList";
import { SocialLabel, SocialName } from "../../../utility";
/**
 * @typedef SocialLinks
 * @property {string} [facebookURL]
 * @property {string} [instagramURL]
 * @property {string} [twitterURL]
 * @property {string} [youtubeURL]
 */

/**
 * @param {object} props
 * @param {string} props.position
 * @param {SocialLinks} props.links
 * @returns {React.JSX.Element}
 */
const SocialNavigation = ({ position, links }) => {
    const linkContent = (glyph, name) => (
        <>
            <Icon
                glyph={glyph}
                type="FontAwesome"
                ariaHidden
                className="text-xl"
            />
            {position === NavigationListPosition.Sidebar ? name : null}
        </>
    );
    /** @type {import("../NavigationList/NavigationList").NavigationListItem[]} */
    const items = [
        {
            id: SocialName.Facebook,
            href: links.facebookURL,
            content: linkContent("square-facebook", SocialName.Facebook),
            isTargetBlank: true,
            title: SocialLabel.Facebook,
        },
        {
            id: SocialName.Instagram,
            href: links.instagramURL,
            content: linkContent("square-instagram", SocialName.Instagram),
            isTargetBlank: true,
            title: SocialLabel.Instagram,
        },
        {
            id: SocialName.X,
            href: links.twitterURL,
            content: linkContent("x-twitter-brands-square", SocialName.X),
            isTargetBlank: true,
            title: SocialLabel.X,
        },
        {
            id: SocialName.YouTube,
            href: links.youtubeURL,
            content: linkContent("square-youtube", SocialName.YouTube),
            isTargetBlank: true,
            title: SocialLabel.YouTube,
        },
    ];
    const validLinks = items.filter(({ href }) => !!href);

    if (!validLinks.length) return null;

    return (
        <NavigationList
            position={position}
            items={validLinks}
            type={NavigationListType.Social}
        />
    );
};

export default SocialNavigation;
